.outSideDiv7{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 550px;
    margin: 5px;
    padding: 3% 0;
    background: #ffffff;
    color: #000;
    text-align: center;
    box-shadow: 0px 0px 4px #000;
}

.detailHere7{
    width: 65%;
    height: 22%;
    margin: 1.8% 0 0 20%;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    text-decoration: underline;
}
.coverLineDiv7{ position: relative; }

.lineDiv7{
    position: absolute;
    height: 3px;
    width: 75%;
    margin-left: 13%;
    background: #093950;
}
div.firstlineDiv7{ margin-top: 52px; }

div.secondlineDiv7{ margin-top: 107px; }

.detailHere7 h1{
    line-height: 1.05;
    font-size: 3.45em;
    font-weight: 700;
    max-width: 100%;
    max-height: 95%;
}

.blueLineDiv7{
    width: 100%;
    height: 105px;
    margin-top: 25%;
    background: #3995C8 !important;
    z-index: -1;
}
.textWrapperDiv7{
    position: absolute;
    width: 65%;
    height: 65%;
    top: 27%;
    margin-left: 13%;
    background: #ffffff;
}

.textWrapperDiv7 div{
    padding: 4% 6%;
    text-align: justify;
    font-size: 1.7em;
    font-weight: 700;
    line-height: 1.1;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 74%;
}
i.lastIcon7{
    margin-top: -8%
}
.circle7 { 
    width: 66px; 
    height: 66px;
    text-align: center; 
    position: relative; 
} 
.yourLogo7{
    width: 50px;
    height: 38px;
    max-height: 38px;
    position: absolute;
    bottom: -47%;
    margin-left: 24px;
    z-index: 99;
}
.logoImage7{
    width: 85%;
    height: 85%;
    object-fit: cover;
}

.triangle7 { 
    position: absolute; 
    left: 30%;
    bottom: -40%;
    /* margin-top: 90%; */
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8; 
} 
.triangle7:nth-child(2) { transform: rotate(10deg); } 
.triangle7:nth-child(3) { transform: rotate(20deg); } 
.triangle7:nth-child(4) { transform: rotate(30deg); } 
.triangle7:nth-child(5) { transform: rotate(40deg); } 
.triangle7:nth-child(6) { transform: rotate(50deg); } 
.triangle7:nth-child(7) { transform: rotate(60deg); } 
.triangle7:nth-child(8) { transform: rotate(70deg); } 
.triangle7:nth-child(9) { transform: rotate(80deg); } 
.triangle7:nth-child(10) { transform: rotate(90deg); } 
.triangle7:nth-child(11) { transform: rotate(100deg); } 
.triangle7:nth-child(12) { transform: rotate(110deg); }

@media (max-width: 992px) {
    .outSideDiv7{ height: 450px; }
   
    div.firstlineDiv7{ margin-top: 39px; }
    
    div.secondlineDiv7{ margin-top: 78px; }
   
    .detailHere7{ height: 20%; }
   
    .detailHere7 h1{
        line-height: 1.05;
        font-size: 2.5em;
    }
    .textWrapperDiv7{
        top: 15%;
        margin-top: 12%;
    }
    .textWrapperDiv7 div{
        font-size: 1.3em;
        line-height: 1.1;
    }
    .yourLogo7{ 
        bottom: -53px;
    }     
    .triangle7 { 
        bottom: -50px;
    } 
}

@media (max-width: 770px) {
    .textWrapperDiv7 div{ 
        max-height: 75%; 
    }
    .yourLogo7{ 
        bottom: -20px;
    }     
    .triangle7 { 
        bottom: -16px;
    } 
}

@media (max-width: 450px) {
    .outSideDiv7{ height: 400px; }

    .detailHere7{ 
        height: 22%;
        margin-left: 18%; 
    }
    
    .textWrapperDiv7{
        top: 15%;
        margin-top: 12%;        
        margin-left: 10%;
    }
    .textWrapperDiv7 div{ 
        font-size: 1.1em;
        max-height: 71.5%; 
    }
    .yourLogo7{ 
        bottom: -12px;
        line-height: 0.95;
    }
        
    .triangle7 {
        bottom: -9px;
    } 
}
@media (max-width: 350px) {
    .outSideDiv7{ height: 350px; }
    
    .detailHere7{ height: 17.5%;}
    .detailHere7 h1{ 
        font-size: 1.8em;
        max-height: 50px; 
    }
    
    div.firstlineDiv7{ margin-top: 27px; }
    
    div.secondlineDiv7{ margin-top: 60px; }
    
    .textWrapperDiv7{
        top: 15%;
        margin-top: 10%;
        margin-left: 7%;
    }
    .textWrapperDiv7 div{ 
        font-size: 0.9em;
        max-height: 69%; 
    }
    
    .yourLogo7{ 
        bottom: -13%;
    }
        
    .triangle7 { 
        bottom: -10%;
    } 

}
