body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
.parentContain4 {
    height: 550px;
    margin: 5px;
    background: url(../assets/medil4.jpg); 
    background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
    color: #ffffff;
    font-size: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.centerImageTextDiv4{
    width: 33%;
    height: calc(100% - 350px);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv4{
    width: 200px;
    height: 190px;
    box-shadow:0px 0px 7px;
}
.middleImage4{
    width: 85%;
    height: 80%;
    border-radius: 50%;
    object-fit: cover;
}
.otherTexts4{
    color: #764532;
    max-width: 100%;
    max-height: 57%;
    font-size: 1em; 
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    margin-top: -18%;
}
.fromTexts4{
    max-width: 60%;
    font-size: 0.8em;
    background: #FB0A10;
    margin-top: -2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 1200px) {
    .parentContain4{
        height: 450px;
    }
    .centerImageTextDiv4{
        height: calc(100% - 300px);
    }
    .imageDiv4{
        width: 170px;
        height: 160px;
    }
    .otherTexts4{
        max-height: 60%;
        font-size: 0.95em;
    }
    .fromTexts4{
        max-height: 30%;
        font-size: 0.75em;
    }

}
@media (max-width: 992px) {
    .parentContain4{
        height: 390px;
    }
    .centerImageTextDiv4{
        height: calc(100% - 250px);
    }
    .imageDiv4{
        width: 160px;
        height: 140px;
    }
    .otherTexts4{
        font-size: 0.85em;
        max-height: 60%;
    }
    .fromTexts4{ font-size: 0.65em; }
}
@media (max-width: 770px) {
    .parentContain4{
        height: 500px;
    }
    .centerImageTextDiv4{
        height: calc(100% - 310px);
    }
    .imageDiv4{ width: 140px; }
    
    .otherTexts4{ margin-top: 1%; }
    
    .fromTexts4{ font-size: 0.7em; }
}
@media (max-width: 550px) {
    .parentContain4{
        height: 450px;
    }
    .centerImageTextDiv4{
        height: calc(100% - 300px);
    }
    .imageDiv4{
        width: 140px;
        height: 140px;
    }
    .otherTexts4{ 
        margin-top: -10%;
        max-height: 47%;
        font-size: 0.75em;  
    }
    
    .fromTexts4{ font-size: 0.66em; }
}
@media (max-width: 450px) {
    .parentContain4{
        height: 370px;
    }
    .centerImageTextDiv4{
        height: calc(100% - 235px);
    }
    .imageDiv4{
        width: 120px;
        height: 120px;
    }
    .otherTexts4{
        font-size: 0.6em;
    }
    .fromTexts4{
        font-size: 0.53em;
    }
}
@media (max-width: 350px) {
    .parentContain4{
        height: 280px;
    }
    .centerImageTextDiv4{
        height: calc(100% - 170px);
    }
    .imageDiv4{
        width: 85px;
        height: 85px;
    }
    .otherTexts4{
        font-size: 0.4em;
    }
    .fromTexts4{
        font-size: 0.39em;
        margin-top: -5%;
    }
}