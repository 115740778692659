body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: 'Rage Italic';
    src: local('Rage Italic'), url('../../../assets/RAGE_1.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}
.parentContainer14 {
    height: 550px;
    margin: 5px;
	/* background:linear-gradient(0deg, rgba(43, 62, 139, 0.9), rgba(43, 62, 139, 0.9)); */
    background: url(../assets/RE4.jpg); 
    background-position: center;
    background-size: 100%;
	background-repeat: no-repeat;
    color: #D5DDE0;
    font-size: 100%;
    padding-left: 13%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.centerImageTextDiv14{
    width: 50%;
    height: calc(100% - 150px);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv14{
    width: 200px;
    height: 200px;
}
.middleImage14{
    width: 80%;
    height: 80%;
    border-radius: 50%;
    object-fit: cover;
}
.underTextsDiv14{
    max-width: 100%;
    margin-top: -12%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.newYearText14{
    font-family: 'Rage Italic';
    color: #ffffff;
    font-size: 2.3em;
}
.otherTexts14{
    max-width: 100%;
    max-height: 45%;
    font-size: 1em;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
}
.fromTexts14{
    max-width: 100px;
    height: 40px;
    font-size: 0.8em;
    background: #282F49;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 1200px){
    .parentContainer14{
        height: 450px;
    }
    .centerImageTextDiv14{
        height: calc(100% - 100px);
    }
    .imageDiv14{
        width: 160px;
        height: 160px;
    }
    .underTextsDiv14{
        margin-top: -10%;
    }
    .newYearText14{
        font-size: 2em;
    }
    .otherTexts14{
        max-height: 52%;
        font-size: 1em;
    }
    .fromTexts14{
        margin-top: -5%;
        height: 40px;
        font-size: 0.8em;
    }
}
@media (max-width: 992px)
{
    .parentContainer14{
        height: 400px;
        padding-left: 10%;
    }
    .centerImageTextDiv14{
        height: calc(100% - 100px);
    }
    .newYearText14{
        font-size: 1.7em;
    }
    .imageDiv14{
        width: 140px;
        height: 140px;
    }
    .underTextsDiv14{
        margin-top: -15%;
    }
    .otherTexts14{
        font-size: 0.85em;
        max-height: 50%;
    }
    .fromTexts14{
        max-width: 80px;
        max-height: 30px;
        font-size: 0.6em;
    }   
}
@media (max-width: 770px){
    .parentContainer14{ height: 500px; }
    
    .centerImageTextDiv14{
        height: calc(100% - 100px);
    }
    .newYearText14{ font-size: 2.2em; }
    
    .imageDiv14{
        width: 180px;
        height: 180px;
    }
    .underTextsDiv14{ margin-top: -25%; }
    
    .otherTexts14{
        font-size: 0.95em;
        max-height: 45%;
    }
    .fromTexts14{
        max-width: 80px;
        max-height: 30px;
        font-size: 0.7em;
    }
}
@media (max-width: 550px)
{
    .parentContainer14{
        height: 500px;
    }
    .centerImageTextDiv14{
        height: calc(100% - 150px);
    }
    .imageDiv14{
        width: 150px;
        height: 150px;
    }
    .underTextsDiv14{ margin-top: -10%; }
    
    .newYearText14{ font-size: 1.9em; }
    
    .otherTexts14{ 
        max-height: 47%;
        font-size: 0.8em; 
    }
    
    .fromTexts14{
        max-width: 70px;
        max-height: 25px;
        font-size: 0.5em;
    }   
}
@media (max-width: 450px) {
    .parentContainer14{
        height: 400px;
    }
    .centerImageTextDiv14{
        height: calc(100% - 160px);
    }
    .imageDiv14{
        width: 120px;
        height: 120px;
    }
    .underTextsDiv14{ margin-top: -10%; }
    
    .newYearText14{ font-size: 1.5em; }
    
    .otherTexts14{ 
        max-height: 47%;
        font-size: 0.7em;
    }
    
    .fromTexts14{
        max-height: 20px;
        max-width: 60px;
        font-size: 0.4em;
    }    
}
@media (max-width: 350px) {
    .parentContainer14{
        height: 300px;
    }
    .centerImageTextDiv14{
        height: calc(100% - 130px);
    }
    .imageDiv14{
        width: 75px;
        height: 75px;
    }
    .underTextsDiv14{ margin-top: -10%; }
    
    .newYearText14{ font-size: 1em; }
    
    .otherTexts14{ font-size: 0.4em;  } 
    
    .fromTexts14{
        margin-top: -8%;
        max-width: 50px;
        font-size: 0.35em;
    }
}