 body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
.parentContainer6 {
	height: 550px;
	width: 100%;
	background: lightgrey;
	background-size: 100%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	color: #ffffff;
	position: relative;
}
.blueBackground6 {
	display: flex;
	flex-direction: column;
	background: #00ADEF;
	height: 100%;
	width: 70%;
	padding: 30px;
}
.circleBackground6 {
	position: absolute;
	bottom: 0;
	right: 0;
	margin-right: 16%;
	margin-bottom: 10px;
	border: 7px solid #ffffff;
	background-color: #ffffff;
	height: 280px;
	width: 280px;
	border-radius: 50%;
	display: flex;
}
.celebrantImage6 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}
.happyBirthday6 {
	font-size: 50px;
	font-weight: bold;
	color: #000000;
	word-break: break-word;
	text-transform: uppercase;
}
.celebrantName6 {
	margin-top: 40px;
	font-size: 30px;
	text-transform: uppercase;
}
.date6 {
	position: absolute;
	bottom: 0;
	color: rgba(255, 255, 255, 0.5);
	font-size: 70px;
	text-transform: uppercase;
	z-index: 2;
}

@media (max-width: 768px) {
	.parentContainer6 {
		height: 400px;
	}
	.blueBackground6 {
		padding: 15px;
	}
	.happyBirthday6 {
		font-size: 40px;
	}
	.celebrantName6 {
		font-size: 20px;
	}
	.fromSender6 {
		font-size: 15px;
	}
	.circleBackground6 {
		height: 180px;
		width: 180px;
	}
	.celebrantImage6 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
	}
	.date6 {
		font-size: 60px;
		z-index: 2;
	}
}

@media (max-width: 576px) {
	.parentContainer6 {
		height: 300px;	
		background-size: 140%;
		background-position: left top;
	}
	.blueBackground6 {
		padding: 10px;
	}
	.happyBirthday6 {
		font-size: 25px;
	}
	.celebrantName6 {
		font-size: 15px;
	}
	.circleBackground6 {
		position: absolute;
		bottom: 0;
		right: 0;
		margin-right: 16%;
		margin-bottom: 10px;
		border: 5px solid #ffffff;
		background-color: #ffffff;
		height: 130px;
		width: 130px;
		border-radius: 50%;
		display: flex;
	}
	.celebrantImage6 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
	}
	.date6 {
		font-size: 30px;
	}
}