.parentContainer9 {
	height: 550px;
	width: 100%;
	color: #ffffff;
	background: transparent;
}
.whiteBg9 {
	height: 30%;
	width: 100%;
	background-color: #ffffff;
	position: relative;
}
.celebrantImage9 {
	background:linear-gradient(0deg, rgba(210, 128, 97, 0.2), rgba(210, 128, 97, 0.2)), url('../../../../../assets/images/templates/heroes/7.jpeg');
	background-size: 100%;
	background-repeat: no-repeat;	
	height: 70%;
	width: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: flex-end;
}
.textHappy9 {
	font-size: 65px;
	font-weight: bold;
	text-transform: uppercase;
	margin-left: 10%;
}
.textBirthday9 {
	font-size: 65px;
	font-weight: bold;
	text-transform: uppercase;
	margin-left: 5%;
	color: #D28061;
}
.extraNote9 {
	position: absolute;
	bottom: 0;
	margin-bottom: 10px;
	text-align: center;
	font-size: 14px;
	width: 100%;
	text-transform: lowercase;
	word-break: break-word;
	letter-spacing: 5px;
	color: #000000;
}

@media (max-width: 768px) {
	.parentContainer9 {
		height: 400px;
	}
	.textHappy9, .textBirthday9 {
		font-size: 45px;
	}
	.extraNote9 {
		font-size: 10px;
	}
}

@media (max-width: 576px) {
	.parentContainer9 {
		height: 300px;
	}
	.textHappy9, .textBirthday9 {
		font-size: 40px;
	}
	.extraNote9 {
		font-size: 7px;
		letter-spacing: 3px;
	}
} 