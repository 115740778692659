
.labelly{
    position: relative;
    margin-top: 20px;
    display: block;
}
.Manually {
    border-bottom-style: solid;
    border-width: 0 0 1px 0;
    border-bottom-color: slategray;
    outline: none;
    width: 100%;
}
.Manually:focus {
    border-bottom-color: #3F51B5;
    padding-bottom: 3px;
    border-width: 0 0 2px 0;
    -webkit-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
    /*transform: scaleX(1.3);*/
}

.notValid {
    border-bottom-style: solid;
    outline: none;
    color: #F44336;
    border-width : 0 0 1px 0;
    border-bottom-color: #F44336;
    width: 100%;
}

.labelly{
    display: block;
    color: grey;
    transform: translate(2%, -60px);
}

.Manually:focus + .labelly {
    display: block;
    color: #3F51B5;
    /*transform: translate(2%, -64px);*/
    /*transition: 0.5s;*/
}


.margin-reduce{
    color: #F44336;
    display: block;
    /* transform: translate(2%, -64px); */
}

.redLabel {
    color: #F44336;
    display: block;
    margin-top: -4px;
    transform: translate(2%, -57px);
    transition: 0.5s;
}


.links{
    text-decoration: none;
    color: inherit;
}

.links:hover{
    text-decoration: none;
    color: inherit;
}

.link-ite{
    text-decoration: none;
    color: white;
}

.link-ite:hover{
    text-decoration: none;
    color: white;
}

.spinner{
    width: 25px;
    height: 25px;
    border-bottom: 3px dotted #2E75F9;
    border-left: 2px dotted #2E75F9;
    border-right: 1px solid #2E75F9;
    border-top: 3px dotted #2E75F9;
  }


.magup{
    margin-top: -20px;
}