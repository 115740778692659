.outSideDiv01{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    text-transform: uppercase;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 4px #000;
}
.mainInsideDiv01{
    width: 100%;
    height: 65%;
    color: #fff !important;
    background:linear-gradient(0deg, rgba(15,11,147, 0.9), rgba(15,11,147, 0.9)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5% 10% 0 10%;
    display: flex;
    flex-direction: column;
}
.logoHeaderText01{
    width: 100%;
    height: 20%;
}
.castVotesDiv01{
    width: 99%;
    height: 85px;
    margin: auto;
    text-align: center;  
}
h2.castVotesClass01{
    font-size: 2.3em;
    font-weight: 700;
    color: #fff;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 75px;
}
.mainDetailsText01{
    width:100%;
    display: flex;
    justify-content: center;
    padding-top: 15%;
}
.imagesDivFlex01{
    width: 265px;
    height: 265px;
    margin-left: 10%;
}
.imgFlexed01{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 2px 5px #000;
}
.circle01 { 
    margin-left: -35px;
    margin-top: 50px;
    width: 70px; 
    height: 70px;
    background-color: #FBC335;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
} 
.noVotes01{
    text-align: center;
    margin: auto; 
    width: 85%;
    height: 56%;
    text-transform: capitalize;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
    font-size: 1.3em;
    font-weight: 600;
    z-index: 99;
}
.underneathDetails01{
    width: 100%;
    padding: 11%;
    color: #000;
}
.voterDetailsText01{
    width: 85%;
    margin: auto;
    text-align: center;
}
.voterDetailsText01 h5{
    font-weight: 600;
    line-height: 0.2;
}
.voterDetailsText01 h1{
    line-height: 0.9;
    font-size: 3em;
    font-weight: 800;
    text-shadow: 1px 1px #fff;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 80px;
}
.voterDetailsText01 p{
    font-weight: 800;
    overflow: hidden;
    max-height: 25px;
}

@media (max-width: 992px) {
    .outSideDiv01{
        height: 550px;
    }
    h2.castVotesClass01{
        font-size: 1.9em;
        max-height: 75px;
    }
    .mainDetailsText01{
        padding-top: 65px;
    }
    .imagesDivFlex01{
        width: 230px;
        height: 230px;
    }
    .voterDetailsText01 h1{
        font-size: 2.2em;
        max-height: 62px;
    }
    .voterDetailsText01 p{
        max-height: 28px;
    }
}

@media (max-width: 770px) {
    h2.castVotesClass01{
        font-size: 2em;
        font-weight: 700;
        color: #fff;
    }
    .underneathDetails01{
        padding-top: 8%;
    }
}

@media (max-width: 550px) { 
    .outSideDiv01{
        height: 500px;
    }
    .castVotesDiv01{
        width: 95%;
    }
    h2.castVotesClass01{
        font-size: 1.6em;
        max-height: 60px;
    }
    .mainDetailsText01{
        padding-top: 55px;
    }
    .imagesDivFlex01{
        width: 225px;
        height: 225px;
    }
    .underneathDetails01{
        padding-top: 13%;
    }
    .voterDetailsText01 h1{
        font-size: 2em;
        max-height: 57px;
    }
}
@media (max-width: 450px) {
    .outSideDiv01{
        height: 400px;
    }
    h2.castVotesClass01{
        font-size: 1.3em;
        max-height: 50px;
    }
    .mainDetailsText01{
        padding-top: 37px;
    }
    .imagesDivFlex01{
        width: 180px;
        height: 180px;
    }
    .circle01 { 
        width: 55px; 
        height: 55px;
    }
    .noVotes01{
        font-size: 0.85em;
        max-height: 25px;
    }
    .underneathDetails01{
        padding-top: 10%;
    }
    .voterDetailsText01 h5{
        font-size: 0.8em;
    }
    .voterDetailsText01 h1{
        font-size: 1.8em;
        max-height: 48px;
    }
    .voterDetailsText01 p{
        font-size: 0.7em;
    }
}

@media (max-width: 350px) {
    .outSideDiv01{
        height: 350px;
    }
    h2.castVotesClass01{
        font-size: 1.1em;
        max-height: 40px;
    }
    .mainDetailsText01{
        padding-top: 53px;
    }
    .circle01 { 
        width: 48px; 
        height: 48px;
        margin-top: 15px;
    } 
    .noVotes01{
        font-size: 0.75em;
        max-height: 23px;
    }
    .imagesDivFlex01{
        width: 135px;
        height: 135px;
    }
    .underneathDetails01{
        padding-top: 13%;
    }
    .voterDetailsText01 h5{
        font-size: 0.7em;
    }
    .voterDetailsText01 h1{
        font-size: 1.2em;
        max-height: 33px;
    }
    .voterDetailsText01 P{
        font-size: 0.6em;
    }
}
