body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
.parentContain1 {
    height: 470px;
    width: 100%;
    margin: 5px;
    background: url(../assets/medil1.jpg); 
    background-size: 100%;
	background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
    font-size: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.centerImageTextDiv1{
    height: 43%;
    width: 40%;
    margin-top: 22%;
    text-align: center;
}
.imageDiv1{
    width: 100%;
    height: 160px;
}
.middleImage1{
    width: 100%;
    height: 95%;
    object-fit: cover;
}
.otherTexts1{
    max-width: 100%;
    max-height: 25%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    font-size: 1.08em; 
}
@media (max-width: 992px ){
    .parentContain1{
        height: 400px;
    }
    .centerImageTextDiv1{ margin-top: 20%; }
    
    .imageDiv1{ height: 90%; }
    
    .middleImage1{ height: 80%; }
    
    .otherTexts1{
        max-height: 35%;
        font-size: 0.9em;
        margin-top: -8%;
    }
}
@media (max-width: 770px ){
    .parentContain1{
        background-size: 80%
    }
}
@media (max-width: 550px ){
    .parentContain1 {
        height: 470px;
        background-size: 100%;
    }
    .centerImageTextDiv1{
        margin-top: 13vh;
    }
    .imageDiv1{
        height: 150px;
    }
    .middleImage1{ height: 95%; }
    
    .otherTexts1{
        margin-top: -2%;
    }
    .otherTexts1{
        line-height: 1.2;
        font-size: 0.8em; 
    } 
}
@media (max-width: 440px) {
    .parentContain1{
        height: 400px;
    }
    .centerImageTextDiv1{
        margin-top: 27%;
    }
    .imageDiv1{
        height: 110px;
    }
    .otherTexts1{
        font-size: 0.6em;
        padding-top: 7%; 
    }    
}
@media (max-width: 350px) {
    .parentContain1{
        height: 300px;
    }
    .centerImageTextDiv1{
        margin-top: 35%;
        height:40%;
    }
    .imageDiv1{
        height: 80px;
    }
    .otherTexts1{
        max-height: 30%;
        font-size: 0.5em; 
    }    
}

