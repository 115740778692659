.parentContainer10 {
	height: 550px;
	width: 100%;
	background:linear-gradient(0deg, rgba(220, 84, 0, 1), rgba(220, 84, 0, 1));
	color: #ffffff;
	position: relative;
}
.leftDiv10 {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 50%;
	padding-right: 30px;
}
.celebrantImageWrapper10 {
	position: absolute;
	bottom: 0;
	right: 0;
	margin-right: 8%;
	margin-bottom: 9%;
	border: 3px solid #ffffff;
	background-color: #ffffff;
	height: 80%;
	width: 290px;
	border-radius: 20px;
	display: flex;
}
.celebrantImage10 {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	object-fit: cover;
	background-color: grey;
}
.happyBirthday10 {
	font-size: 45px;
	font-weight: bold;
	text-align: right;
	color: #ffffff;
	word-break: break-word;
	text-transform: uppercase;
	z-index: 90;
}
.celebrantName10 {
	font-family: 'taken_by_vultures_demoregular';
	margin-top: 25px;
	max-width: 100%;
	word-break: break-word;
	font-size: 90px;
	text-align: right;
	text-transform: capitalize !important;
	z-index: 10;
}
.date10 {
	position: absolute;
	top: 0;
	right: 0;
	margin-right: 10%;
	margin-top: 10%;
	color: rgba(255, 255, 255, 0.5);
	font-size: 50px;
	text-transform: uppercase;
	z-index: 2;
}
.extraNote10 {
	position: absolute;
	bottom: 0;
	font-size: 11px;
	text-align: center;
	margin-bottom: 10px;
	letter-spacing: 0.3rem;
	width: 100%;
	z-index: 2;
}
@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@media (max-width: 992px) {
	.happyBirthday10 {
		font-size: 45px;
	}
}
@media (max-width: 768px) {
	.parentContainer10 {
		height: 400px;
	}
	.happyBirthday10 {
		font-size: 25px;
	}
	.celebrantName10 {
		font-size: 45px;
	}
	.celebrantImageWrapper10 {
		margin-right: 8%;
		margin-bottom: 15%;
		height: 70%;
		width: 170px;
	}
	.date10 {
		font-size: 35px;
		margin-top: 10%;
	}
	.extraNote10 {
		font-size: 9px;
	}
}

@media (max-width: 576px) {
	.parentContainer10 {
		height: 300px;
	}
	.leftDiv10 {
		padding-right: 15px;
	}
	.happyBirthday10 {
		font-size: 20px;
	}
	.celebrantName10 {
		font-size: 35px;
	}
	.celebrantImageWrapper10 {
		margin-right: 8%;
		margin-bottom: 15%;
		height: 70%;
		width: 150px;
	}
	.date10 {
		font-size: 25px;
	}
	.extraNote10 {
		font-size: 7px;
	}
}
@media (max-width: 350px) {
	.leftDiv10 {
		padding-right: 10px;
	}
	.celebrantImageWrapper10 {
		width: 120px;
	}
	.happyBirthday10 {
		font-size: 17px;
	}
	.date10 {
		font-size: 20px;
	}
}