body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: 'Rage Italic';
    src: local('Rage Italic'), url('../../../assets/RAGE_1.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}
.parentContainer13 {
    height: 550px;
    margin: 5px;
    background: url(../assets/RE3.jpg); 
    background-position: center;
    background-size: 100%;
	background-repeat: no-repeat;
    color: #FBFEFF;
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerImageTextDiv13{
    width: 70%;
    height: calc(100% - 260px);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv13{
    width: 200px;
    height: 200px;
}
.middleImage13{
    width: 80%;
    height: 80%;
    border-radius: 50%;
    object-fit: cover;
}
.underTextsDiv13{
    max-width: 100%;
    margin-top: -12%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.newYearText13{
    font-family: 'Rage Italic';
}
.otherTexts13{
    max-width: 100%;
    max-height: 30%;
    font-size: 1.05em;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis; 
    line-height: 1.1;
}
.fromTexts13{
    max-width: 100px;
    font-size: 0.8em;
    background: #282F49;
    color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 1200px) {
    .parentContainer13{
        height: 450px;
    }
    .centerImageTextDiv13{
        height: calc(100% - 180px);
    }
    .imageDiv13{
        width: 160px;
        height: 160px;
    }
    .newYearText13{
        font-family: 'Rage Italic';
    }
    .otherTexts13{
        max-width: 100%;
        max-height: 35%;
        font-size: 1.05em;
    }
}
@media (max-width: 992px)
{
    .parentContainer13{
        height: 400px;
    }
    .centerImageTextDiv13{
        height: calc(100% - 140px);
    }
    .imageDiv13{
        width: 140px;
        height: 140px;
    }
    .underTextsDiv13{ margin-top: -10%; }
    
    .newYearText13{  font-size: 2em;  }
    .otherTexts13{
        font-size: 1.05em; 
        max-height: 40%;
        margin-top: -10%;
    }
    .fromTexts13{
        max-width: 80px;
        max-height: 30px;
        margin-top: -5%;
        font-size: 0.6em;
    }   
}
@media (max-width: 770px){
    .parentContainer13{
        height: 480px;
    }
    .imageDiv13{
        width: 170px;
        height: 170px;
    }
    .underTextsDiv13{
        margin-top: -10%;
    }
    .newYearText13{ 
        font-size: 2.2em; 
    }
    .otherTexts13{
        max-height: 40%;
        font-size: 1em;
        margin-top: -5%;
    }
    .fromTexts13{
        max-width: 80px;
        max-height: 30px;
        margin-top: -5%;
        font-size: 0.6em;
    }
}
@media (max-width: 550px)
{
    .parentContainer13{
        height: 500px;
    }
    .centerImageTextDiv13{
        height: calc(100% - 250px);
    }
    .imageDiv13{
        width: 150px;
        height: 150px;
    }
    .underTextsDiv13{ margin-top: -10%; }
    
    .newYearText13{ font-size: 2em; }
    
    .otherTexts13{ 
        margin-top: -1%;
        font-size: 0.9em;
    }
    
    .fromTexts13{
        max-width: 70px;
        margin-top: 2%;
        font-size: 0.5em;
    }   
}
@media (max-width: 450px) {
    .parentContainer13{
        height: 400px;
    }
    .centerImageTextDiv13{
        height: calc(100% - 220px);
    }
    .imageDiv13{
        width: 120px;
        height: 120px;
    }
    .underTextsDiv13{ margin-top: -10%; }
    
    .newYearText13{ font-size: 1.7em; }
    
    .otherTexts13{
        max-height: 35%;
        font-size: 0.7em;
    }
    
    .fromTexts13{
        margin-top: -5%;
        max-width: 55px;
        max-height: 20px;
        font-size: 0.4em;
    }    
}
@media (max-width: 350px) {
    .parentContainer13{
        height: 300px;
    }
    .centerImageTextDiv13{
        height: calc(100% - 150px);
    }
    .imageDiv13{
        width: 75px;
        height: 75px;
    }
    .underTextsDiv13{ margin-top: -10%; }
    
    .newYearText13{ font-size: 1em; }
    
    .otherTexts13{ font-size: 0.45em;  } 
    
    .fromTexts13{
        margin-top: -7%;
        max-width: 50px;
        font-size: 0.35em;
    }
}
