@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.parentContainer12 {
	height: 600px;
	background: grey;
	width: 100%;
	background: url('../../../CSSs/Birthday/twelve/assets/template-bg.png');
	background-size: 100%;
	background-repeat: no-repeat;
	color: #ffffff;
	padding: 40px;
	position: relative;
}
.happyBirthday12 {
	font-size: 15px;
	color: #000000;
	font-weight: bold;
	text-transform: uppercase;
}
.bottomDiv12 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.dateDiv12 {
	color: #000000;
	font-weight: bold;
	font-size: 15px;
	text-align: right;
	text-transform: uppercase;
}
.celebrantImageDiv12 {
	display: flex;
	justify-content: center;
}
.celebrantImageWrapper12 {
	background-color: black;
	height: 320px;
	width: 320px;
	border: 8px solid #ffffff;
	box-shadow: 2px 2px 2px #000000;
	border-radius: 50%;
	display: flex;
}
.celebrantImage12 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}
.celebrantName12 {
	text-align: center;
	font-size: 30px;
	color: #1A3D5B;
	font-weight: bold;
	width: 50%;
	text-transform: uppercase;
}
.extraNote12 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	color: #000000;
	letter-spacing: 1.2px;
	margin-bottom: 5rem;
	word-spacing: 2.2px;
	max-width: 100%;
	word-break: break-word;
}

@media (max-width: 768px) {
	.parentContainer12 {
		height: 500px;
		padding: 20px;
	}
	.celebrantImageWrapper12 {
		height: 250px;
		width: 250px;
	}
	.happyBirthday12 {
		font-size: 10px;
	}
	.dateDiv12 {
		font-size: 10px;
	}
	.celebrantName12 {
		font-size: 20px;
	}
	.extraNote12 {
		margin-bottom: 7.8rem;
		font-size: 12px;
	}
}

@media (max-width: 576px) {
	.parentContainer12 {
		height: 300px;
		padding: 15px;
	}
	.celebrantImageWrapper12 {
		height: 180px !important;
		width: 180px !important;
		border: 4px solid #ffffff;
	}
	.happyBirthday12 {
		font-size: 8px;
	}
	.dateDiv12 {
		font-size: 8px;
	}
	.celebrantName12 {
		font-size: 18px;
	}
	.extraNote12 {
		font-size: 7px;
		margin-bottom: 0.7rem;
	}
} 