.outSideDiv07{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    background-color: #000;
    box-shadow: 0px 0px 4px #000;
}
.mainInsideDiv07{
    width: 100%;
    height: 68%;
    color: #000 !important;
    background: linear-gradient(0deg, rgba(242, 242, 242, 0.7), rgba(242, 242, 242, 0.7)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
}
.logoHeaderText07{
    width: 100%;
    display: flex;
}
.circleDiv07{
    width: 20%;
}
.circle07 { 
    width: 68px; 
    height: 68px;
    /* background-color: #FBC335; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
} 
.yourLogo07{
    width: 50px;
    height: 45px;
    z-index: 99;
}
.logoImage07{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.textInfoText07{
    width: 80%;
}
.presidentialText07{
    width: 95%;
    margin-top: 10px;
}
.presidentialText07 h2{
    margin: auto;
    font-weight: 800;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 60px;
}
.mainDetailsText07{
    width: 90%;
    margin: auto;
    padding-top: 10%;
    display: flex;
}
.saveDateText07{
    width: 40%;
    padding-top: 10%;
}
.saveDateText07 h1{
    font-weight: 800;
    width: 80%;
    font-size: 2.8em;
    line-height: 1;
}
.saveDateText07 p{
    width: 63%;
    font-size: 1.3em;
    font-weight: 700;
    padding: 2px 5px;
    background-color: #FBC335;
}
.imagesDivFlex07{
    width:60%;
    margin: auto;
    padding-left: 3%;
}
.thinCircle07{
    width: 240px;
    height: 240px;
    position: absolute;
    margin-top: 8px;
    margin-left: 22px;
    border: 1px solid #fff;
    border-radius: 50%;
}
.middleImageDiv07{
    width: 90%; 
    margin: auto;
    display: flex;
    flex-direction: column;
}
.imgFlexed07{
    width: 255px;
    height: 255px;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
}

div.yellowBoard07{
    width: 100%;
    font-size: 1.5em;
    background-color: #FBC335;
    color: #000;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 800;
    margin-top: -11%;
    margin-left: 2%;
    padding: 5px 8px;
    z-index: 2;
    text-align: center;
    max-height: 70px;
}
.textBelowDiv07{
    width: 93%;
    padding-top: 3%;
    margin: auto;
    color: #ffffff;
    display: flex;
}
.textOnly07{
    width: 47%;
    height: 90%;
    line-height: 1;
    font-weight: bold;
    text-align: left;
}
.textOnly07 h5{
    font-weight: 900;
    line-height: 1;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 37.5px;
}
.textOnly07 p{
    font-size: 0.8em;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 49px;
}
.textOnlyTwo07{
    text-align: right;
}
@media (max-width: 1200px){
    .mainDetailsText07{
        padding-top: 15%;
    }
    .saveDateText07 p{
        width: 80%;
        padding: 2px 5px;
    }
    .imagesDivFlex07{
        padding-top: 3%;
    }
    .thinCircle07{
        width: 215px;
        height: 215px;
        margin-top: 7px;
        margin-left: 20px;
    }
    .imgFlexed07{
        width: 230px;
        height: 230px;
    }
    div.yellowBoard07{
        font-size: 1.3em;
    }
    .textBelowDiv07{
        padding-top: 4%;
    }
    .textOnly07 p{
        max-height: 48px;
    }
}
@media (max-width: 992px) {
    .outSideDiv07{
        height: 550px;
    }
    .presidentialText07 h2{
        font-size: 1.6em;
        max-height: 50px;
    }
    .mainDetailsText07{
        padding-top: 15%;
    }
    .saveDateText07 h1{
        font-size: 2.4em;
    }
    .saveDateText07 p{
        font-size: 1.2em;
    }
    .imagesDivFlex07{
        padding-top: 7%;
    }
    .thinCircle07{
        width: 185px;
        height: 185px;
        margin-top: 7px;
        margin-left: 18px;
    }
    .imgFlexed07{
        width: 200px;
        height: 200px;
    }
    div.yellowBoard07{
        font-size: 1.2em;
        max-height: 60px;
    }
    .textOnly07{
        width: 48%;
    }
    .textOnly07 h5{
        font-weight: 700;
    }
    .textOnly07 p{
        max-height: 54px;
        font-size: 0.75em;
    }
}

@media (max-width: 770px) {
    .presidentialText07 h2{
        font-size: 1.6em;
        max-height: 50px;
    }
    .mainDetailsText07{
        padding-top: 3%;
    }
    .saveDateText07{
        padding-top: 15%;
    }
    .saveDateText07 p{
        width: 70%;
    }
    .imagesDivFlex07{
        padding-top: 7%;
    }
    .thinCircle07{
        width: 215px;
        height: 215px;
        margin-top: 8px;
        margin-left: 25px;
    }
    .imgFlexed07{
        width: 235px;
        height: 235px;
    }
    div.yellowBoard07{
        font-size: 1.2em;
        max-height: 60px;
    }
    .textOnly07{
        width: 48%;
    }
    .textOnly07 h5{
        max-height: 37.5px;
    }
    .textOnly07 p{
        font-size: 0.7em;
        max-height: 49px;
    }
}
@media (max-width: 550px) {
    .saveDateText07{
        padding-top: 20%;
    }
    .saveDateText07 p{
        width: 80%;
        font-size: 1.1em;
    }
    .imagesDivFlex07{
        padding-top: 15%;
        padding-left: 3%;
    }
    .thinCircle07{
        width: 195px;
        height: 195px;
        margin-left: 4%;
    }
    .imgFlexed07{
        width: 210px;
        height: 210px;
    }
    div.yellowBoard07{
        font-size: 1.2em;
    }
    .textBelowDiv07{
        padding-top: 5%;
    }
    .textOnly07{
        width: 48%;
    }
    .textOnly07 h5{
        font-weight: 700;
    }
    .textOnly07 p{
        font-size: 0.7em;
    }
}
@media (max-width: 450px) {
    .outSideDiv07{
        height: 450px;
    }
    .circle07 { 
        width: 60px; 
        height: 60px;
    }
    .yourLogo07{
        width: 45px;
        height: 40px;
    }
    .presidentialText07 h2{
        font-size: 1.3em;
        max-height: 40px;
    }
    .mainDetailsText07{
        padding-top: 7%;
    }
    .saveDateText07 h1{
        font-size: 2em;
    }
    .saveDateText07 p{
        font-size: 0.9em;
    }
    .imagesDivFlex07{
        padding-left: 3%;
    }
    .thinCircle07{
        width: 155px;
        height: 155px;
        margin-top: 7px;
        margin-left: 18px;
    }
    .imgFlexed07{
        width: 170px;
        height: 170px;
    }
    div.yellowBoard07{
        font-size: 0.9em;
        max-height: 45px;
    }
    .textOnly07 h5{
        font-size: 0.8em;
        max-height: 25px;
    }
    .textOnly07 p{
        font-size: 0.6em;
        max-height: 45px;
    }
}
@media (max-width: 350px) {
    .outSideDiv07{
        height: 350px;
    }
    .circle07 { 
        width: 55px; 
        height: 55px;
        margin-left: 5px;
    } 
    .yourLogo07{
        width: 40px;
        height: 35px;
    }
    .presidentialText07 h2{
        font-size: 0.9em;
        width: 90%;
    }
    .mainDetailsText07{
        padding-top: 15%;
    }
    .saveDateText07{
        padding-top: 9%;
    }
    .saveDateText07 h1{
        font-size: 1.5em;
    }
    .saveDateText07 p{
        font-size: 0.6em;
    }
    .imagesDivFlex07{
        padding: 0;
    }
    .thinCircle07{
        width: 118px;
        height: 118px;
        margin-top: 5px;
        margin-left: 6.5%;
    }
    .imgFlexed07{
        width: 130px;
        height: 130px;
    }
    div.yellowBoard07{
        font-size: 0.7em;
        max-height: 35px;
    }
    .textOnly07 h5{
        font-size: 0.67em;
        max-height: 20px;
    }
    .textOnly07 p{
        font-size: 0.45em;
        max-height: 35px;
    }
      
}
