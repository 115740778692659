.outSideDiv03{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    text-transform: uppercase;
    background-color: #DCDBD6;
    box-shadow: 0px 0px 4px #000;
}
.mainInsideDiv03{
    width: 100%;
    height: 65%;
    color: #fff !important;
    background: linear-gradient(0deg, rgba(15,11,147, 0.9), rgba(15,11,147, 0.9)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5% 10% 0 10%;
    display: flex;
    flex-direction: column;
}
.logoHeaderText03{
    width: 100%;
    height: 20%;
}
.byElectionDiv03{ text-align: center; }

h2.byElectionClass03{
    font-size: 2.3em;
    font-weight: 700;
    color: #fff;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 75px;
}
.mainDetailsText03{
    width:100%;
    display: flex;
    justify-content: center;
    padding-top: 23%;
}
.imagesDivFlex03{
    width: 255px;
    height: 255px;
}
.imgFlexed03{
    width: 100%;
    height: 100%;
    border: 3px solid #fff;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 2px 5px #000;
}
.circle03 { 
    margin-left: -35px;
    margin-top: 60px;
    width: 66px; 
    height: 66px;
    background-color: #FBC335;
    border-radius: 50%;
    padding-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
} 
.yourLogo03{
    width: 55px;
    height: 50px;
    z-index: 99;
}
.logoImage03{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.underneathDetails03{
    width: 100%;
    padding: 13% 10% 10% 10%;
    color: #000;
    clear: both;
}
.voterDetailsText03{
    width: 67%;
    float: left
}
.voterDetailsText03 h5{
    font-weight: 600;
    line-height: 0.2;
}
.voterDetailsText03 h1{
    line-height: 0.9;
    font-size: 2.5em;
    font-weight: 800;
    text-shadow: 1px 1px #fff;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 90px;
}
.voterDetailsText03 p{ color: #FBC335; }

.dateTextDiv03{
    margin-top: 5%;
    width: 32%;
    float: right;
}
.dateTextDiv03 h5{
    text-align: right;
    line-height: 1;
    font-weight: 700;
    overflow: hidden;
}

@media (max-width: 992px) {
    .outSideDiv03{ height: 550px; }  
}

@media (max-width: 770px) {
    .mainDetailsText03{ padding-top: 17%; }

    .underneathDetails03{ padding: 12% 10% 10% 10%; }
}

@media (max-width: 550px) {
    h2.byElectionClass03{
        font-size: 2em;
        max-height: 66px;
    }
    .mainDetailsText03{ padding-top: 20%; }

    .underneathDetails03{ padding-top: 12%; }

    .voterDetailsText03 h1{
        font-size: 2em;
        max-height: 80px;
    }
    .dateTextDiv03{ margin-top: 7%; }

    .dateTextDiv03 h5{ font-size: 1.1em; }
}
@media (max-width: 450px) {
    .outSideDiv03{ height: 400px; }
    
    h2.byElectionClass03{
        font-size: 1.4em;
        max-height: 55px;
    }
    .mainDetailsText03{ padding-top: 18%; }

    .underneathDetails03{ padding-top: 12%; }

    .imagesDivFlex03{
        width: 170px;
        height: 170px;
    }
    .circle03 { 
        width: 55px; 
        height: 55px;
    } 
    .yourLogo03{
        width: 46px; 
        height: 42px;
    }
    .voterDetailsText03 h5{ line-height: 0.2; }
    .voterDetailsText03 h1{
        font-size: 1.5em;
        max-height: 60px;
    }
    .dateTextDiv03 h5{ font-size: 1em; }
}

@media (max-width: 350px) {
    .outSideDiv03{ height: 300px; }
    h2.byElectionClass03{
        font-size: 1.1em;
        max-height: 40px;
    }
    .mainDetailsText03{ padding-top: 20%; }
    
    .imagesDivFlex03{
        width: 135px;
        height: 135px;
    }
    .circle03 { 
        width: 48px; 
        height: 48px;
        margin-top: 35px;
        padding-top: 3px;
    } 
    .yourLogo03{
        width: 40px; 
        height: 38px;
    }
    .voterDetailsText03 h5{ font-size: 0.7em; }
    
    .voterDetailsText03 h1{
        font-size: 1.1em;
        max-height: 42px;
    }
    .dateTextDiv03 h5{ font-size: 0.7em; }  
}
