body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: 'Rage Italic';
    src: local('Rage Italic'), url('../../../assets/RAGE_1.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}
.parentContainer15 {
    height: 550px;
    margin: 5px;
    background: url(../assets/RE5.jpg); 
    background-position: center;
    background-size: 100%;
	background-repeat: no-repeat;
    color: #FBFEFF;
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerImageTextDiv15{
    width: 50%;
    height: calc(100% - 300px);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv15{
    width: 200px;
    height: 200px;
}
.middleImage15{
    width: 80%;
    height: 80%;
    border-radius: 50%;
    object-fit: cover;
}
.underTextsDiv15{
    max-width: 100%;
    margin-top: -15%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.newYearText15{
    font-family: 'Rage Italic';
    color: #D7AC5F;
    font-size: 2.2em;
}
.otherTexts15{
    max-width: 100%;
    max-height: 30%;
    font-size: 1em;
    margin-top: -3%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
}
.fromTexts15{
    max-width: 100px;
    font-size: 0.8em;
    margin-top: 2%;
    background: #282F49;
    color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 1200px) {
    .parentContainer15{
        height: 450px;
    }
    .centerImageTextDiv15{
        height: calc(100% - 250px);
    }
    .imageDiv15{
        width: 170px;
        height: 170px;
    }
    .newYearText15{
        font-size: 2em;
    }
    .otherTexts15{
        max-height: 34%;
        font-size: 0.8em;
        margin-top: -5%;
    }
    .fromTexts15{
        max-width: 80px;
        font-size: 0.6em;
    }
}
@media (max-width: 992px)
{
    .parentContainer15{
        height: 400px;
    }
    .centerImageTextDiv15{
        height: calc(100% - 220px);
    }
    .imageDiv15{
        width: 120px;
        height: 120px;
    }
    .underTextsDiv15{
        margin-top: -12%;
    }
    .newYearText15{
        font-size: 1.75em;
    }
    .otherTexts15{
        font-size: 0.75em;
        max-height: 35%;
    }
    .fromTexts15{
        max-width: 85px;
        max-height: 30px;
        margin-top: -10%;
        font-size: 0.65em;
    }   
}
@media (max-width: 770px) {
    .parentContainer15{
        height: 500px;
    }
    .centerImageTextDiv15{
        height: calc(100% - 260px);
    }
    .imageDiv15{
        width: 170px;
        height: 170px;
    }
    .newYearText15{
        font-size: 2em;
    }
    .otherTexts15{
        font-size: 1em;
    }
}
@media (max-width: 550px)
{
    .parentContainer15{
        height: 450px;
    }
    .centerImageTextDiv15{
        height: calc(100% - 250px);
    }
    .imageDiv15{
        width: 140px;
        height: 140px;
    }
    .underTextsDiv15{ margin-top: -10%; }

    .newYearText15{ font-size: 1.8em; }
    
    .otherTexts15{
        margin-top: -4%;
        font-size: 0.8em;
        max-height: 30%; 
    }
    
    .fromTexts15{
        margin-top: -5%;
        max-width: 70px;
        max-height: 25px;
        font-size: 0.5em;
    }   
}
@media (max-width: 450px) {
    .parentContainer15{
        height: 400px;
    }
    .centerImageTextDiv15{
        height: calc(100% - 280px);
    }
    .imageDiv15{
        width: 100px;
        height: 100px;
    }
    .underTextsDiv15{ margin-top: -10%; }
    
    .newYearText15{ font-size: 1.3em; }
    
    .otherTexts15{ 
        font-size: 0.6em;
        margin-top: -8%;
        max-height: 30%;
    }
    
    .fromTexts15{
        margin-top: -10%;
        max-width: 55px;
        max-height: 20px;
        font-size: 0.4em;
    }    
     
}
@media (max-width: 350px) {
    .parentContainer15{
        height: 280px;
    }
    .centerImageTextDiv15{
        height: calc(100% - 170px);
    }
    .imageDiv15{
        width: 85px;
        height: 85px;
    }
    .underTextsDiv15{ margin-top: -10%; }
    
    .newYearText15{ font-size: 1em; }
    
    .otherTexts15{
        margin-top: -8%;
        font-size: 0.45em;
        max-height: 40px; 
    }
    .fromTexts15{
        margin-top: -8%;
        max-width: 50px;
        font-size: 0.35em;
    }
}