.outSideDiv13{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 550px;
    width: 100%;
    margin: 5px;
    background:linear-gradient(0deg, rgba(8, 62, 92, 0.7), rgba(11, 90, 133, 0.7)), url('../../../../../assets/images/templates/general/skyscrapperOne.jpg');
    background-size: cover;
    background-position: center;
	background-repeat: no-repeat;
	/* background-attachment: fixed; */
    color: #ffffff;
    box-shadow: 0px 0px 4px #000;
}

.circle13 { 
    width: 66px; 
    height: 66px;
    text-align: center; 
    position: relative; 
} 
.yourLogo13{
    width: 50px;
    height: 48px;
    position: absolute;
    margin-top: 60px;
    margin-left: 42px;
    z-index: 99;
}
.logoImage13{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
    
.triangle13 { 
    position: absolute; 
    left: 57%;
    margin-top: 70%;
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8; 
} 
  .triangle13:nth-child(2) { transform: rotate(10deg); } 
  .triangle13:nth-child(3) { transform: rotate(20deg); } 
  .triangle13:nth-child(4) { transform: rotate(30deg); } 
  .triangle13:nth-child(5) { transform: rotate(40deg); } 
  .triangle13:nth-child(6) { transform: rotate(50deg); } 
  .triangle13:nth-child(7) { transform: rotate(60deg); } 
  .triangle13:nth-child(8) { transform: rotate(70deg); } 
  .triangle13:nth-child(9) { transform: rotate(80deg); } 
  .triangle13:nth-child(10) { transform: rotate(90deg); } 
  .triangle13:nth-child(11) { transform: rotate(100deg); } 
  .triangle13:nth-child(13) { transform: rotate(110deg); }

.righttextDiv13{
    width: 35%;
    height: 9%;
    float: right;
    margin-top: -20px;
    margin-right: 5px;
    margin-bottom: -10px;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.2;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.righttextDiv13 p{
    max-width: 100%;
    max-height: 100%;
}
.clearRight13{ clear: right;}

.textDetailsDiv13{
    height: 330px;
    margin-left: 27px;
    text-align: center;
}

.textDetailsDiv13 h1{
    font-size: 3.2em;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
}
.textDetailsDiv13 h1.styledWord13{
    font-family: 'taken_by_vultures_demoregular';
    font-size: 10em;
    max-width: 100%;
    font-weight: normal;
    line-height: 0.5;
    text-transform: capitalize;
}
.textDetailsDiv13 span{ color: #F8C435; }

@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.divImageRect13{
    width: 80%;
    height: 53%;
    margin-top: 3%;
}
.imageRect13{
    width: 100%;
    height: 100%;
    margin-left: 8%;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.underTextDiv13{ 
    display: flex;
    width: 98%;
    margin-top: 66px;
}
.buyNowDiv13{
    width: 50%;
    margin-left: 5px;
}

h1.buyNowClass13{
    font-size: 3em;
    line-height: 0.3;
    color: rgba(8, 62, 92, 0.7);
    text-shadow: -1px 0 #ccc, 0 1px #ccc, 1px 0 #ccc, 0 -1px #ccc;
} 
h1.buyNowOne13{
    color: #ffffff !important;
    margin-top: -44px;

}
h1.buyNowTwo13{
    opacity: 0.3;
    margin-top: -38px;
}
h1.buyNowThree13{
    margin-top: 10px;
    opacity: 0.4;
}

.rightSideText13{
    width: 50%;
}
.coverImgRectDiv13{
    width: 90%;
    height: 90%;
}

.imagetextDiv13{
    max-width: 90%;
    margin-top: -45px;
    margin-left: 10px;
    text-align: left;
}
.imagetextDivOne13{
    max-height: 37%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.imagetextDiv13 p{
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1.3;
}
.imagetextDivTwo13{
    margin-top: 20px;
}
.imagetextDivTwo13 p{
    line-height: 1;
    margin-top: 1%;
    max-height: 30%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 1200px) {
    .righttextDiv13{
        max-height: 9%;
    }
    .imagetextDivOne13{ max-height: 34%; }
    
    .imagetextDivTwo13{ margin-top: 10px; }
}
@media (max-width: 992px) {
	.underTextDiv13{
        margin-top: 60px;
    }
	h1.buyNowClass13{
        font-size: 2.3em;
        line-height: 0.5;
    }
    p, .imagetextDiv13 p{ font-size: 0.75em; }
    
    .imagetextDivOne13{ max-height: 33%; }
    
    .imagetextDivTwo13{ margin-top: 10px; }
}
@media (max-width: 768px) {
    h1.styledWord13{
        font-size: 7em;
        line-height: 0.2;
    }
    .underTextDiv13{ margin-top: 60px; }
   
    .imagetextDivOne13{ max-height: 35%; }
    
    .imagetextDivTwo13{ margin-top: 10px; }
}

@media (max-width: 576px) {
    .outSideDiv13{
        height: 500px;
    }
    .underTextDiv13{ margin-top: 57px; }
   
    p, .imagetextDiv13 p{ font-size: 0.7em; }
    
    h1.buyNowClass13{
        font-size: 2em;
        line-height: 0.6;
    } 
    .imagetextDivOne13{ max-height: 35%; }
    
    .imagetextDivTwo13{ margin-top: 8px; }
}
@media (max-width: 435px) {
    h1.buyNowClass13{
        font-size: 1.8em;
        line-height: 0.7;
    } 
    .righttextDiv13{
        /* ma-width: 100%; */
        height: 8%;
    }
    .outSideDiv13{ height: 460px; }
    
    .divImageRect13{ height: 40%; }
    
    .imagetextDivOne13{ max-height: 37.5%; }
    
    .imagetextDivTwo13{ margin-top: 4px; }
}
@media (max-width: 350px) {
    p, .imagetextDiv13 p{
        font-size: 0.60em; }
    
    h1.buyNowClass13{
        font-size: 1.33em;
        line-height: 0.9;
    } 
    .imagetextDivOne13{ max-height: 28%; }
    
    .imagetextDivTwo13{ margin-top: 8px; }
}
