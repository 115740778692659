body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
.parentContain3 {
    height: 550px;
    margin: 5px;
    background: url(../assets/medil3.jpg); 
    background-position: center;
    background-size: 100%;
	background-repeat: no-repeat;
    color: #ffffff;
    font-size: 100%;
    padding-right: 5%;
    display: flex;
    justify-content: flex-end;
}
.centerImageTextDiv3{
    width: 35%;
    height: calc(100% - 350px);
    margin-top: 160px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv3{
    width: 100%;
    height: 200px;
}
.middleImage3{
    width: 100%;
    height: 170px;
    max-width: 87%;
    max-height:80%;
    border-radius: 50%;
    object-fit: cover;
}

.otherTexts3{
    max-width: 100%;
    max-height: 41%;
    word-wrap: break-word;
    font-size: 1em; 
    margin-top: -17%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
}
.fromTexts3{
    max-width: 65%;
    font-size: 0.8em;
    background: #B80000;
    padding: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 1200px) {
    .parentContain3{
        height: 450px;
    }
    .centerImageTextDiv3{ margin-top: 130px; }
    
    .imageDiv3{ height: 150px; }
    
    .middleImage3{ height: 120px; }
    
    .otherTexts3{
        font-size: 0.9em; 
        max-height: 88%; 
    }
}
@media (max-width: 992px) {
    .parentContain3{ height: 400px; }
    
    .centerImageTextDiv3{ margin-top: 115px; }
    
    .imageDiv3{
        height: 120px;
    }
    .middleImage3{
        width: 85%;
        height:80%;
    }
    .otherTexts3{
        margin-top: -15%;
        font-size: 0.75em; 
        max-height: 75px;
    }
    .fromTexts3{
        max-width: 60%;
        font-size: 0.65em;
    }
                                                                                
}
@media (max-width: 770px) {
    .parentContain3{ height: 500px; }
    
    .centerImageTextDiv3{ margin-top: 140px; }
    
    .imageDiv3{ height: 160px; }
    
    .otherTexts3{
        font-size: 0.8em; 
        max-height: 80px;
    }  
    .fromTexts3{ font-size: 0.68em; }                                                                  
}
@media (max-width: 550px) {
    .parentContain3{
        height: 450px;
    }
    .centerImageTextDiv3{
        height: calc(100% - 330px);
        margin-top: 130px;
    }
    .imageDiv3{
        height: 120px;
    }
    .middleImage3{
        width: 110px;
        height:  110px;
    }
    .otherTexts3{
        margin-top: -12%;
        max-height: 70%;
        font-size: 0.78em; 
    }
    .fromTexts3{
        max-width: 60%;
        font-size: 0.65em;
    }
}
@media (max-width: 450px) {
    .parentContain3{
        height: 400px;
    }
    .centerImageTextDiv3{
        height: calc(100% - 300px);
        margin-top: 120px;
    }
    .imageDiv3{
        height: 100px;
    }
    .middleImage3{
        width: 80px;
        height:  80px;
    }
    .otherTexts3{
        max-height: 65%;
        font-size: 0.55em; 
    }
    .fromTexts3{ font-size: 0.5em; }
}
@media (max-width: 350px) {
    .centerImageTextDiv3{
        height: calc(100% - 300px);
        margin-top: 140px;
    }
    .imageDiv3{
        height: 70px;
    }
    .middleImage3{
        width: 60px;
        height: 60px;
    }
    .otherTexts3{
        font-size: 0.47em;
        max-height: 60%; 
    }
    p.fromTexts3{
        margin-top: -13%;
        font-size: 0.35em;
    }
}