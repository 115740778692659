.outSideDiv09{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    background: linear-gradient(0deg, rgba(8,42,100, 0.9), rgba(8,42,100, 0.9)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
    display: flex;
    box-shadow: 0px 0px 4px #000;
}
.leftSideDiv09{
    width: 50%;
}
.mainInsideDiv09{
    margin-top: 15%;
    padding-left: 30px;
}
.circle09 { 
    width: 66px; 
    height: 66px;
    /* background-color: #FBC335; */
    border-radius: 50%;
    padding-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; 
    position: relative; 
} 
.yourLogo09{
    width: 50px;
    height: 45px;
    position: absolute;
    z-index: 99;
}
.logoImage09{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.textDetailsDiv09{
    width: 100%;
    height: 218px;
    margin-top: 90px;
}
.textDetailsDiv09 h1{
    font-size: 1.85em;
    font-weight: 650;
    line-height: 1;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 83%;
    padding-top: 5px;
    padding-bottom: 5px; 
}
.textDetailsDiv09 .roundyellow09{
    background-color: #FBC335;
    color: #000;
    font-size: 0.9em;
    font-weight: 200;
    padding: 0 6px;
    border-radius:50%;
}
.textDetailsDiv09 .subordinateText09{
    font-size: 1.1em;
    font-weight: 800;
}
.textDetailsDiv09 span.electionYear09{ 
    color: #FAC139;
    font-size: 1.1em;
}
.roundWhiteCircle09{
    background: #ffffff;
    width: 355px;
    height: 58%;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    left: -82px;
    bottom: -28.3%;
    clip-path: inset(0px 0px 50% 100px);
}
.textOnly09{
    width: 50%;
    margin-top: 17%;
    margin-left: 125px;
    font-weight: 700;
}
.textOnly09 p{
    color: #000;
    font-size: 0.8em;
    line-height: 1.1;
    z-index: 3;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 72.5px;
}
.textOnly09 span.h6{
    font-weight: 800;
    text-transform: capitalize;
}
.rightSideDiv09{
    width: 50%;
    height: 100%;
}
.coverImgRectDiv09{
    width: 85%;
    height: 85%;
    margin-top: 17%;
    margin-left: 18%;
    display: flex;
}
.imagesDivFlex09{
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.imgFlexed09{
    width: 100%;
    height: 42%;
    object-fit: cover;
    border: 3px solid #ffffff;
}
.imgFlexedTwo09{
    margin-top: 10%;
}
div.yellowBoard09{
    width: 100%;
    max-height: 10.4vh;
    background-color: #FAC139;
    color: #000;
    font-weight: 600;
    padding: 5px;
    text-transform: capitalize;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.yellowBoardOne09{
    margin-bottom: 5%;
}

@media (max-width: 992px) {
    .outSideDiv09{
        height: 550px;
    }
    .textDetailsDiv09 h1{
        font-size: 1.6em; 
    }
    .coverImgRectDiv09{
        height: 80%;
        margin-top: 20%;
    }
    div.yellowBoard09{
        font-weight: 600;
        font-size: 0.9em;
        max-height: 9vh;
    }
}

@media (max-width: 770px) {
    .outSideDiv09{ height: 570px; }
    .coverImgRectDiv09{
        height: 85%;
        margin-top: 17%;
    }
}

@media (max-width: 550px) {
    .outSideDiv09{ height: 500px; }
    
    .textDetailsDiv09 h1{
        font-size: 1.3em;
    }
    .roundWhiteCircle09{
        width: 320px;
    }
    .textOnly09 p{
        font-size: 0.7em;
        max-height: 62px;
    }
    .textOnly09 span.h6{ font-size: 1.3em; }
    
    .imagesDivFlex09{ 
        width: 80%;
    }
}
@media (max-width: 450px) {
    .outSideDiv09{ height: 400px; }

    .textDetailsDiv09{
        height: 130px;
        margin-top: 50px;
    }
    .textDetailsDiv09 h1{
        font-size: 1.1em;
    }
    .textDetailsDiv09 .roundyellow09{
        margin-left: 30%;
    }
    .roundWhiteCircle09{
        width: 280px;
    }
    .textOnly09{
        margin-left: 115px;
    }
    .textOnly09 span.h6{
        font-size: 1.1em;
    }
    .textOnly09 p{
        max-width: 90%;
        font-size: 0.7em;
    }
    .coverImgRectDiv09{
        height: 100%;
        margin: 20% 0 0 10%;
    }
    .imagesDivFlex09{ 
        width: 95%;
        height: 85%;
    }
    div.yellowBoard09{
        font-size: 0.7em;
        max-height: 10vh;
    }
    .imgFlexedTwo09{
        margin-top: 15%;
    }
}

@media (max-width: 350px) {
    .outSideDiv09{
        height: 400px;
    }
    .mainInsideDiv09{
        padding-left: 10px;
    }
    .textDetailsDiv09{
        margin-top: 50%;
    }
    .textDetailsDiv09 h1{
        font-size: 0.95em;
    }
    .textDetailsDiv09 .roundyellow09{
        margin-left: 30%;
    }
    .textDetailsDiv09 .subordinateText09{
        font-size: 0.9em;
    }
    .roundWhiteCircle09{
        width: 255px;
    }
    .textOnly09{
        width: 45%;
        margin-left: 115px;
    }
    .textOnly09 p{
        font-size: 0.6em;
        max-width: 90%;
    }
    .textOnly09 span.h6{
        font-size: 1em;
    }
    .coverImgRectDiv09{
        width: 80%;
        height: 90%;
        margin-top: 20%;
    }
    .imagesDivFlex09{
        width: 90%;
        height: 88%;
    }
    div.yellowBoard09{
        font-size: 0.5em;
    }
    
}

