 body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.parentContainer01 {
	height: 500px;
	width: 100%;
	background-image: url('../assets/bg1.png');
	background-color: #ffffff;
	/*background:linear-gradient(0deg, rgba(43, 62, 139, 0.9), rgba(43, 62, 139, 0.9));*/
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: 0% 50%;
	color: #ffffff;
	padding: 10px;
	color: #000;
	border-bottom: 17px solid #ff0000;
}
.dateDiv01 {
	position: absolute;
	right: 0;
	top: 0;
	margin-right: 50px;
	margin-top: 30px;
	font-size: 20px;
	text-align: right;
	text-transform: uppercase;
}
.happyBirthday01 {
	font-family: 'taken_by_vultures_demoregular';
	position: absolute;
	font-size: 60px;
	word-break: break-word;
	text-transform: capitalize;
	margin-left: 70px;
	-ms-transform: rotate(-40deg); /* IE 9 */
  	transform: rotate(-40deg);
}
.birthday01 {
	margin-top: -40px;
	margin-left: 20px;
}

.month01 {
	font-size: 9px;
	text-transform: lowercase;
}
.celebrantImageDiv01 {
	display: flex;
	justify-content: center;
	margin-top: 15%;
}
.celebrantImageWrapper01 {
	background-color: black;
	height: 140px;
	width: 140px;
	outline: none;
	border-radius: 50%;
	display: flex;
}
.celebrantImage01 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}
.celebrantName01 {
	text-align: center;
	font-size: 20px;
	margin-top: 10px;
	word-break: break-word;
}
.extraNoteDiv01 {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}
.extraNote01 {
	text-align: center;
	font-size: 10px;
	letter-spacing: 1.2px;
	word-spacing: 2.2px;
	max-width: 80%;
	/*color: rgba(255, 255, 255, 0.5);*/
}

.bottomPart01 {
	position: absolute;
	bottom: 0px;
	left: 0;
	margin-bottom: 20px;
	width: 100%;
}

@media (max-width: 768px) {
	.parentContainer01 {
		height: 450px;
		padding: 20px;
	}
	.happyBirthday01 {
		font-size: 40px;
	}
	.dateDiv01 {
		font-size: 13px;
	}
	.celebrantImageWrapper01 {
		height: 130px;
		width: 130px;
	}
	.birthday01 {
		margin-top: -20px;
		margin-left: 20px;
	}
	.celebrantName01 {
		font-size: 13px;
	}
	.extraNote01 {
		font-size: 7px;
	}
}

@media (max-width: 576px) {
	.parentContainer01 {
		height: 350px;
		padding: 10px;
	}
	.dateDiv01 {
		position: absolute;
		right: 0;
		top: 0;
		margin-right: 30px;
		margin-top: 20px;
		font-size: 10px;
		text-align: right;
	}
	.celebrantImageDiv01 {
		display: flex;
		justify-content: center;
		margin-top: 15%;
	}
	.celebrantImageWrapper01 {
		background-color: black;
		height: 80px;
		width: 80px;
		border-radius: 50%;
		display: flex;
	}
	.celebrantImage01 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
	}
	.happyBirthday01 {
		font-size: 20px;
		margin-top: 20px;
	}	
	.birthday01 {
		margin-top: -8px;
		margin-left: 10px;
	}
	.celebrantName01 {
		font-size: 11px;
	}
	.extraNoteDiv01 {
		margin-top: 7px;
	}
	.extraNote01 {
		font-size: 5.5px;
	}
} 