.outSideContainer2{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 550px;
    width: 100%;
    /* margin: 5px; */
    display: flex-column;
    box-shadow: 0px 0px 4px #000;
}
.equalHalves2{
    width: 100%;
    height: 50%;
}
.topHalf2{
    background: url('../../../../../assets/images/templates/general/cloth1.jpg');
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position:center !important;
    z-index: -6;
}
.bottomHalf2{
    background: url('../../../../../assets/images/templates/general/cloth2.jpg');
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position:center !important;
    z-index: -4;
}
.coverWrapper2{
    position: absolute;
    height: 100%;
    width: 96%;
    display: flex;
}
.halfDiv2{
    position: relative;
    width: 50%;
    height: 100%;
}
.divOne2{
    background: linear-gradient(180deg, rgba(0, 51, 102, 0.6), rgba(0, 51, 102, 0.9)); 
}
.divTwo2{
    background: linear-gradient(180deg, rgba(246,237,230, 0.6), rgba(246,237,230, 0.9));
    z-index: 1;
}
.topRightDiv2{
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    width: 30%;
    height: 57px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.yourLogo2{
    width: 50px;
    height: 38px;
    position: absolute;
    margin-top: 20px;
    margin-left: 26px;
    z-index: 99;
}
.logoImage2{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
#clearright2{
    clear: right;
}
.middleDiv2{
    position: absolute;
	width: 360px;
    height: 85%;
    top: 15%;
    justify-content: center;
    left: 45%;
    z-index: 4;
}
.midcircleDiv2{
    position: relative;
    width: 100%;
    height: 75%;
    margin: auto;
    display: flex;
}
.middleCircle2{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #ED008C;
    border: 7px solid #F3951B;
    box-shadow: 0px 0px 6px 1px #000 inset;
    text-align: center;
    padding-top: 22%;
    color: #FFFFFF;
}
.middleCircle2 h6.headandtail2{
    font-size: 2em;
    line-height: 0.85;
    font-weight: bold;
    margin-left: 6%;
    /* padding: 3px 0; */
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 88%;
    height: 10%;
}

.middleCircle2 h1.middletext2{
    font-size: 8em;
    font-weight: 700;
    text-shadow: 2px 2px 5px #000;
    line-height: 0.8;
}
.bottomRectangle2{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 20%;
    background: #F7941D;
    color: #FFFFFF;
    align-self: flex-end;
    text-align: justify;
    padding: 1% 2.5% 0 2.5%;
}
.bottomRectangle2:before, .bottomRectangle2:after{
    z-index: -1;
    position: absolute;
    content: "";
    bottom:5px;
    left: 5px;
    height:20px;
    width:100%;
    max-width: 200px;
    background: #F7941D;
    -webkit-box-shadow: 0 10px 10px #000;
    -moz-box-shadow: 0 10px 10px #000;
    box-shadow: 0 10px 10px #000;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
}
.bottomRectangle2:after{
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 5px;
    left:auto;
}

.bottomRectangle2 p{
    font-size: 1.05em;
    font-weight: bold;
    margin-top: 10px;
    line-height: 1.1;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 80%;
}

.underDiv2{
    position: relative;
    margin-top: 7%;
    width: 100%;
}
.underDiv2 p, .underDiv2 h2{
    color: #ffffff;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.underDiv2 p{
    font-weight: bold;
    font-size: 0.75em;
    margin-top: -2%;
    max-height: 18px;
    text-shadow: 2px 2px 3px #000;
}
.underDiv2 h2 {
    font-weight: bold;
    font-size: 1.8em;
    margin-top: -5%;
    max-height: 33px;
    text-shadow: 2px 2px 4px #000000;
}
.footerLink2{
    float: right;
    margin-top: 520px;
    margin-right: -45px;
    font-size: 0.8em;
    color: #000;
}
@media (max-width: 1200px) {
    .middleDiv2{
        left: 30%;
    }
}
@media (max-width: 992px) and (min-width: 767px) {
    .outSideContainer2{
        height: 450px;
    }
    .yourLogo2{
        width: 50px;
        height: 35px;
        margin-top: 10px;
        margin-left: 10px;
    }
    .middleDiv2{
        width: 290px;
        justify-content: center;
        /* left: 15%; */
    }
    .middleCircle2{
        border: 6px solid #F3951B;
        padding-top: 20%;
    }
    .middleCircle2 h6.headandtail2{
        font-size: 1.8em;
        line-height: 0.8;
    }
    .middleCircle2 h1.middletext2{ font-size: 5.5em; }
  
    .bottomRectangle2{ padding: 1% 2.5% 0 2.5%; }
  
    .bottomRectangle2:before, 
        .bottomRectangle2:after{
            max-width: 150px; 
    }
    .bottomRectangle2 p{ font-size: 0.9em; }
    
    .underDiv2{ margin-top: 7%; }

    .underDiv2 p{ font-size: 0.65em; }
    
    .underDiv2 h2 {
        font-size: 1.5em;
        margin-top: -7%;
    }
    .footerLink2{
        margin-top: 430px;
        font-size: 0.7em;
    }
}
@media (max-width: 550px) {
    .outSideContainer2{
        height: 400px;
    }
    .middleDiv2{
        width: 280px;
        height: 80%;
        top: 10%;
        left: 40%;
    }
    .midcircleDiv2{
        height: 85%;
    }
    .middleCircle2{ padding-top: 23%; }
    
    .middleCircle2 h6.headandtail2{ 
        font-size: 1.7em; 
        height: 23px;
    }
    
    .middleCircle2 h1.middletext2{ font-size: 4.3em; }

    .bottomRectangle2 p{ font-size: 0.8em; }

    .underDiv2 p{
        font-size: 0.65em;
        max-height: 12px;
    }
    .underDiv2 h2 {
        font-size: 1.4em;
        margin-top: -6%;
        max-height: 22px;
    }
    .footerLink2{
        float: right;
        margin-top: 380px;
        margin-right: -45px;
        font-size: 0.8em;
        color: #000;
    }
}
@media (max-width: 450px){
    .topRightDiv2{
        margin-right: 30px;
        width: 40%;
    }
    .middleDiv2{
        justify-content: center;
        width: 260px;
        top: 10%;
        margin-left: -15%;
    }
    .footerLink2{
        font-size: 0.6em;
    }
}
@media (max-width: 350px){
    .outSideContainer2{ height: 350px; }
    
    .yourLogo2{
        width: 40px;
        height: 35px;
        margin-top: 5px;
        margin-left: 17px;
    }
    
    /* .topRightDiv2 h6{ font-size: 1.05em; }

    .secondDot2{ 
        margin-top: -27px;
        margin-left: 29px; 
    } */
    .middleDiv2{
        justify-content: center;
        width: 210px;
        margin-left: -17%;
    }
    .middleCircle2{
        border: 5px solid #F3951B;
        padding-top: 25%;
    }
    .middleCircle2 h6.headandtail2{ font-size: 1.35em; }

    .bottomRectangle2:before, .bottomRectangle2:after{
        bottom:10px;
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    .bottomRectangle2:after{
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    
    .bottomRectangle2 p{ font-size: 0.63em; }
    
    .underDiv2 p{ font-size: 0.5em; }
    
    .underDiv2 h2 {
        font-size: 1.15em;
        margin-top: -7%;
    }
    .footerLink2{
        margin-top: 330px;
        font-size: 0.5em;
    }
}