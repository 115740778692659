.outSideDiv6{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
  width: 100%;
  height: 580px;
  margin: 5px;
  color: #000;
  box-shadow: 0px 0px 4px #000;
}
.circle6 { 
  width: 68px; 
  height: 68px;
  text-align: center; 
  position: relative; 
} 
.yourLogo6{
  width: 50px;
  height: 38px;
  max-height: 38px;
  position: absolute;
  margin-top: 33px;
  margin-left: 24px;
  z-index: 99;
}
.logoImage6{
  width: 85%;
  height: 85%;
  object-fit: cover;
}

.triangle6 { 
  position: absolute; 
  left: 30%;
  top: 23%; 
  width: 86.6%; 
  height: 75%; 
  background-color: #FBC335; 
  clip-path: polygon(50% 0, 0 100%, 100% 100%); 
  transform-origin: center 66.6%;
  color: #000;
  text-align:center;
  font-size: 2em;
  z-index: 8; 
} 

.triangle6:nth-child(2) { transform: rotate(10deg); } 
.triangle6:nth-child(3) { transform: rotate(20deg); } 
.triangle6:nth-child(4) { transform: rotate(30deg); } 
.triangle6:nth-child(5) { transform: rotate(40deg); } 
.triangle6:nth-child(6) { transform: rotate(50deg); } 
.triangle6:nth-child(7) { transform: rotate(60deg); } 
.triangle6:nth-child(8) { transform: rotate(70deg); } 
.triangle6:nth-child(9) { transform: rotate(80deg); } 
.triangle6:nth-child(10) { transform: rotate(90deg); } 
.triangle6:nth-child(11) { transform: rotate(100deg); } 
.triangle6:nth-child(12) { transform: rotate(110deg); }

.detailHere6{
  width: 300px;
  height: 20%;
  margin: -7% 0 0 24%;
  text-decoration: underline;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.coverLineDiv6{ position: relative; }

.lineDiv6{
    position: absolute;
    height: 3px;
    width: 85%;
    margin-left: 10%;
    background: #093950;
}

div.firstlineDiv6{ margin-top: 17.5%; }

div.secondlineDiv6{ margin-top: 35%; }

.detailHere6 h1{
  font-size: 3em;
  font-weight: 700;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-height: 120px; */
  max-height: 95%;
}

.centerContainer6{
  width: 100%;
  height: 70%;
  padding: 3% 0;
}
.blueLineDiv6{
  width: 100%;
  height: 30%;
  margin-top: 10%;
  background: #3995C8;
  z-index: -1;
}
.imagesFlexDiv6{
  height: 67%;
  width: 76%;
  margin-top: -165px;
  margin-left: 12%;
  display: flex;
}
.halfImg6{
  width: 50%;
  height: 100%;
  object-fit: cover;
  border: 4px solid #ffffff;
}

.belowImgDiv6{
  width: 82%;
  height: 30%;
  margin-left: 9%;
  display: flex;
}
.devFlexCircle6{
  width: 35%;
}
.circleTwo6 { 
  width: 95px;
  height: 95px;
  text-align: center;
  position: relative;
}

.getUpTo6{
  width: 80px;
  position: absolute;
  margin-top: 23px;
  margin-left: 0px;
  font-size: 0.8em;
  font-weight: 700;
  color: #000;
  z-index: 99;
}
.getUpTo6 h5{ 
  font-size: 1.75em;
  font-weight: 700;
  line-height:1; 
}
.secondTriangle6 { 
  position: absolute;
  width: 86.6%;
  height: 75%;
  margin-top: 5%;
  background-color: #FBC335;
  clip-path: polygon(50% 0, 0 100%, 100% 100%); 
  transform-origin: center 66.6%;
  color: #000;
  text-align:center;
  font-size: 2em;
  z-index: 8;
}

.secondTriangle6:nth-child(2) { transform: rotate(10deg); } 
.secondTriangle6:nth-child(3) { transform: rotate(20deg); } 
.secondTriangle6:nth-child(4) { transform: rotate(30deg); } 
.secondTriangle6:nth-child(5) { transform: rotate(40deg); } 
.secondTriangle6:nth-child(6) { transform: rotate(50deg); } 
.secondTriangle6:nth-child(7) { transform: rotate(60deg); } 
.secondTriangle6:nth-child(8) { transform: rotate(70deg); } 
.secondTriangle6:nth-child(9) { transform: rotate(80deg); } 
.secondTriangle6:nth-child(10) { transform: rotate(90deg); } 
.secondTriangle6:nth-child(11) { transform: rotate(100deg); } 
.secondTriangle6:nth-child(12) { transform: rotate(110deg); }

.divFlexText6{
  margin-top: 18px;
  margin-left: 15px;
  width: 80%;
  height: 39px;
}
.divFlexText6 p{
  margin-top: -5px;
  line-height: 1.05;
  padding-bottom: 2px;
  text-transform: capitalize;
  font-size: 1.25em;
  font-weight: 700;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
  max-height: 100%;
}
@media (max-width: 992px) {
  .outSideDiv6{
    height: 430px;
  }
  .detailHere6{
    width: 230px;
    margin: -10% 0 7% 23%;
  }
  .detailHere6 h1{ 
    font-size: 2.3em;
    max-height: 95%; 
  }
  .imagesFlexDiv6{
    height: 65%;
    margin-top: -155px;
    margin-bottom: 10px;
  }
  .divFlexText6{
    margin-top: 3px;
  } 
  .divFlexText6 p{
    margin-top: -6px;
    font-size: 1em !important;
    max-width: 90%;
    max-height: 82%;
  }
}

@media (max-width: 470px) {
  .circle6 { 
    width: 48px; 
    height: 48px;
  } 
  .yourLogo6{
    width: 30px;
    height: 29.5px;
    margin-top: 23px;
    margin-left: 19px;
    font-size: 0.75em;
  }
  
  .imagesFlexDiv6{
    height: 65%;
    width: 78%;
    margin-top: -145px;
  }
  .divFlexText6{
    margin-top: 10px;
  }
  .divFlexText6 p{
    font-size: 0.8em !important;
    max-height: 63%;
  }
  .circleTwo6 { 
    width: 68px;
    height: 68px;
  }
  .getUpTo6{
    width: 60px;
    margin-top: 17px;
    font-size: 0.67em;
  }
  .getUpTo6 h5{ font-size: 1.55em; }
  
}
@media (max-width: 370px) {
  .outSideDiv6{
    height: 300px;
  }
  .detailHere6{
    width: 130px;
    margin: -12% 0 15% 30%;
  }
  div.firstlineDiv6{ margin-top: 17%; }
  
  div.secondlineDiv6{ margin-top: 34%; }
  
  .blueLineDiv6{
    height: 30%;
    margin-top: -1%;
  }
  .detailHere6 h1{ 
    font-size: 1.3em; 
    max-height: 83%;
  }
  .imagesFlexDiv6{
    /* height: 65%; */
    width: 85%;
    margin-left: 5%;
    margin-top: -105px;
  }
  .divFlexText6{
    margin-top: 10px;
  }
  .divFlexText6 p{ 
    font-size: 0.6em !important;
    max-width: 85%;
    max-height: 46%; 
  }

}

