.outSideDiv12{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 550px;
    margin: 5px;
    background:linear-gradient(0deg, rgba(23, 126, 103, 0.7), rgba(23, 126, 103, 0.7)), url('../../../../../assets/images/templates/general/skyscrapperOne.jpg');
    background-size: cover;
    background-position: center;
	background-repeat: no-repeat;
    color: #ffffff;
    box-shadow: 0px 0px 4px #000;
}

.circle12 { 
    width: 66px; 
    height: 66px;
    text-align: center; 
    position: relative; 
} 
.yourLogo12{
    width: 50px;
    height: 48px;
    max-height: 48px;
    position: absolute;
    margin-top: 58px;
    margin-left: 42px;
    z-index: 99;
}
.logoImage12{
    width: 80%;
    height: 80%;
    object-fit: cover;
}
    
.triangle12 { 
    position: absolute; 
    left: 57%;
    margin-top: 70%;
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8; 
} 
  .triangle12:nth-child(2) { transform: rotate(10deg); } 
  .triangle12:nth-child(3) { transform: rotate(20deg); } 
  .triangle12:nth-child(4) { transform: rotate(30deg); } 
  .triangle12:nth-child(5) { transform: rotate(40deg); } 
  .triangle12:nth-child(6) { transform: rotate(50deg); } 
  .triangle12:nth-child(7) { transform: rotate(60deg); } 
  .triangle12:nth-child(8) { transform: rotate(70deg); } 
  .triangle12:nth-child(9) { transform: rotate(80deg); } 
  .triangle12:nth-child(10) { transform: rotate(90deg); } 
  .triangle12:nth-child(11) { transform: rotate(100deg); } 
  .triangle12:nth-child(12) { transform: rotate(110deg); }

.righttextDiv12{
    /* width: 35%; */
    max-width: 40%;
    max-height: 8.3%;
    float: right;
    margin-top: -20px;
    margin-bottom: -10px;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.2;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .righttextDiv p{} */
.clearRight12{ clear: right;}

.textDetailsDiv12{
    height: 330px;
    margin-left: 27px;
    text-align: center;
}

.textDetailsDiv12 h1{
    font-size: 3.2em;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
}
.textDetailsDiv12 h1.styledWord12{
    font-family: 'taken_by_vultures_demoregular';
    /* font-family: 'Curlz MT','Allegratta Personal Use','Blackadder ITC', Rage,'Brush Script MT'; */
    font-size: 10em;
    line-height: 0.5;
    font-weight: normal;
    letter-spacing: 10px;
    text-transform: capitalize;
}
@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.textDetailsDiv12 span{ color: #F8C435; }

.divImageFlex12{
    width: 100%;
    height: 50%;
    display: flex;
    margin-top: 3%;
}
.imageFlexed12{
    width: 40%;
    height: 90%;
    object-fit: cover;
    margin-left: 5%;
}
.underTextDiv12{ 
    display: flex;
    width: 98%;
    margin-top: 75px;
}
.buyNowDiv12{
    width: 50%;
    margin-left: 5px;
}

h1.buyNowClass12{
    font-size: 3em;
    line-height: 0.3;
    color: rgba(23, 126, 103, 0.9);
    text-shadow: -1px 0 #ccc, 0 1px #ccc, 1px 0 #ccc, 0 -1px #ccc;
} 
h1.buyNowOne12{
    color: #ffffff !important;
    margin-top: -44px;

}
h1.buyNowTwo12{
    opacity: 0.3;
    margin-top: -38px;
}
h1.buyNowThree12{
    margin-top: 10px;
    opacity: 0.4;
}

.rightSideText12{
    width: 50%;
}
.coverImgRectDiv12{
    width: 90%;
    height: 90%;
}

.imagetextDiv12{
    width: 90%;
    max-height: 40px;
    margin-top: -45px;
    margin-left: 10px;
    text-align: left;
   
}
.imagetextDivOne12{
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.imagetextDiv12 p{
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1.1;
}
.imagetextDivTwo12{
    margin-top: 10px;
}

@media (max-width: 992px) {
    .underTextDiv12{
        margin-top: 60px;
    }
	h1.buyNowClass12{
        font-size: 2.3em;
        line-height: 0.5;
    }
    p, .imagetextDiv12 p{
        font-size: 0.75em;
    }
    .imagetextDiv12{
        max-height: 36px; 
    }
}
@media (max-width: 768px) {
    h1.styledWord12{
        font-size: 7em;
        line-height: 0.2;
    }
    .underTextDiv12{
        margin-top: 60px;
    }
    .imagetextDivTwo12{
        margin-top: 15px;
    }
}

@media (max-width: 576px) {
    .outSideDiv12{
        height: 500px;
    }
    .underTextDiv12{
        margin-top: 47px;
    }
    p, .imagetextDiv12 p{
        font-size: 0.7em;
    }
	h1.buyNowClass12{
        font-size: 2em;
        line-height: 0.6;
    } 
    .imagetextDiv12{
        max-height: 33px;
    }
}
@media (max-width: 430px) {
    .outSideDiv12{
        height: 460px;
    }
    .divImageFlex12{
        height: 40%;
    }
    .imagetextDivTwo12{
        margin-top: 5px;
    }
}
@media (max-width: 350px) {
    p, .imagetextDiv12 p{
        font-size: 0.65em;
    }
    h1.buyNowClass12{
        font-size: 1.13em;
        line-height: 0.3;
    }
    .imagetextDivOne12 p{
        max-height: 20px;
    } 
    .imagetextDivTwo12{
        margin-top: 2px;
        line-height: 0.7;
    }
    .imagetextDivTwo12 p{
        line-height: 0.8;
    }
}
