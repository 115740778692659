.parentContainer{
    width: 100%;
    position: relative;
    overflow: hidden;
}
.outSideDiv9{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 550px;  
    margin: 5px;
    background:linear-gradient(0deg, rgba(13,53,98, 0.7), rgba(13,53,98, 0.7)), url('../../../../../assets/images/templates/general/skyscrapperOne.jpg');
    background-size: cover;
    background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
    color: #ffffff;
    display: flex;
    box-shadow: 0px 0px 4px #000;
}
.leftSideDiv9{
    width: 50%
}

.mainInsideDiv9{
    margin-top: 30%;
    margin-left: 15px;
}

.circle9 { 
    width: 66px; 
    height: 66px;
    text-align: center; 
    position: relative; 
} 
.yourLogo9{
    width: 50px;
    height: 45px;
    max-height: 45px;
    position: absolute;
    margin-top: -25px;
    margin-left: 36px;
    z-index: 99;
}
.logoImage9{
    width: 80%;
    height: 80%;
    object-fit: cover;
}   
.triangle9 { 
    position: absolute; 
    left: 50%;
    top: -60%; 
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8; 
} 
  .triangle9:nth-child(2) { transform: rotate(10deg); } 
  .triangle9:nth-child(3) { transform: rotate(20deg); } 
  .triangle9:nth-child(4) { transform: rotate(30deg); } 
  .triangle9:nth-child(5) { transform: rotate(40deg); } 
  .triangle9:nth-child(6) { transform: rotate(50deg); } 
  .triangle9:nth-child(7) { transform: rotate(60deg); } 
  .triangle9:nth-child(8) { transform: rotate(70deg); } 
  .triangle9:nth-child(9) { transform: rotate(80deg); } 
  .triangle9:nth-child(10) { transform: rotate(90deg); } 
  .triangle9:nth-child(11) { transform: rotate(100deg); } 
  .triangle9:nth-child(12) { transform: rotate(110deg); }

.textDetailsDiv9{
    width: 80%;
    height: 230px;
    margin-top: 5px;
    margin-left: 27px;
}

.textDetailsDiv9 h1{
    font-size: 3.5em;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
    max-height: 70%;
}
.textDetailsDiv9 span{
    color: #FAC139;
}
.textDetailsDiv9 p{
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
}
.roundWhiteCircle9{
    background: #ffffff;
    width: 57%;
    height: 57%;
    border-radius: 50%;
    z-index: 1;
}

.whiteCircleOne9{
    position: absolute;
    left: -96px;
    margin-top: 12px;
    clip-path: inset(0px 0px 50% 100px);
}
.textOnly9{
    max-width: 50%;
    height: 20%;
    margin-top: 22%;
    margin-left: 37%;
}
.textOnly9 p{
    color: #1B4966;
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.1;
    z-index: 3;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 56px;
}
.rightSideDiv9{
    width: 50%;
    height: 100%;
}
.coverImgRectDiv9{
    width: 80%;
    height: 80%;
    margin-top: 97px;
    display: flex;
}
.roundImageCircle9{
    object-fit: cover;
    width: 80%;
    height: 90%;
    border: 15px solid #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.whiteCircleTwo9{
    position: absolute;
    margin-top: -147px;
    margin-left: -5.3%;
    clip-path: inset(50px 32% 0px 0px); 
}
.imagetextDiv9{
    width: 70%;
    height: 10%;
    margin: 370px 0 0 20%;
}

.imagetextDiv9 p{
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1.3;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 47px;
}

@media (max-width: 992px) {
    .outSideDiv9{
        height: 450px;
    }
    .textDetailsDiv9{
        margin-top: 10px;
    }
    .textDetailsDiv9 h1{
        font-size: 2.8em;
        line-height: 1;
    }

    .textDetailsDiv9 p{
        font-size: 0.7em;
        max-height: 40px;
    }
    .roundWhiteCircle9{
        width: 75%;
        height: 60%;
    }
    
    .whiteCircleOne9{
        margin-top: -50px;
    }

    .textOnly9{
        max-width: 45%;
        margin-top: 20%;
        margin-left: 41%;
    }
    .textOnly9 p{ 
        font-size: 0.8em;
        max-height: 65px; 
    }

    .roundImageCircle9{
        width: 71%;
        height: 78%;
    }
    
    .whiteCircleTwo9{ margin-left: 0.5%; } 
    
    .imagetextDiv9{
        height: 15%;
        margin-top: 280px;
        margin-left: 40%;
    }
    
    .imagetextDiv9 p{ 
        font-size: 0.65em;
        max-height: 35px;
    }
}
@media (max-width: 770px) {
    .textDetailsDiv9{ margin-top: 2px; }
    
    .textDetailsDiv9 h1{ font-size: 3em; }
    
    .textDetailsDiv9 p{
        max-height: 30px;
    }
    .roundWhiteCircle9{
        width: 58%;
        height: 55%;
    }
    
    .whiteCircleOne9{ margin-top: -47.5px; }

    .textOnly9{ margin-top: 18%; }
    
    .textOnly9 p{ 
        font-size: 0.75em;
        max-height: 60px; 
    }

    .roundImageCircle9{
        width: 71%;
        height: 78%;
    }
    
    .whiteCircleTwo9{ margin-left: 1%; } 
    
    .imagetextDiv9 p{ 
        font-size: 0.75em; 
        max-height: 42px;
    }
}
@media (max-width: 540px) {
    .whiteCircleOne9{ margin-top: -33px; }

    .whiteCircleTwo9{ margin-left: 0.8%; } 
    .textOnly9{
        margin-left: 43%;
    }

}
@media (max-width: 430px) {
    .parentContainer{
        overflow-x: scroll;
    }
    .outSideDiv9{ height: 360px; }
    
    .textDetailsDiv9{ 
        margin-top: -5px;
        margin-left: 7%; 
    }
    
    .textDetailsDiv9 h1{ font-size: 2.5em; }

    .textDetailsDiv9 p, 
        .textOnly9 p, 
            .imagetextDiv9 p{
                font-size: 0.6em;
                max-height: 34px;
    }
    .roundWhiteCircle9{
        width: 75%;
        height: 45%;
    }
    
    .whiteCircleOne9{ margin-top: -58px; }

    .textOnly9{
        margin-top: 13%;
        margin-left: 46%;
    }
    .roundImageCircle9{
        width: 60%;
        height: 80%;
    }
    
    .whiteCircleTwo9{ margin-left: 7.5%; } 
    
    .imagetextDiv9{
        width: 80%;
        margin-top: 210px;
        margin-left: 30%;
    }
}
@media (max-width: 350px) {
    .outSideDiv9{
        height: 280px;
    }
    .circle9 { 
        width: 50px; 
        height: 50px;
    } 
    .yourLogo9{
        width: 40px;
        height: 38px;
        margin-top: -15px;
        margin-left: 17px;
        font-size: 0.85em;
    }    
    .triangle9 { 
        left: 30%;
        top: -50%; 
    } 
    .textDetailsDiv9{ 
        margin-top: 3px;
        margin-left: 1%;
    }
    
    .textDetailsDiv9 h1{ font-size: 1.8em; }

    .textDetailsDiv9 p, 
        .imagetextDiv9 p{
         font-size: 0.5em;
         max-height: 40px;
    }
    
    .roundWhiteCircle9{
        width: 220px;
        height: 45%;
    }
    
    .whiteCircleOne9{ margin-top: -96px; }

    .textOnly9{ margin-left: 52%; }
    
    .textOnly9 p{ font-size: 0.45em; }

    .roundImageCircle9{
        width: 65%;
        height: 80%;
    }
    
    .whiteCircleTwo9{ margin-left: 3.5%; } 
    
    .imagetextDiv9{
        height: 15%;
        margin-top: 130px;
        margin-left: 40%;
    }
}
  
