.parentContainer8 {
	height: 500px;
	width: 100%;
	background: url('./assets/bg.jpeg');
	background-size: cover;
	background-repeat: no-repeat;
	color: #ffffff;
	padding: 50px !important;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.topTag8 {
	position: absolute;
	background-color: #ff0000;
	height: 15px;
	width: 80px;
	margin-left: 40px;
}
.bottomTag8 {
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: #ff0000;
	height: 15px;
	width: 80px;
	margin-right: 60px;
}
.imageContainer8 {
	background: #F5F5F5;
	padding: 15px;
	margin-bottom: 20px;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.45) !important;
}
.celebrantImageWrapper8 {
	background-color: black;
	height: 200px;
	width: 250px;
	display: flex;
}
.celebrantImageWrapper8 > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.celebrantName8 {
	max-width: 250px;
	word-break: break-word;
	font-size: 18px;
	font-weight: bold;
	margin-top: 10px;
	text-align: center;
	color: #000000;
}
.happyBirthday8 {
	font-family: 'taken_by_vultures_demoregular';
	font-size: 60px;
	word-break: break-word;
	text-transform: capitalize;
	text-align: center;
	/*width: 60%;*/
	max-width: 75%;
	color: #000000;
	margin-left: -50px;
}
.textBirthday8 {
	display: block;
	margin-top: -35px;
	margin-left: 90px;
}

@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@media (max-width: 768px) {
	.parentContainer8 {
		height: 400px;
		padding: 30px;
	}
	.imageContainer8 {
		padding: 7px;
	}
	.celebrantImageWrapper8 {
		height: 110px;
		width: 160px;
	}
	.happyBirthday8 {
		font-size: 40px;
	}
	.celebrantName8 {
		font-size: 11px;
	}
}

@media (max-width: 576px) {
	.parentContainer8 {
		height: 300px;
		padding: 20px;
	}
	.topTag8 {
		height: 10px;
		width: 50px;
	}
	.bottomTag8 {
		height: 10px;
		width: 50px;
	}
	.imageContainer8 {
		padding: 7px;
		margin-bottom: 10px;
	}
	.celebrantImageWrapper8 {
		height: 100px;
		width: 150px;
	}
	.happyBirthday8 {
		font-size: 30px;
	}
	.celebrantName8 {
		font-size: 12px;
	}
	.textBirthday8 {
		display: block;
		margin-top: -15px;
		margin-left: 40px;
	}
} 