.outSideDiv8{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 550px;
    margin: 5px;
    background:linear-gradient(0deg, rgba(13,53,98, 0.7), #0d3562b3), url('../../../../../assets/images/templates/general/skyscrapperOne.jpg');
	background-size: cover;
	background-repeat: no-repeat;
    color: #ffffff;
    display: flex;
    box-shadow: 0px 0px 4px #000;
}
.leftSideDiv8{
    width: 50%;
}
.mainInsideDiv8{
    margin-top: 30%;
    margin-left: 15px;
}
.circle8 { 
    width: 66px; 
    height: 66px;
    text-align: center; 
    position: relative; 
} 
.yourLogo8{
    width: 50px;
    height: 45px;
    position: absolute;
    margin-top: -21px;
    margin-left: 33px;
    z-index: 99;
}
.logoImage8{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.triangle8 { 
    position: absolute; 
    left: 45%;
    top: -53%; 
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    /* font-size: 2em; */
    z-index: 8; 
} 
  .triangle8:nth-child(2) { transform: rotate(10deg); } 
  .triangle8:nth-child(3) { transform: rotate(20deg); } 
  .triangle8:nth-child(4) { transform: rotate(30deg); } 
  .triangle8:nth-child(5) { transform: rotate(40deg); } 
  .triangle8:nth-child(6) { transform: rotate(50deg); } 
  .triangle8:nth-child(7) { transform: rotate(60deg); } 
  .triangle8:nth-child(8) { transform: rotate(70deg); } 
  .triangle8:nth-child(9) { transform: rotate(80deg); } 
  .triangle8:nth-child(10) { transform: rotate(90deg); } 
  .triangle8:nth-child(11) { transform: rotate(100deg); } 
  .triangle8:nth-child(12) { transform: rotate(110deg); }

.textDetailsDiv8{
    width: 95%;
    height: 230px;
    margin-top: 20px;
    margin-left: 27px;
}
.textDetailsDiv8 h1{
    font-size: 3.5em;
    font-weight: 650;
    line-height: 1;
    text-transform: capitalize;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 68%;
    padding-bottom: 5px; 
}
.textDetailsDiv8 span{ color: #FAC139; }

.textDetailsDiv8 p{
    max-width: 95%;
    margin-top: 8%;
    font-size: 1em;
    font-weight: 600;
    line-height: 1;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 47px;
}
.roundWhiteCircle8{
    background: #ffffff;
    width: 355px;
    height: 58%;
    border-radius: 50%;
    z-index: 1;
}
.whiteCircle8{
    position: absolute;
    left: -81px;
    margin-top: 1px;
    clip-path: inset(0px 0px 50% 100px);
}
.textOnly8{
    width: 50%;
    margin-top: 17%;
    margin-left: 125px;
}
.textOnly8 p{
    color: #1B4966;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.1;
    z-index: 3;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 65px;
}
.rightSideDiv8{
    width: 50%;
    height: 100%;
}
.coverImgRectDiv8{
    width: 80%;
    height: 85%;
    margin-top: 25%;
    margin-left: 20%;
    display: flex;
}
.imagesDivFlex8{
    width: 75%;
    height: 88%;
    display: flex;
    flex-direction: column;
}
.imgFlexed8{
    width: 100%;
    height: 48%;
    object-fit: cover;
    border: 3px solid #ffffff;
}
.imgFlexedTwo8{
    margin-top: 5%;
}

.imagesDivFlex8 p{
    margin-top: 15px;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.3;
}
.yellowColorDiv8{
    width: 25%;
    height: 30%;
    margin-top: 60%;
    margin-left: 4%;
    background: #FBC436;
}

@media (max-width: 992px) {
    .outSideDiv8{
        height: 430px;
    }
    .yourLogo8{
        margin-left: 23px;
        max-height: 43px;
    }
    .triangle8 { left: 30%; } 

    .textDetailsDiv8{
        margin-top: 1px;
        margin-left: 15px;
    }
    
    .textDetailsDiv8 h1{ 
        font-size: 2.9em; 
        max-height: 59%;
    }
    
    .textDetailsDiv8 p{
        margin-top: 12%;
        font-size: 0.73em;
        max-height: 30px;
    }
    .roundWhiteCircle8{
        width: 300px;
        height: 50%;
    }
    .whiteCircle8{
        left: -81px;
        margin-top: -33px;
    }
    .textOnly8{ margin-left: 117px; }
    
    .textOnly8 p{ 
        font-size: 0.7em;
        max-height: 45px; 
    }
   
    .coverImgRectDiv8{ margin-top: 30%; }
    
    .imgFlexedTwo8{ margin-top: 5%; }
    
    .imagesDivFlex8 p{ font-size: 0.65em; }

    .yellowColorDiv8{ margin-top: 75%; }
    
}
@media (max-width: 770px) {
    .outSideDiv8{ height: 450px; }
}
@media (max-width: 550px) {
    .outSideDiv8{ height: 400px; }
    
    .textDetailsDiv8 h1{ font-size: 2.7em; }
    
    .textDetailsDiv8 p{ margin-top: 5%; }
   
    .roundWhiteCircle8{
        width: 270px;
        height: 210px;
    }
    .whiteCircle8{
        left: -81px;
        top: 83.5%;
    }
    .imagesDivFlex8{ 
        width: 100%;
        margin-top: -10%; 
    }

    .yellowColorDiv8{ margin-top: 50%; }
}

@media (max-width: 350px) {
    .outSideDiv8{
        height: 300px;
    }
    .triangle8{
        margin-top: 20%;
        margin-left: -25%
    }
    .yourLogo8{
        margin-top: -7px;
        margin-left: 10%;
    }
    .textDetailsDiv8{ 
        width: 90%;
        margin-left: 7%;
     }

    .textDetailsDiv8 h1{ 
        font-size: 1.8em; 
        max-height: 37%;
    }
    
    .textDetailsDiv8 p{
        margin-top: 10%;
        font-size: 0.45em;
        max-height: 18px;
    }
    .roundWhiteCircle8{ 
        width: 220px;
        height: 48%;
    }

    .whiteCircle8{ top: 85%; }
    
    .textOnly8{
        width: 43%;
        margin-top: 15%;
        margin-left: 110px;
    }
    .textOnly8 p{
        max-height: 33px;
    }
    .textOnly8 p, .imagesDivFlex8 p{ font-size: 0.5em; }
    
    .rightSideDiv8{ width: 60%; }
    
    .coverImgRectDiv8{
        width: 80%;
        height: 75%;
        margin-top: 40%;
    }
    .imagesDivFlex8{
        width: 75%;
        height: 88%;
        display: flex;
        flex-direction: column;
    }
    .imgFlexed8{
        width: 100%;
        height: 48%;
        object-fit: cover;
        border: 3px solid #ffffff;
    }
    .imgFlexedTwo8{ margin-top: 5%; }

    .yellowColorDiv8{ margin-top: 85%; }
}
