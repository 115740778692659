 body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.parentContainer03 {
	height: 550px;
	width: 100%;
	background-image: url('../assets/bg3.jpg');
	background-color: #ffffff;
	/*background:linear-gradient(0deg, rgba(43, 62, 139, 0.9), rgba(43, 62, 139, 0.9));*/
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: 0% 20%;
	/*background-attachment: fixed;*/
	color: #000000;
	/*padding: 10px;*/
}
.dateDiv03 {
	/*position: absolute;*/
	/*right: 0;*/
	/*top: 0;*/
	/*margin-right: 50px;*/
	/*margin-top: 30px;*/
	font-size: 20px;
	/*text-align: right;*/
	text-transform: uppercase;
}
.happyBirthday03 {
	/*font-family: 'taken_by_vultures_demoregular';*/
	/*position: absolute;*/
	font-size: 45px;
	text-align: center;
	/*word-break: break-word;*/
	text-transform: capitalize;
	/*margin-left: 70px;*/
}
.birthday03 {
	margin-top: -30px;
	/*margin-left: 20px;*/
}

.month03 {
	font-size: 9px;
	text-transform: lowercase;
}
.celebrantImageDiv03 {
	display: flex;
	justify-content: center;
	/*margin-top: 15%;*/
}
.celebrantImageWrapper03 {
	background-color: black;
	height: 90px;
	width: 90px;
	outline: none;
	border-radius: 50%;
	display: flex;
}
.celebrantImage03 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}
.celebrantName03 {
	text-align: center;
	font-size: 20px;
	margin-top: 10px;
	word-break: break-word;
}

.rightPart03{
	height: 100%;
	margin-top: 70%;
}
.extraNoteDiv03 {
	margin-top: 10px;
}
.extraNote03 {
	text-align: center;
	font-size: 10px;
	letter-spacing: 1.2px;
	word-spacing: 2.2px;
	/*height: 80px;*/
	/*max-width: 85%;*/
}

@media (max-width: 768px) {
	.parentContainer03 {
		height: 450px;
		padding: 20px;
	}
	.happyBirthday03 {
		font-size: 40px;
	}
	.dateDiv03 {
		font-size: 13px;
	}
	.celebrantImageWrapper03 {
		height: 150px;
		width: 150px;
	}
	.birthday03 {
		margin-top: -20px;
		margin-left: 20px;
	}
	.celebrantName03 {
		font-size: 17px;
	}
	.extraNote03 {
		font-size: 7px;
	}
}

@media (max-width: 576px) {
	.parentContainer03 {
		height: 370px;
		padding: 10px;
	}
	.dateDiv03 {
		position: absolute;
		right: 0;
		top: 0;
		margin-right: 30px;
		margin-top: 20px;
		font-size: 10px;
		text-align: right;
	}
	.celebrantImageDiv03 {
		display: flex;
		justify-content: center;
		margin-top: 15%;
	}
	.celebrantImageWrapper03 {
		background-color: black;
		height: 60px;
		width: 60px;
		border-radius: 50%;
		display: flex;
	}
	.celebrantImage03 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
	}
	.happyBirthday03 {
		font-size: 20px;
		margin-top: 20px;
	}	
	.birthday03 {
		margin-top: -8px;
		margin-left: 10px;
	}
	.celebrantName03 {
		font-size: 13px;
	}
	.extraNoteDiv03 {
		margin-top: 7px;
	}
	.extraNote03 {
		font-size: 5.5px;
	}
} 