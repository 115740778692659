
$speeds: ("fast":0.4s, "medium":0.6s, "slow":0.9s);

@each $name, $speed in $speeds{
  .from-left-#{$name} {
    animation: fly-from-left $speed ease-out
  }
  .to-left-#{$name} {
    animation: fly-to-left $speed ease-out
  }
  .from-right-#{$name} {
    animation: fly-from-right $speed ease-out
  }
  .to-right-#{$name} {
    animation: fly-to-right $speed ease-out
  }
  .from-top-#{$name} {
    animation: fly-from-top $speed ease-out
  }
  .to-top-#{$name} {
    animation: fly-to-top $speed ease-out
  }
  .from-bottom-#{$name} {
    animation: fly-from-bottom $speed ease-out
  }
  .to-bottom-#{$name} {
    animation: fly-to-bottom $speed ease-out
  }
  .zoom-up-#{$name} {
    animation: zoom-up $speed ease-out
  }
  .zoom-off-#{$name} {
    animation: zoom-off $speed ease-out
  }
  .zoom-up-#{$name} {
    animation: zoom-up $speed ease-out
  }
  .big-zoom-up-#{$name} {
    animation: big-zoom-up $speed ease-out
  }  
}
.fixed-page {
  position: fixed;
  inset: 0;
}
.flex-mid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-enter{
  animation:  slider 0.5s cubic-bezier(0.5, 0.52, 0.165, 1)
}
.slide-leave{
  animation: slider 0.5s cubic-bezier(0.025, 0.382, 0.565, 1) reverse
}
@keyframes fly-from-left {
  0% { transform: translateX(-300px) }
  100%{ transform: translateX(0) }  
}
@keyframes fly-to-left {
  0% { transform: translateX(0) }
  100% { transform: translateX(-300px) }
}
@keyframes fly-from-right {
  0% { transform: translateX(300px) }
  100% { transform: translateX(0) }
}
@keyframes fly-to-right {
  0% { transform: translateX(0) }
  100% { transform: translateX(300px) }
}
@keyframes fly-from-top {
  0% { transform: translateY(-300px) }
  100% { transform: translateY(0) }
}
@keyframes fly-to-top {
  0% { transform: translateY(0) }
  100% { transform: translateY(-300px) }
}
@keyframes fly-from-bottom {
  0% { transform: translateY(300px) }
  100% { transform: translateY(0) }
}
@keyframes fly-to-bottom {
  0% { transform: translateY(0) }
  100% { transform: translateY(300px) }
}
@keyframes zoom-up {
  0% { transform: scale(0.3) }
  100% { transform: scale(1) }
}
@keyframes zoom-off {
  0% { transform: scale(1) }
  100% { transform: scale(0) }
}
@keyframes big-zoom-up {
  0% { transform: scale(0.55) }
  100% { transform: scale(1) }
}
@keyframes slider {
  0% {
    transform: scaleY(0);
    transform-origin: top
  }
  100% {
    transform-origin: top;
    transform: scaleY(1)
  }
}
.modal-style{
  background-color: rgba(0,0,0, 0.3);
  z-index: 2000 !important;
}
.center{
  position: absolute;
  padding: 12px 0px;
  max-width: 97vh;
  border-radius: 1rem;
  max-width: max-content;
  @extend .zoom-up-medium
}
.center-leave{
  position: absolute;
  padding: 12px 0px;
  border-radius: 1rem;
  max-width: 97vh;    
  max-width: max-content;
  @extend .zoom-up-fast
}
.full{
  position: absolute;
  left:4px;
  bottom:4px;
  right:4px;
  top:4px;
  padding: 12px 0px;
  @extend .big-zoom-up-medium;
}
.full-leave{
  position: absolute;
  left:4px;
  bottom:4px;
  right:4px;
  top:4px;
  padding: 12px 0px;
  @extend .zoom-off-fast
}
.bottom{
  position: absolute;
  border-top-left-radius: 1.5rem/* 24px */;
  border-top-right-radius: 1.5rem/* 24px */;
  padding: 12px 0px;
  box-sizing: border-box;
  bottom: 0;    
  left:0;
  right:0;
  @extend .from-bottom-medium
}
.bottom-leave{
  position: absolute;
  border-top-left-radius: 1.5rem/* 24px */;
  border-top-right-radius: 1.5rem/* 24px */;
  padding: 12px 0px;
  box-sizing: border-box; 
  bottom: 0;    
  left:0;
  right:0;
  @extend .to-bottom-fast
}
.top{
  position: absolute;
  border-bottom-right-radius: 1.5rem/* 24px */;
  border-bottom-left-radius: 1.5rem/* 24px */; 
  box-sizing: border-box; 
  padding: 12px 0px;
  top:0;
  left:0;
  right:0;
  @extend .from-top-medium
}
.top-leave{
  position: absolute;
  border-bottom-right-radius: 1.5rem/* 24px */;
  border-bottom-left-radius: 1.5rem/* 24px */;
  padding: 12px 0px;
  box-sizing: border-box; 
  top:0;
  left:0;
  right:0;
  @extend .to-top-fast
}
.right{
  position: absolute;
  padding: 0.5rem/* 8px */; 
  top:0;
  right:0;
  bottom: 0;
  width: 98%; 
  @extend .from-right-medium      
}
.right-leave{
  position: absolute;
  padding: 0.5rem/* 8px */; 
  top:0;
  right:0;
  bottom: 0;
  width: 98%; 
  @extend .to-right-medium      
}
.left{
  position: absolute;
  padding: 0.5rem/* 8px */; 
  top:0;
  left:0;
  bottom: 0;
  width: 98%; 
  @extend .from-left-medium    
}
.left-leave{
  position: absolute;
   padding: 0.5rem/* 8px */; 
  top:0;
  left:0;
  bottom: 0;
  width: 98%; 
  @extend .to-left-medium    
}
.close-icon{
  display: flex ;
  justify-content: flex-end;
  padding-right: 6px;
}

.sq-icon{
  background-color: white;
  cursor: pointer;
  border: 1.5px solid rgba(0,0,0, 0.5);
}
.round-icon{
  border-radius: 9999px;
  @extend .sq-icon
}
.left-close-icon{
  right: -0.5rem/* -8px */;
  top: 2.5rem/* 40px */;
}
.right-close-icon{
  top: 2.5rem/* 40px */;
  right: -0.5rem/* -8px */;
  transform: rotate(90deg);
}
.top-close-icon{
  bottom: -1.25rem/* -20px */;
  right: 3.5rem/* 56px */;  
  transform: rotate(90deg);
}
.bottom-close-icon{  
  top: -1.25rem/* -20px */;
  right: 3.5rem/* 56px */;  
  transform: rotate(-90deg);
}
@media (min-width: 320px) {
  .left-close-icon{
    right: -1.5rem/* -24px */;
  }
  .right-close-icon{
    left: -1.5rem/* -24px */;
  }
}
.center-close-icon{
  top: 0.5rem/* 8px */;
  right: 0.75rem/* 12px */;
}
.full-close-icon{   
  top:0;
  right:0;
}
.flow-box{
   overflow-y: auto !important;
}

.scroller{
  overflow-y: auto;
}

.scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.8);
  overflow-x: auto;
}

.scroller::-webkit-scrollbar {
  border-radius: 5px;
  width: 8px;
  height: 6px;
  background-color: rgba(240, 240, 240, 0.8);
  overflow-x: auto;
}

.scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0953DC;
  overflow-x: auto;
}

pt-3{
  padding-top: 12px;
}