 body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
.parentContainer5 {
	height: 500px;
	width: 100%;
	background:linear-gradient(0deg, rgba(0, 26, 43, 0.8), rgba(0, 26, 43, 0.8)), url('../../../../../assets/images/templates/heroes/2A.jpeg');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: top center;
	color: #ffffff;
	padding: 30px;
	position: relative;
}
.contentWrapper5 {
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	bottom: 0;
	left: 0;
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.circleBackground5 {
	background-color: #ffffff;
	border-radius: 50%;
	height: 250px;
	width: 250px;
	/*margin-right: 10px;*/
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.happyBirthday5 {
	font-size: 40px;
	font-weight: bold;
	color: #000000;
	word-break: break-word;
	text-transform: uppercase;
	text-align: center;
}
.celebrantName5 {
	font-size: 25px;
	color: #8D032D;
	text-transform: uppercase;
	max-width: 210px;
	word-wrap: break-word;
}
.fromSender5 {
	font-size: 15px;
	letter-spacing: 0.6rem;
	text-align: center;
	max-width: 230px;
	word-wrap: break-word;
}

@media (max-width: 768px) {
	.parentContainer5 {
		padding: 30px;
		height: 400px;
	}
	.circleBackground5 {
		height: 150px;
		width: 150px;
	}
	.happyBirthday5 {
		font-size: 22px;
	}
	.celebrantName5 {
		font-size: 15px;
	}
	.fromSender5 {
		font-size: 10px;
		max-width: 180px;
	}
}

@media (max-width: 576px) {
	.parentContainer5 {
		height: 300px;
		padding: 20px;
		background-size: 100%;
		background-position: left top;
	}
	.circleBackground5 {
		height: 120px;
		width: 120px;
	}
	.happyBirthday5 {
		font-size: 17px;
	}
	.celebrantName5 {
		font-size: 9px;
		max-width: 96px;
	}
	.fromSender5 {
		font-size: 5px;
		letter-spacing: 6px;
		max-width: 170px;
	}
}
@media (max-width: 576px) {
	.parentContainer5 {
		height: 270px;
	}
	.fromSender5 {
		max-width: 130px;
	}
}