.outSideDiv05{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    box-shadow: 0px 0px 2px #000;
    display: flex;
}
.leftSidedDiv05{
    width: 50%;
    padding: 5% 0 5% 5%;
    background: linear-gradient(0deg, rgba(1,16,109, 0.9), rgba(1,16,109, 0.9)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
    background-size: cover;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-position: center;
}
.circleDiv05{ width: 20%; }

.circle05 { 
    width: 68px; 
    height: 68px;
    /* background-color: #FBC335; */
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; 
} 
.yourLogo05{
    width: 50px;
    height: 45px;
    z-index: 99;
}
.logoImage05{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.imagesDivFlex05{
    width: 250px;
    height: 250px;
    margin-top: 40%;
    margin-left: 50%;
}
.imgFlexed05{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.voterDetailsText05{
    height: 25%;
    margin-top: -15%;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}
.voterDetailsText05 h6{
    font-weight: 600;
}
.voterDetailsText05 h1{
    line-height: 1;
    font-size: 2.4em;
    font-weight: 700;
    text-shadow: 1px 1px #fff;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 107px;
}
.voterDetailsText05 p{
    color: #FBC335;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 47px;
}
.rightSidedDiv05{
    width: 50%;
    height: 100%;
    padding: 5% 5% 0 0;
    background-color: #fff;
    color: #01106D;
    z-index: -1;
}
.wrapperElection05{
    display: flex;
    justify-content: flex-end;
}
.electiontitle05{
    text-align: right;
    text-transform: uppercase;
    width: 100%;
    height: 155px;
}
.electiontitle05 h1, .electiontitle05 p{
    line-height: 0.96;
    font-weight: 700;
    font-size: 2.3em;
}
.electiontitle05 h1{
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 96px;
}
.electiontitle05 p.electionYear05{
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    max-height: 34px;
    float: right;
    padding: 2px 5px;
    background: #FBC335 !important;
}
.clearFloat{ clear: right; }

.bigCirclePlanet05{
    width: 250px;
    height: 250px;
    border: 1px solid #01106D;
    margin-top: 14px;
    margin-left: -36%;
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.smallCirclePlanet05{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #01106D;
    margin-bottom: 20%; 
    margin-right: 6px;
}
@media (max-width: 1200px) {
    .bigCirclePlanet05{ margin-top: 3px; }    
}
@media (max-width: 992px) {
    .outSideDiv05{ height: 550px; }
    
    .imagesDivFlex05{
        width: 225px;
        height: 225px;
        margin-left: 40%;
    }
    .voterDetailsText05 h1{
        font-size: 2.2em;
        max-height: 102px;
    }
    .voterDetailsText05 p{ max-height: 30px; }
    
    .rightSidedDiv05{
        width: 50%;
        height: 100%;
        padding: 5% 5% 0 0;
    }
    .electiontitle05{ height: 135px; }
    
    .electiontitle05 h1, .electiontitle05 p{ font-size: 2em; }
    
    .electiontitle05 h1{ max-height: 87px; }
    
    .electiontitle05 p.electionYear05{
        max-width: 160px;
        max-height: 30px;
    }
    .bigCirclePlanet05{
        width: 225px;
        height: 225px;
        margin-top: 12px;
        margin-left: -40%;
    }
}
@media (max-width: 770px) {
    .imagesDivFlex05{
        width: 240px;
        height: 240px;
        margin-top: 35%;
        margin-left: 50%;
    }
    .voterDetailsText05 h1{
        max-width: 90%; 
        font-size: 2.3em;
    }
    .bigCirclePlanet05{
        width: 240px;
        height: 240px;
        margin-top: 8%;
    }
}

@media (max-width: 550px) {
    .outSideDiv05{ height: 500px; }
    
    .imagesDivFlex05{
        width: 200px;
        height: 200px;
        margin-top: 35%;
        margin-left: 50%
    }
    .voterDetailsText05 h1{
        font-size: 2em;
        max-height: 90px;
    }
    .voterDetailsText05 p{
        font-size: 0.9em;
        max-height: 40px;
    }
    .electiontitle05 h1{
        font-size: 1.9em;
        max-height: 80px;
    }
    .bigCirclePlanet05{
        width: 200px;
        height: 200px;
        margin-top: 2%;
    }
    .smallCirclePlanet05{
        width: 20px;
        height: 20px;
        margin-right: 2px;
    }
}
@media (max-width: 450px) {
    .outSideDiv05{ height: 400px; }
    
    .circle05 { 
        width: 60px; 
        height: 60px;
    } 
    .imagesDivFlex05{
        width: 150px;
        height: 150px;
    }
    .voterDetailsText05 h1{
        font-size: 1.5em;
        max-height: 65px;
    }
    .voterDetailsText05 p{
        font-size: 0.7em;
        max-height: 30px;
    }
    .electiontitle05 h1, .electiontitle05 p{ font-size: 1.5em; }
    
    .electiontitle05 h1{ max-height: 61px; }
    
    .electiontitle05 p.electionYear05{
        max-width: 130px;
        max-height: 24px;
    }
    .bigCirclePlanet05{
        width: 150px;
        height: 150px;
        margin-top: -14%;
    }
    .smallCirclePlanet05{
        width: 18px;
        height: 18px;
    }
}

@media (max-width: 350px) {
    .outSideDiv05{ height: 350px; }
    
    .circle05 { 
        width: 50px; 
        height: 50px;
    } 
    .yourLogo05{
        width: 40px; 
        height: 40px;
    }
    .imagesDivFlex05{
        width: 125px;
        height: 125px;
    }
    .voterDetailsText05{ margin-top: 5%; }
    
    .voterDetailsText05 h6{ font-size: 0.8em; }
    
    .voterDetailsText05 h1{
        font-size: 1.1em;
        max-height: 50px;
    }
    .voterDetailsText05 p{
        font-size: 0.55em;
        max-height: 20px;
    }
    .electiontitle05 h1, .electiontitle05 p{ font-size: 1.1em; }
    
    .electiontitle05 h1{ max-height: 44px; }
    
    .electiontitle05 p.electionYear05{
        max-width: 105px;
        max-height: 16px;
    }
    .bigCirclePlanet05{
        width: 125px;
        height: 125px;
        margin-top: -36%;
    }
    .smallCirclePlanet05{
        width: 15px;
        height: 15px;
    }
}
