@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.parentContainer13 {
	height: 500px;
	width: 100%;
	background: #FEEAE3;
	padding: 20px !important;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
.outlineBorder13 {
	border: 10px solid #CDAF7A;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	padding: 20px 10px;
	height: 100%;
	width: 100%;
}
.leftDiv13 {
	width: 100%;
	padding-right: 20px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}
.celebrantName13 {
	max-width: 100%;
	word-break: break-word;
	font-size: 35px;
	font-weight: bold;
	color: #000000;
	text-align: right;
	text-transform: uppercase;
}
.happyBirthday13 {
	font-family: 'taken_by_vultures_demoregular';
	font-size: 60px;
	word-break: break-word;
	text-transform: capitalize;
	text-align: center;
	color: #000000;
	margin-left: -50px;
}
.textBirthday13 {
	display: block;
	margin-top: -35px;
	margin-left: 90px;
}
.imageContainer13 {
	background: #F5F5F5;
	padding: 5px;
	width: 100%;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.45) !important;
}
.celebrantImageWrapper13 {
	background-color: black;
	height: 100%;
	width: 100%;
	display: flex;
}
.celebrantImageWrapper13 > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.extraNote13 {
	font-size: 12px;
	letter-spacing: 0.3rem;
	writing-mode: vertical-rl;
}

@media (max-width: 768px) {
	.parentContainer13 {
		height: 380px;
		padding: 30px;
	}
	.happyBirthday13 {
		font-size: 20px;
		margin-top: 30px;
	}
	.textBirthday13 {
		display: block;
		margin-top: -15px;
		margin-left: 40px;
	}
	.celebrantName13 {
		font-size: 16px;
	}
	.extraNote13 {
		font-size: 8px;
	}
}

@media (max-width: 576px) {
	.parentContainer13 {
		height: 300px;
		padding: 10px;
	}
	.imageContainer13 {
		padding: 3px;
	}
	.happyBirthday13 {
		font-size: 20px;
	}
	.celebrantName13 {
		font-size: 12px;
	}
	.textBirthday13 {
		display: block;
		margin-top: -10px;
		margin-left: 30px;
	}
	.extraNote13 {
		font-size: 5px;
	}
} 