body {
  background-color: #f9fbff !important;
}

.bg-medillery {
  background-color: #f9fbff !important;
}
.heroBackground {
  display: flex;
  background: #357BFD;
  background: linear-gradient(#024DD7, #357BFD, #3474FC);
  height: 90vh;
  padding-top: 3em !important;
  justify-content: center;
  align-items: center;
}
.heroContainer{
  height: 70%;
  display: flex;
  align-items: center;
}
.heroText {
  font-size: 3.6rem !important;
  font-weight: 600 !important;
  text-align: center;
}
.heroSecondText {
  font-size: 1rem !important;
  color: #ffffff;
  margin-bottom: 2rem !important;
  text-align: center;
}
.heroCircleImage {
  width: 130px;
  height: 130px;
  border: 5px solid #EAEAEA;
  margin-top: 1.5em;
  border-radius: 50%;
}
.heroTextBelow {
  background-color: #C3FFD3;
  padding: 1.5em 0 !important;
}
.heroBtnBelow {
  font-size: 0.9rem !important;
  margin-top: 0.5rem !important;
  text-align: center;
}
.heroStartBtn {
  margin-left: 1em !important;
}
.heroStartText {
  font-weight: 700 !important;
}
.libraryBackground{
  min-height: 55vh !important;
}
.libraryCard {
  padding: 0 !important;
  margin: 0 !important;
}
.libraryImage {
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
}
.templateImgColumn {
  height: 65vh !important;
}
.templateImg {
  height: 35vh !important; 
}
.templateImgSmall {
  height: 30vh !important;
}

.libraryCaption {
  position: absolute;
  bottom: 0;
  text-align: start;
  width: 100%;
  padding: 4px 6px;
  color: #ffffff;
  text-shadow: 2px 2px 3px black;
  background: linear-gradient(rgba(18, 16, 16, 0.03), rgba(49, 43, 43, 0.2));
  font-weight: bold;
}
.campaignCard {
  height: 65vh;
}
.campaignOffset.card {
  margin-top: 5vh !important;
}
.heroStartBtn {
  margin-left: 1em !important;
}
.communityBackground {
  background-color: #000046;
}
.creatorImage {
  width: 5rem;
  position: absolute;
  border: 4px solid #fff;
  border-radius: 50%;
}
.creatorIcon {
  width: 2.8rem;
  position: absolute;
  border-radius: 50%;
}
.servicesBackground {
  background-image: url("./assets/images/website/homepage/services-bg.png");
  background-color: #f9fbff;
  background-position: 90%;
  background-repeat: no-repeat;
  background-size: 45%;
  padding: 3rem 0 3rem 0;
}
.whyMedilleryBackground {
  background-color: #000046;
  /* min-height: 75vh !important; */
  /* background-image: url("./assets/images/website/homepage/why-medillery.png"),
    url("./assets/images/website/homepage/why-medillery-bg.png");
  height: 85vh, 100%;
  background-position: 90% 50%, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: 15%, cover; */
}
.highlightsBackground {
  padding: 3rem 0 3rem 0;
  /* background-image: url("./assets/images/website/homepage/why-medillery.png"),
    url("./assets/images/website/homepage/why-medillery-bg.png");
  height: 85vh, 100%;
  background-position: 90% 50%, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: 15%, cover; */
}
/* .testimonyCard{
  margin: 0 4em;
} */
.profilePageHero {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background: linear-gradient(to right, #4285f4, #4fcdff) !important;
  /*background: url("./assets/images/dashboard/profile-page-hero.png"), linear-gradient(to right, #4285F4, #4FCDFF);*/
  /*background-image: url("./assets/images/dashboard/profile-page-hero.png");*/
  height: 200px;
  /*background-position: right;*/
  background-repeat: no-repeat;
  background-size: 20%;
  position: relative;
}

.servicesCard:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.5s;
}

.profilePageHeroChild {
  /*border: 1px solid white;*/
  margin-top: 0.25rem;
  background-image: url("./assets/images/dashboard/profile-page-hero.svg");
  height: 180px;
  width: 100%;
  right: 0px;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}
.testimoniesCard {
  width: 35em;
}
.App {
  text-align: center;
}
.active {
  color: #387dff !important;
  font-weight: bold !important;
}
.smallerText {
  font-size: 0.9rem;
}
.sidebarText {
  font-size: 0.85rem;
}
.navbarSearchBox{
  display: inline;
  position: relative;
  margin-left: 4em;
}
.App-logo {
  height: 40vmin;
}
.customPrimaryColor {
  color: #387dff !important;
}
.customPrimaryBgColor {
  background: linear-gradient(
    90deg,
    #387dff -4.49%,
    #004bd6 104.49%
  ) !important;
}
.customSecondaryColor {
  color: #f07672 !important;
}
.customSecondaryBgColor {
  background: linear-gradient(
    92.21deg,
    #fa9347 5.16%,
    #f07672 99.12%
  ) !important;
}
.primaryCustomColor {
  color: #f9fbff;
}
.primaryCustomBtn {
  color: #f9fbff;
  border: none;
  background: linear-gradient(90deg, #fa9347 -4.49%, #f07672 104.49%);
}
.sectionTitle {
  color: #004BD6;
  font-weight: bold;
}
.spaceWord {
  word-spacing: 0.25em;
}
.spaceText {
  letter-spacing: 0.25em;
}
.statusImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.gap1 {
  gap: 1px;
}
#heroInput::placeholder {
  color: #e5e5e5;
  opacity: 1; /* Firefox */
}
#heroInput {
  color: #ffffff;
}
#heroInput:focus {
  box-shadow: none;
}
.no-shadow {
  box-shadow: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.roundedClass {
  border-radius: "18.5px" !important;
}
.customNavToggler:focus {
  outline: none !important;
}
.App-link {
  color: #09d3ac;
}
.sideNavStyle {
  position: sticky !important;
  top: 70px;
  border-radius: 5px;
  height: 90vh;
}

.sideNavContent {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.rightNavStyle {
  position: sticky !important;
  top: 70px;
  border-radius: 5px;
  height: 90vh;
}
.mainDisplay {
  border: none;
  border-radius: 5px;
}
.chatNotFoundBg {
  background: url("./assets/images/dashboard/empty-chat.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 60vh;
  width: 30vh;
}
.pageNotFoundBg {
  background: url("./assets/images/not-found.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 60vh;
  width: 30vh;
}
.componentBg {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 0.5rem;
}
.statusBox {
  width: 75%;
}
.oneLineText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fabButton,
#fabButton {
  position: absolute;
  right: 0;
  bottom: 0;
}
.followersModalClass {
  width: 40% !important;
}
.wordBreakAll {
  word-break: break-all;
}
.wordBreakWord {
  word-break: break-word;
}
.tagProfessionalFab {
  position: fixed !important;
  color: #ffffff !important;
  bottom: 0;
  right: 0;
  margin-bottom: 15px !important;
  margin-right: 15px !important;
}
.cursorPointer:hover {
  cursor: pointer;
}
.cursorZoomIn:hover {
  cursor: zoom-in;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*DASHBOARD STYLES*/

/* PROJECT DETAIL IMAGES */
.projectImageContainer {
  height: 120px;
  width: 100%;
  position: relative;
}
.projectImageStyle {
  max-height: 100%;
  max-width: 100%;
}
.projectImageContainer:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("./assets/images/img.jpeg");
  background-size: 100px 100px;
  background-position: 30px 30px;
  background-repeat: no-repeat;
  opacity: 0.7;
}

.dashboardFabBtn {
  position: absolute;
  bottom: 2px;
  right: 2px;
  margin-bottom: 35px;
  margin-right: 35px;
  color: #ffffff;
  z-index: 50;
}

/*ENJOY SECTION */

/*TAG PROFRESSIONAL BUTTON AND TEXT*/
.tag-professional-group,
.update-status-group {
  /* causes absolute icon div to be positioned correctly */
  position: relative;

  /*width: 25rem;*/
  width: 100%;
  height: 3.2rem;
  border-radius: 18.5px;
  box-sizing: border-box;
}

.tag-professional-group .form-control,
.update-status-group .form-control {
  height: 100%;
  width: 100%;
  padding-right: 3.65rem;
  box-sizing: border-box;
}

.tag-professional-group .icon,
.update-status-group .icon {
  position: absolute;

  /* These are set relative to the height of the input box to bound the box neatly inside. This is aesthetic to me but you may change the dimensions of course. */
  right: 0.8rem;
  top: 0.47rem;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.3rem;

  /* content in the icon div is centered, without bootstrap or font-awesome you may wish to add your own text in the span */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.attachIcon {
  background-color: blue;
}
.attachIcon:hover {
  cursor: pointer;
}
/*TAG PROFRESSIONAL BUTTON AND TEXT ENDS HERE*/

/*STATUS IMAGE MODAL*/
/* Style the Image Used to Trigger the Modal */
#statusImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#statusImg:hover {
  opacity: 0.7;
}
/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}


@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #ffffff;
  font-size: 60px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/*STATUS IMAGE MODAL STYLES ENDS HERE*/

@media screen and (max-width: 823px) and (orientation: landscape) {
  .navbarSearchBox{
    display: none;
  }
}
@media (max-width: 1024px) {
  .heroText {
    font-size: 2.8rem !important;
  }
}
@media (min-width: 575px) and (max-width: 768px) {
  .navbarSearchBox{
    margin-left: 1em;
  }
  .heroBackground {
    height: 75vh;
    padding-top: 2.5em !important;
  }
  .heroContainer {
    height: 60%;  
  }
  .heroText {
    font-size: 2.5rem !important;
  }
  .heroSecondText {
    font-size: 0.95rem !important;
    margin-bottom: 1.4rem !important;
  }
  .heroCircleImage {
    width: 80px;
    height: 80px;
    margin-top: 0.7em;
    border: 4px solid #ffffff;
  }
  .heroBtnBelow {
    font-size: 0.9rem !important;
    margin-top: 0.4rem !important;
  }
  .libraryTitle{
    font-size: 1.0rem !important;
  }
  .templateImgColumn {
    height: 60vh !important;
  }
  .templateImg {
    height: 30vh !important; 
  }
  .templateImgSmall {
    height: 30vh !important;
  }
  .libraryCaption {
    font-size: 1em !important;
  }
  .testimoniesCard {
    width: 30em;
  }
  .hideSm {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .heroBackground {
    height: 70vh;
    padding-top: 0.2em !important;
  }
  .heroText {
    font-size: 2.5rem !important;
  }
  .heroSecondText {
    font-size: 0.8rem !important;
    margin-bottom: 1rem !important;
  }
  .heroImageRow .heroSecondText{
    margin-bottom: -1rem !important;
  }
  .heroCircleImage {
    width: 70px;
    height: 70px;
    margin-top: 0.6em;
    border: 4px solid #ffffff;
  }
  .heroTextBelow {
    padding: 1.4em 0 !important;
  }
  .heroBtnBelow {
    font-size: 0.8rem !important;
    margin-top: 0.6rem !important;
  }
  .libraryTitle{
    font-size: 1.0rem !important;
  }
  .templateImgColumn {
    height: 55vh !important;
  }
  .templateImg {
    height: 30vh !important; 
  }
  .templateImgSmall {
    height: 25vh !important;
  }
  .hideSm, .hideOnSmall{
    display: none !important;
  }  
  .campaignCard{
    height: 45vh;
  }
  .campaignCard .card{
    height: 40vh;
  }
  .campaignOffset.card {
    margin-top: 0 !important;
  }
  .communityBackground {
    background-color: #000046;
  }
  .servicesBackground {
    background-size: cover;
  }  
  .testimoniesCard {
    width: 25rem !important;
  }
  .navbarSearchBox{
    display: inline;
    margin-left: -0.6em;
    display: none !important;
  }
  .sideNavContent {
    border: none;
  }
  .sideNavStyle {
    z-index: 1;
  }
  .statusBox {
    width: 82% !important;
  }
  .smallerText {
    font-size: 0.8rem;
  }
  .sidebarText {
    font-size: 0.95rem;
  }
  .text-sm {
    font-size: 0.8rem;
  }
  .smallCenter {
    text-align: center;
  }
}
@media (max-width: 360px) {
  .navbarSearchBox{
    max-width: 75%;
  }
  .navbarSearchBox input{
    max-width: 75%;
  }
  .heroBackground {
    min-height: 65vh;
    padding-top: 0.2em !important;
  }
  .heroText {
    font-size: 2.5rem !important;
  }
  .heroSecondText {
    font-size: 0.75rem !important;
  }
  .heroImageRow .heroSecondText{
    margin-bottom: -1rem !important;
  }
  .heroImageColumn{
    padding: 5px 5px !important;
  }
  .heroCircleImage {
    width: 60px !important;
    height: 60px !important;
    margin-top: 0.5em;
    border: 3px solid #fff;
  }
  .heroBtnBelow {
    font-size: 0.75rem !important;
    margin-top: 0.17rem !important;
  }
  .heroStartBtn {
    padding: 0 !important;
    margin-left: 0.3em !important;
  }
  .heroStartBtn .heroStartText{
    font-size: 0.8em !important;
  }
  .templateImgColumn {
    height: 40vh !important;
  }
  .templateImg {
    height: 20vh !important; 
  }
  .templateImgSmall {
    height: 20vh !important;
  }
  .testimoniesCard {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (max-width: 289px) {
  .heroBackground {
    min-height: 75vh;
    padding-top: 1em !important;
  }
}

/*SIDE NAV CSS*/
.sidenav {
  height: 100%;
  position: fixed;
  z-index: 1030;
  top: 0;
  left: 0;
  background-size: cover;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 30px;
  margin-left: 50px;
  color: #ffffff;
}

@media screen and (max-height: 450px) {
  .sidenav a {
    font-size: 18px;
  }
}
#sidebarBackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  background: #ffffff;
  top: 0;
  left: 0;
  z-index: 100;
}
