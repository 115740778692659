.outSideDiv4{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 530px;
    /* margin: 5px; */
    display: flex;
    color: #000;
    box-shadow: 0px 0px 4px #000;
}

.leftSideDiv4{
    width: 63%;
    height: 100%;
    background: #FFFFFF;
}
.yellowCircle4 { 
    width: 64px; 
    height: 64px;
    text-align: center; 
    position: relative; 
} 
.yourLogo4{
    width: 50px;
    height: 38px;
    position: absolute;
    margin-top: 37px;
    margin-left: 26px;
    z-index: 99;
}
.logoImage4{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
    
.triangle4 { 
    position: absolute; 
    left: 35%;
    top: 35%; 
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8; 
} 
.triangle4:nth-child(2) { transform: rotate(10deg); } 
.triangle4:nth-child(3) { transform: rotate(20deg); } 
.triangle4:nth-child(4) { transform: rotate(30deg); } 
.triangle4:nth-child(5) { transform: rotate(40deg); } 
.triangle4:nth-child(6) { transform: rotate(50deg); } 
.triangle4:nth-child(7) { transform: rotate(60deg); } 
.triangle4:nth-child(8) { transform: rotate(70deg); } 
.triangle4:nth-child(9) { transform: rotate(80deg); } 
.triangle4:nth-child(10) { transform: rotate(90deg); } 
.triangle4:nth-child(11) { transform: rotate(100deg); } 
.triangle4:nth-child(12) { transform: rotate(110deg); }

.circleTwo4 { 
    width: 110px;
    height: 110px;
    text-align: center;
    position: relative;
}

.getUpTo4{
    width: 80px;
    height: 60px;
    position: absolute;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 90%;
    top: 80%;
    margin-top: 210px;
    margin-left: 116px;
    line-height: 0.7;
    font-size: 0.9em;
    font-weight: 700;
    color: #000;
    z-index: 99;
}
.getUpTo4 h4{ 
    font-weight: 700;
    text-transform: uppercase;
}

.secondTriangle4 { 
    position: absolute;
    left: 90%;
    top: 80%;
    margin-top: 182px;
    margin-left: 110px;
    width: 86.6%;
    height: 75%;
    background-color: #FBC335;
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8;
}

.secondTriangle4:nth-child(2) { transform: rotate(10deg); } 
.secondTriangle4:nth-child(3) { transform: rotate(20deg); } 
.secondTriangle4:nth-child(4) { transform: rotate(30deg); } 
.secondTriangle4:nth-child(5) { transform: rotate(40deg); } 
.secondTriangle4:nth-child(6) { transform: rotate(50deg); } 
.secondTriangle4:nth-child(7) { transform: rotate(60deg); } 
.secondTriangle4:nth-child(8) { transform: rotate(70deg); } 
.secondTriangle4:nth-child(9) { transform: rotate(80deg); } 
.secondTriangle4:nth-child(10) { transform: rotate(90deg); } 
.secondTriangle4:nth-child(11) { transform: rotate(100deg); } 
.secondTriangle4:nth-child(12) { transform: rotate(110deg); }

.insideDiv4{
    width: 65%;
    margin-left: 7%;
}
.textDiv4{
    margin-top: -18%;
}
.coverLineDiv4{ position: relative; }

.lineDiv4{
    position: absolute;
    height: 4px;
    width: 100%;
    background: #093950;
}
div.firstlineDiv4{ margin-top: 65px; }

div.secondlineDiv4{ margin-top: 135px; }

div.thirdlineDiv4{ margin-top: 198px; }

.textDiv4 h1{
    max-width: 100%;
    font-size: 3.8em;
    word-break: keep-all;
    text-transform: uppercase;
    font-weight: 700;
     
}
.textDiv4 p{
    white-space: nowrap;
    font-size: 1em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -7px;
}
.offerDateText{
    position: absolute;
    width: 75%;
    max-height: 30px;
    left: 5%;
    margin-left: 5%;
    margin-top: 8%;
    z-index: 2;
    font-size: 1.3em;
    font-weight: 700;
    color: #000;
    text-shadow: 2px 2px 6px #000000;
    display: flex;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.offerOne{
    width: 70%;
    text-align: right;
}
.offerTwo{
    width: 30%;
    text-align: left;
}
span#november4{ 
    color: #FFFFFF; 
    margin-left: 1.5%;
}
.rightSideDiv4{
    width: 37%;
    height: 100%;
    background: #093950;
    z-index: 1;
    color: #FFFFFF;
}

.imageFrame4{
    width: 120%;
    height: 50%;
    margin-top: 130px;
    margin-left: -60%;
    border: 5px solid #FFFFFF;
}

.mainImg4{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
p.tandc4{
    text-align: right;
    margin-right: 30%;
    margin-top: 36%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 20px;
}
p.tandc4 small{ 
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 1px;
}
@media (max-width: 992px) {
    .outSideDiv4{ height: 450px; }
    
    .textDiv4{ margin-top: -18%; }
    
    .lineDiv4{ height: 3px; }
    
    div.firstlineDiv4{ margin-top: 45px; }
    
    div.secondlineDiv4{ margin-top: 90px; }
    
    div.thirdlineDiv4{ margin-top: 135px; }
    
    .textDiv4 h1{ font-size: 2.6em; }
   
    .textDiv4 p{ width: 93%; }
    
    .offerDateText{
        margin-top: 12%;
        max-height: 25px;
        font-size: 1em;
    }
    .imageFrame4{
        height: 45%;
        margin-left: -55%;
    }
    .circleTwo4 { 
        width: 95px;
        height: 95px;
    }
    
    .getUpTo4{
        max-width: 75%;
        margin-top: 155px;
        margin-left: 45px;
        font-size: 0.75em;
    }
    
    .secondTriangle4 { 
        margin-top: 135px;
        margin-left: 40px;
        font-size: 1em !important;
    }
    p.tandc4{
        margin-top: 55%;
        margin-right: 30%;
    } 
}

@media (max-width: 770px) {
    .textDiv4{ margin-top: -12%; }
    
    div.firstlineDiv4{ margin-top: 50px; }
    
    div.secondlineDiv4{ margin-top: 99px; }
    
    div.thirdlineDiv4{ margin-top: 145px; }
    
    .textDiv4 h1{ font-size: 2.8em; }
    
    .textDiv4 p{ width: 93%; }
    
    .offerDateText{
        margin-top: 8%;
    }
    
    .imageFrame4{
        height: 48%;
        margin-left: -55%;
    }
    .circleTwo4 { 
        width: 95px;
        height: 95px;
    } 
    .getUpTo4{
        margin-top: 165px;
        margin-left: 95px;
    }
    .secondTriangle4 { 
        margin-top: 145px;
        margin-left: 90px;
    }
    p.tandc4{ margin-top: 40%; }
}

@media (max-width: 450px) {
    .outSideDiv4{
        height: 350px;
    }
    .yellowCircle4 { 
        width: 50px; 
        height: 50px;
    }
    .yourLogo4{
        width: 40px;
        height: 35px;
        position: absolute;
        margin-top: 27px;
        margin-left: 20px;
        z-index: 99;
    }
    .textDiv4{ margin-top: -20%; }
    
    .lineDiv4{ height: 3px; }
    
    div.firstlineDiv4{ margin-top: 38px; }
    
    div.secondlineDiv4{ margin-top: 77px; }
    
    div.thirdlineDiv4{ margin-top: 115px; }
    
    .textDiv4 h1{ font-size: 2.2em; }
    .textDiv4 p{ font-size: 0.6em; }
    
    .offerDateText{
        margin-top: 11%;
        font-size: 0.9em;
        max-height: 23px;
    }

    .imageFrame4{ 
        margin-top: 60%;
        height: 50%;
    }
    .circleTwo4 { 
        width: 80px;
        height: 80px;
    } 
    .getUpTo4{
        margin-top: 118px;
        margin-left: 55px;
        font-size: 0.6em;
        max-height: 50px;
    }
    .getUpTo4 h4{ 
        font-size: 2em;
    }
    .secondTriangle4 { 
        margin-top: 100px;
        margin-left: 50px;
    }

    p.tandc4{ margin-top: 45%; }
}
@media (max-width: 350px) {
    .outSideDiv4{
        height: 300px;
    }
        
    .textDiv4{ margin-top: -20%; }
    
    div.firstlineDiv4{ margin-top: 25px; }
    
    div.secondlineDiv4{ margin-top: 53px; }
    
    div.thirdlineDiv4{ margin-top: 79px; }
    
    .textDiv4 h1{ font-size: 1.5em; }
   
    .textDiv4 p{
        font-size: 0.4em !important;
        width: 93%; 
    }
    .offerDateText{
        max-height: 15px;
        margin-top: 50px;
        /* margin-top: 15%; */
        font-size: 0.6em;
    }
    .imageFrame4{ 
        margin-top: 80%;
        height: 45%; 
    }
    
    .circleTwo4 { 
        width: 75px;
        height: 75px;
    }
    .getUpTo4{
        margin-top: 90px;
        margin-left: 30px;
        font-size: 0.6em;
    }
    
    .secondTriangle4 { 
        margin-top: 70px;
        margin-left: 25px;
    }
    .getUpTo4 h4{ font-size: 1.9em}

    p.tandc4{ 
        margin-top: 63px;
        bottom: 0;
        max-height: 10px;
    }
}

