.faqSearchInput {
  background-color: transparent;
}
.faqSearchInput::-webkit-input-placeholder {
  color: #f2f2f2;
}
.faqSearchInput::placeholder {
  color: #f2f2f2;
}

.sectionTitle {
  color: #387DFF;
  /*font-weight: bold; */
}