.outSideDiv06{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    box-shadow: 0px 0px 2px #000;
    display: flex;
}
.leftSidedDiv06{
    width: 65%;
    padding: 5% 0 0 5%;
    background-color: #fff;
}
.circleDiv06{
    width: 20%;
}
.circle06 { 
    width: 68px; 
    height: 68px;
    /* background-color: #FBC335; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
} 
.yourLogo06{
    width: 50px;
    height: 45px;
    z-index: 99;
}
.logoImage06{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.textInfoText06{
    width: 85%;
    height: 55%;
    padding-top: 10%;
    color: #043788;
}
.voterDetailsText06{
    padding-top: 33%;
}
.voterDetailsText06{
    width: 85%;
    text-transform: uppercase;
}
.voterDetailsText06 h1.voteText06{
    line-height: 0.5;
}
.voterDetailsText06 h1{
    font-weight: 700;
    font-size: 2.8em;
    line-height: 1;
}
.aspirantName06{
    max-height: 125px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.voterDetailsText06 p{
    font-size: 1.05em;
    font-weight: 800;
    padding: 3px 5px;
    background-color: #FBC335;
    max-height: 50px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rightSidedDiv06{
    width: 35%;
    background-color: #01106D;
}
.imagesDivFlex06{
    width:100%;
    height: 38%;
    margin-top: 80%;
    margin-left: -35%;
}
.imgFlexed06{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid #ffffff;
}
.textBelowDiv06{
    position: absolute;
    width: 85%;
    height: 20%;
    margin-top: 5%;
    color: #000;
    text-align: center;
}
.textOnly06{
    height: 90%;
    font-size: 1.5em;
    margin: auto;
    line-height: 1;
    font-weight: 600;
}
.textOnly06 p.mandateText06{
    margin-left: 6%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 45px;
}
.textOnly06 p.lastp{
    margin-left: 3%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 23px;
}
.textOnly06 span{
    color: #FEA738;
    margin: 0 5px;
}
.textOnly06 span.venueText{
    color: #ffff;
    text-shadow: 2px 2px 2px #000;
}
@media (max-width: 1200px) {
    .voterDetailsText06 h1{ font-size: 2.35em; }
    
    .aspirantName06{ max-height: 105px; }
    
    .voterDetailsText06 p{
        font-size: 0.92em;
        max-height: 45px;
    }
    .imagesDivFlex06{
        width: 180px;
        margin-top: 90%;
    }
    .textBelowDiv06{ margin-top: 3%; }
    
    .textOnly06{ font-size: 1.2em; }
    
    .textOnly06 p.mandateText06{ max-height: 38px; }
    
    .textOnly06 p.lastp{ max-height: 20px; }
}
@media (max-width: 992px) {
    .outSideDiv06{ height: 550px; }
    
    .voterDetailsText06{ padding-top: 28%; }
    
    .voterDetailsText06 h1{
        font-size: 2em;
        max-height: 90px;
    }
    .voterDetailsText06 p{
        font-size: 0.77em;
        max-height: 40px;
    }
    .imagesDivFlex06{ width: 170px; }
    
    .textBelowDiv06{ margin-top: 1%; }
    
    .textOnly06{ font-size: 1.3em; }
    
    .textOnly06 p.mandateText06{ max-height: 43px; }
    
    .textOnly06 p.lastp{
        margin-top: 5%;
        max-height: 15px;
    }
}
@media (max-width: 770px) {
    .voterDetailsText06 h1{
        font-size: 2.6em;
        max-height: 115px;
    }
    .voterDetailsText06 p{
        font-size: 1.04em;
        max-height: 50px;
    }
    .imagesDivFlex06{
        width: 190px;
        height: 40%;
    }
    .textBelowDiv06{ margin-top: 7%; }
    
    .textOnly06{ font-size: 1.6em; }
    
    .textOnly06 p.mandateText06{
        margin-left: 6%;
        max-height: 50px;
    }
    .textOnly06 p.lastp{
        margin-left: 6%;
        max-height: 18px;
    }
}

@media (max-width: 550px) {
    .outSideDiv06{
        height: 500px;
    }
    .voterDetailsText06{
        padding-top: 30%;
    }
    .voterDetailsText06 h1{
        font-size: 2.1em;
        max-height: 95px;
    }
    .voterDetailsText06 p{
        font-size: 0.8em;
        max-height: 40px;
    }
    .imagesDivFlex06{
        width: 170px;
        margin-top: 90%;
    }
    .textOnly06{ font-size: 1.3em; }
    
    .textOnly06 p.mandateText06{ max-height: 40px; }
    
    .textOnly06 p.lastp{ max-height: 15px; }
}
@media (max-width: 450px) {
    .outSideDiv06{
        height: 400px;
    }
    .circle06 { 
        width: 60px; 
        height: 60px;
    } 
    .yourLogo06{
        width: 50px; 
        height: 45px;
    }
    .voterDetailsText06{
        padding-top: 40%;
    }
    .voterDetailsText06 h1{
        font-size: 1.6em;
        max-height: 70px;
    }
    .voterDetailsText06 p{
        font-size: 0.6em;
        max-height: 30px;
    }
    .imagesDivFlex06{
        width: 135px;
        margin-top: 100%;
    }
    .textOnly06{
        font-size: 1.1em;
    }
    .textOnly06 p.mandateText06{ max-height: 33px; }
    
    .textOnly06 p.lastp{ max-height: 10px; }
}

@media (max-width: 350px) {
    .outSideDiv06{
        height: 350px;
    }
    .circle06 { 
        width: 50px; 
        height: 50px;
    }
    .yourLogo06{
        width: 40px; 
        height: 35px;
    }
    .voterDetailsText06{
        padding-top: 35%;
    }
    .voterDetailsText06 h1{
        font-size: 1.25em;
        max-height: 57px;
    }
    .voterDetailsText06 p{
        font-size: 0.5em;
        max-height: 26px;
    }
    .imagesDivFlex06{
        width: 105px;
        height: 120px;
        margin-top: 120px;
    }
    .textOnly06{
        width: 85%;
        font-size: 0.95em;
    }
    .textOnly06 span{
        margin: 0 2px;
    }
    .textOnly06 p.mandateText06{ max-height: 24px; }
    
    .textOnly06 p.lastp{ max-height: 8px; }
}
