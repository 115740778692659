.outSideDiv08{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    color: #000 !important;
    box-shadow: 0px 0px 4px #000;
}
.mainInsideDiv08{
    width: 100%;
    height: 65%;
    background: linear-gradient(0deg, rgba(242, 242, 242, 0.7), rgba(242, 242, 242, 0.7)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 7%;
    display: flex;
    flex-direction: column;
}
.circle08 { 
    width: 68px; 
    height: 68px;
    /* background-color: #FBC335; */
    border-radius: 50%;
    padding: 10px 0 10px 10px;
    margin: auto;
    text-align: center; 
} 

.yourLogo08{
    width: 50px;
    height: 45px;
    z-index: 99;
}
.logoImage08{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.textImagesDiv08{
    width: 70%;
    margin: auto;
    height: 100%;
}
.presidentialText08{
    margin-top: 10px;
}
.presidentialText08 h5{
    font-weight: 650;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 57px;
}
.coverImgRectDiv08{
    width: 100%;
    height: 72%;
    margin-top: 15%;
    display: flex;
}
.imagesDivFlex08{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.imgFlexed08{
    width: 100%;
    height: 80%;
    object-fit: cover;
    border: 2px solid #ffffff;
    border-bottom: 0;
}
.imgFlexedTwo08{
    margin-left: 5%;
}
div.yellowBoard08{
    width: 100%;
    font-size: 0.8em;
    background-color: #FAC139;
    font-weight: 600;
    padding: 7px;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 18%;
}
.textOnly08{
    width: 85%;
    height: 27.7%;
    margin: auto;
    padding-top: 2%;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
    font-weight: 500;
}
.textOnly08 h5{
    font-size: 1.4em;
    font-weight: 900;
    line-height: 1;
}
.textOnly08 p{
    line-height: 1.1;
    font-size: 0.9em;
}

@media (max-width: 992px) {
    .textImagesDiv08{ width: 75%; }
    
    .textOnly08{ height: 27%; }
}

@media (max-width: 770px) {
    .textImagesDiv08{ width: 70%; }
    
    .textOnly08{ height: 27.7%; }
}

@media (max-width: 550px) {
    .textImagesDiv08{ width: 80%; }
    
    .presidentialText08 h5{
        font-weight: 800;
        max-height: 53px;
    }
    .coverImgRectDiv08{ height: 75%; }
    
    div.yellowBoard08{ max-height: 16.5%; }
    
    .textOnly08{
        height: 25.7%;
        padding-top: 1%;
    }
}
@media (max-width: 450px) {
    .outSideDiv08{
        height: 450px;
    }
    .textImagesDiv08{
        width: 75%;
    }
    .presidentialText08 h5{
        font-size: 0.9em;
    }
    .coverImgRectDiv08{
        height: 65%;
        margin-top: 10%;
    }
    .imgFlexedTwo08{
        margin-left: 6%;
    }
    div.yellowBoard08{
        font-size: 0.7em;
        max-height: 21.5%;
    }
    .textOnly08{
        padding-top: 3%;
        width: 70%;
        height: 27%;
    }
    .textOnly08 h5{
        font-size: 0.9em;
    }
    .textOnly08 p{
        font-size: 0.7em;
    }
}

@media (max-width: 350px) {
    .outSideDiv08{
        height: 400px;
    }
    .circle08 { 
        width: 58px; 
        height: 58px;
    } 
    .yourLogo08{
        width: 40px;
        height: 35px;
    }
    .textImagesDiv08{
        width: 75%;
    }
    .coverImgRectDiv08{
        height: 70%;
        margin-top: 8%;
    }
    div.yellowBoard08{
        font-size: 0.45em;
        max-height: 20%;
    }
    .textOnly08{
        width: 70%;
        height: 28.5%;
    }
    .textOnly08 h5{
        font-size: 0.7em;
    }
    .textOnly08 p{
        font-size: 0.6em;
    }    
}
