.headerBg {
    background: #F0F1F3;
    border-radius: 18px;
}
.howItWorksHero {
	height: 300px;
}

.secondBg {
    /*background-image:  url("./assets/images/website/how-it-works/second-bg.png");*/
    background: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /*height: 100%;*/
}
@media (max-width: 576px) {
	.howItWorksHero {
		height: 100px;
	}
}