body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
.parentContain2 {
    height: 550px;
    width: 100%;
    margin: 5px;
    background: url(../assets/medil2.jpg); 
    background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
    color: #ffffff;
    font-size: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    justify-content: center;
}
.centerImageTextDiv2{
    width: 33%;
    height: calc(100% - 300px);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv2{
    width: 100%;
    height: 160px;
}
.middleImage2{
    width: 100%;
    height: 80%;
    object-fit: cover;
}
.otherTexts2{
    max-width: 100%;
    max-height: 25%;
    word-wrap: break-word;
    /* word-break: break-all; */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1em; 
    line-height: 1.1;
    margin-top: -13%;
}
.fromTexts2{
    max-width: 60%;
    /* height: 20%;
    max-height: 18%; */
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
    background: #C30317;
    margin-top: -5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 1200px) {
    .parentContain2{
        height: 500px;
    }
    .fromTexts2{
        max-width: 65%;
        font-size: 0.8em;
        margin-top: 5%;
    }
}
@media (max-width: 992px) {
    .parentContain2{
        height: 400px;
    }
    .centerImageTextDiv2{ margin-top: -12%; }
    
    .imageDiv2{ height: 150px; }
    .otherTexts2{
        font-size: 0.8em;
        max-height: 40%; 
    }
   
    .fromTexts2{ 
        font-size: 0.65em;
        max-height: 30%;
    }
}
@media (max-width: 769px) {
    .parentContain2{ height: 530px}
    .centerImageTextDiv2{ margin-top: -3%; }
}
@media (max-width: 550px) {
    .parentContain2{
        height: 500px;
    }
    .centerImageTextDiv2{
        margin-top: 2%;
    }
    .imageDiv2{ height: 150px; }
    
    .middleImage2{ height: 80%; }
    
    .otherTexts2{
        font-size: 0.7em; 
        margin-top: -17%;
    }
    .fromTexts2{ font-size: 0.6em; }
}
@media (max-width: 450px) {
    .parentContain2{
        height: 400px;
    }
    .centerImageTextDiv2{
        margin-top: -15%;
    }
    .imageDiv2{ height: 120px; }
    .otherTexts2{ max-height: 45%;}
    .fromTexts2{
        margin-top: 5%; 
        font-size: 0.55em;
    }
}
@media (max-width: 350px) {
    .parentContain2{
        height: 300px;
    }
    .centerImageTextDiv2{
        margin-top: -22%;
    }
    .imageDiv2{ height: 100px }
    
    .middleImage2{ height: 70%; }
    
    .otherTexts2{
        font-size: 0.45em; 
        margin-top: -25%;
        max-height: 30px;
    }
    .fromTexts2{ 
        max-width: 55%;
        max-height: 20px;
        padding: 1%;
        font-size: 0.35em; 
    }
}