@mixin border($color, $width) {
  border: $width solid $color;
}

.tabs-btn{
  border: 0px;
  outline: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; 
  width: 100%;
}
.active-tabs-btn{
  @extend .tabs-btn;
  border-bottom: 1px solid blue;
}
.w-full{
  width: 100% !important;
}
.border-l{
  border-left-width: 1px solid inherit; 
}
.border-r{
  border-right-width: 1px solid inherit;
}

@for $i from 1 through 20{
  .gap-#{$i}{
    gap: #{$i}px;
  }

  .gap-x-#{$i}{
    column-gap: #{$i}px; 
  }
}

.font-status-head{
  font-size: 20px;
}


