.outSideDiv3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 600px;
    margin: 5px;
    display: flex;
    flex-direction: column;
}
.halfDivs3{
    width: 100%;
    position: relative;
}
.firstHalf3{
    position: relative;
    height: 60%;
    background: #FFA800;
    display: flex;
}
.divHalf3{ width: 50%; }

.leftPart3{ padding: 4% 4% 0 4%; }

.titleCanvas3{
    background: #ffffff;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    box-shadow: 0px 0px 2px #ccc;
    /* padding-top: 10px; */
    /* padding-left: 4px; */
    font-weight: 800;
    font-size: 0.9em;
}
/* .titleCanvas3 p{
    max-width: 95%;
    max-height: 65%;
    line-height: 1.1;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
} */
.yourLogo3{
    width: 50px;
    height: 40px;
    position: absolute;
    margin-top: 17px;
    margin-left: 8px;
    z-index: 99;
}
.logoImage3{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
/* .redDots3{
    position: absolute;
    color: #DD110A;
    font-size: 0.8em;
}
.firstDot3{
    margin-top: -6px;
    margin-left: -27px; 
}
.secondDot3{
    margin-top: -4.8%;
    margin-left: 5.3px;
} */
.biggyText3{
    width: 98%;
    height: 98%;
    margin-top: 38px;
}
.biggyText3 h1{
    color: #000;
    font-size: 4.3em;
    font-weight: 700;
    line-height: 0.9;
    text-transform: uppercase;
}
.biggyText3 div{
    font-family: 'taken_by_vultures_demoregular';
    color: #F90201;
    font-size: 1.8em;
    letter-spacing: 3px;
    line-height: 1.2;
    text-align: center;
    text-transform: capitalize;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 2px 2px #000;
    -ms-transform: rotate(-15deg); /* IE 9 */
    -webkit-transform: rotate(-15deg); /* Safari */
    transform: rotate(-15deg); /* Standard syntax */
    padding: 12px 0 2px 18px;
    margin-top: -15%;
    max-height: 145px;
}

@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.whiteCanvas3{
    width: 90%;
    height: 88%;
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 38%;
    margin-left: 3%;
    padding: 3%;
    box-shadow:0px 0px 7px;
}
.templateImage3{
    width:95%;
    height:100%;
    margin-left: 2%;
    object-fit:contain;
}
.secondHalfDivs3{
    height: 40%;
    background: #000000;
    z-index: -1;
}
.secondFirstHalf3{
    height: 50%;
    padding-top: 1%;
    padding-left: 2%;
    display: flex;
}
.otherContainer3{
    width: 18%;
    margin: 1%;
}
.redCircles3{
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.textCircle3{
    position: absolute;
    background: #E10101;
    padding-top: 3%;
    clip: rect(0px,100px,85px,0px);
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);
}
.textCircle3 p{
    color: #ffffff;
    text-align: center;
    font-size: 2.8em;
    font-weight: 700;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari */
    transform: rotate(45deg);
}
.clipCircle3{
	position:absolute;
    background: #A30203;
    margin-top: 49px;
    margin-left: 51px;
    clip: rect(0px,100px,15px,0px);
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg); 
}
div.otherTexts3{
    margin-top: 10px;
    margin-left: 10px;
    width: 145px;
    max-height: 100px;
    color: #FFFFFF;
    text-align: justify;
}
.otherTexts3 p{
    max-width: 98%;
    font-size: 0.88em;
}
.otherTexts3 p.discount3{
    font-size: 1.5em;
    font-weight: 600;
    margin-top: -8%;
}
.otherTexts3 p.pSecond3{
    font-size: 1.15em;
    line-height: 0.1;
}
.otherTexts3 span{
    color: #C7931D;
}
div.secondSecondHalf3{
    height: 50%;
    color: #FFFFFF;
    padding-left: 4%;
    padding-top: 20px;
}
.secondSecondHalf3 p.calltitle3{ 
    font-weight: bold;
    font-size: 1em;
    line-height: 0.7;
 }
.secondSecondHalf3 p.mynumber3{
    color: #F4A40D;
    margin-top: -1%;
    font-size: 1.8em;
    font-weight: 600;
    line-height: 0.9;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
    max-height: 23%;
}
.secondSecondHalf3 p.placeOrder3{
    font-size: 1em;
    margin-top: -2%;
}
.footerLink3{
    float: right;
    margin-top: -20px;
    margin-right: 15px;
    font-size: 0.7em
}
@media (max-width: 992px){
    .outSideDiv3{
        height: 400px;
    }
    .titleCanvas3 p{
        font-size: 0.9em;
        max-height: 77%;
    }
    .biggyText3{ margin-top: 30px; }
    
    .biggyText3 h1{ font-size: 2.9em; }
    
    .biggyText3 div{ 
        font-size: 1.6em; 
        max-height: 90px;
    } 
    .whiteCanvas3{
        height: 80%;
        margin-top: 55%;
    }
    
    div.secondFirstHalf3{ padding: 1%; }
    
    .templateImage3{ max-width:100%; }
    
    .otherContainer3{
        width: 18%;
        margin: 2% 0 0 1%;
    }
    .redCircles3{
        width: 65px;
        height: 65px;
    }
    .textCircle3{
        padding-top: 1%;
        clip: rect(0px,100px,50px,0px);
    }
    .textCircle3 p{
        font-size: 1.95em;
        font-weight: 600;
        padding: 2px;
    }
    .clipCircle3{
        margin-top: 25px;
        margin-left: 25px;
    }
    div.otherTexts3{
        max-width: 115px;
        margin-top: 3%;
        margin-left: 2%;
    }
    .otherTexts3 p{ 
        font-size: 0.9em;
        margin-top: -2%;
    }
    .otherTexts3 p.discount3{ 
        font-size: 1.6em;
        margin-top: -2%;
        line-height: 0.1;
    }
    .otherTexts3 p.pSecond3{
        font-size: 1em;  
        margin-top: 2%;  
    }
    .otherTexts3 span{ font-size: 1em; }
    
    div.secondSecondHalf3{
        padding-left: 3%;
        padding-top: 3%;
    }
    .secondSecondHalf3 p{ line-height: 0.6; }
    
    .secondSecondHalf3 p.calltitle3{ 
        font-size: 0.7em;
        letter-spacing: 2px;
    }
    .secondSecondHalf3 p.mynumber3{
        letter-spacing: -2px;
        margin-top: -10px;
        max-width: 90%;
        max-height: 25px;
    }
    .secondSecondHalf3 p.placeOrder3{ font-size: 0.9em; }
    
    .footerLink3{ margin-top: -20px; }
}
@media (max-width: 770px){
    .outSideDiv3{
        height: 500px;
    }
    .titleCanvas3{
        width: 60px;
        height: 60px;
    }
    .yourLogo3{
        width: 46px;
        height: 37px;
        margin-top: 15px;
        margin-left: 7px;
    }
    .biggyText3{ margin-top: 25px; }
    
    .biggyText3 h1{ font-size: 3.8em; }
    
    .biggyText3 div{
        font-size: 1.6em;
        letter-spacing: 7px;
        padding: 12px 0 2px 18px;
        margin-top: -15%;
        max-height: 130px;
    } 
    .whiteCanvas3{
        height: 88%;
        margin-top: 38%;
    }
    .secondFirstHalf3{ padding-left: 1% }

    .otherContainer3{
        width: 15%;
        margin: 1% 1% 0 1%;
    }
    .redCircles3{
        width: 80px;
        height: 80px;
    }
    .textCircle3{
        padding-top: 2%;
        clip: rect(0px,100px,70px,0px);
    }
    .textCircle3 p{ font-size: 2.5em; }
    
    .clipCircle3{
        margin-top: 40px;
        margin-left: 40px;
        clip: rect(0px,100px,13px,0px); 
    }
    
    div.otherTexts3{
        max-width: 95%;
        font-size: 1em;
        max-height: 70px;
    }
    .otherTexts3 p.discount3{ font-size: 1.4em; }

    .otherTexts3 span{ font-size: 0.94em; }
}
@media (max-width: 550px){
    .outSideDiv3{
        height: 450px;
    }
    .titleCanvas3{
        width: 55px;
        height: 55px;
        /* padding-top: 18px; */
        font-size: 0.75em;
    }
    .yourLogo3{
        width: 42px;
        height: 36px;
        margin-top: 11px;
    }
    /* .titleCanvas3 p{ max-height: 65%; } */
    
    .biggyText3{ margin-top: 30px; }  
   
    .biggyText3 h1{ font-size: 3em; }
    
    .biggyText3 div{ max-height: 120px; }
    
    .whiteCanvas3{
        width: 90%;
        margin-left: 5%;
    }
    .otherContainer3{ width: 15%; }
    
    .redCircles3{
        width: 65px;
        height: 65px;
    }
    .textCircle3{
        padding-top: 2%;
        clip: rect(0px,100px,55px,0px);
    }
    .textCircle3 p{ font-size: 1.7em; }
    
    .clipCircle3{
        margin-top: 30px;
        margin-left: 33px;
        clip: rect(0px,100px,10px,0px);
    }
    
    div.otherTexts3{ font-size: 1em; }
   
    .otherTexts3 p.discount3{
        font-size: 1.35em;
        line-height: 0.2;
    }
    .otherTexts3 p.pSecond3{
        line-height: 0.2;
        font-size: 0.95em;    
    }
    .secondSecondHalf3 p.calltitle3{
        font-size: 0.7em;
        line-height: 0.5;
    }
    .secondSecondHalf3 p.mynumber3{
        line-height: 0.9;
        padding: 5px 0;
        max-height: 30px;
    } 
}
@media (max-width: 450px){
    .outSideDiv3{ height: 350px; }
    
    .titleCanvas3{
        width: 50px;
        height: 50px;
    }
    .yourLogo3{
        width: 40px;
        height: 35px;
        margin-top: 10px;
        margin-left: 5px;
    }
    /* .titleCanvas3 p{ max-height: 55%; } */
 
    .biggyText3{ margin-top: 25px; }  
   
    .biggyText3 h1{ font-size: 2.1em; }
   
    .biggyText3 div{ max-height: 85px; }
   
    .whiteCanvas3{
        width: 95%;
        margin-left: 1%;
    }
    .redCircles3{
        width: 48px;
        height: 48px;
    }
    .textCircle3{
        clip: rect(0px,100px,40px,0px); 
    }
    .textCircle3 p{ font-size: 1.3em; }
    
    .clipCircle3{
        margin-top: 23px;
        margin-left: 22px;
        clip: rect(0px,100px,8px,0px);
    }
    div.otherTexts3{
        margin-top: 1px;
        max-height: 55px;
    }
    div.otherTexts3 p{ 
        font-size: 0.65em;
        margin-top: -8%;
    }
    .otherTexts3 p:first-child{ margin-top: 1%; }
    
    .otherTexts3 p.discount3{
        font-size: 1.2em;
        line-height: 0.1;
    }
    .otherTexts3 p.pSecond3{
        line-height: 0.1;
        font-size: 0.8em;    
    }
    .secondSecondHalf3 p.calltitle3{
        font-size: 0.5em;
        line-height: 0.3;
    }
    .secondSecondHalf3 p.mynumber3{
        font-size: 1.5em;
        max-height: 25px;
    } 
    .secondSecondHalf3 p.placeOrder3{ font-size: 0.7em; }
    
    .footerLink3{
        font-size: 0.4em; 
        margin-top: -13px;
    }
}
@media (max-width: 350px) {
    .outSideDiv3{
        height: 300px;
    }
    .titleCanvas3{
        width: 40px;
        height: 40px;
        /* padding-top: 13px; */
        /* font-size: 0.74em; */
    }
    .yourLogo3{
        width: 35px;
        height: 30px;
        margin-top: 7px;
        margin-left: 2px;
    }
    .biggyText3{ margin-top: 40px; }  
   
    .biggyText3 h1{ font-size: 1.8em; }
    
    .biggyText3 div{
        letter-spacing: 4px;
        -ms-transform: rotate(-10deg); /* IE 9 */
        -webkit-transform: rotate(-10deg); /* Safari */
        transform: rotate(-10deg); /* Standard syntax */
        max-height: 70px;
    }
    .whiteCanvas3{ 
        margin-top: 80px;
        height: 75%;
    }
    
    .redCircles3{
        width: 35px;
        height: 35px;
    }
    .textCircle3{
        clip: rect(0px,100px,30px,0px); 
    }
    .textCircle3 p{ font-size: 1.07em; }
    
    .clipCircle3{
        margin-top: 17px;
        margin-left: 17px;
        clip: rect(0px,100px,6px,0px);
    }
    div.otherTexts3{ max-height: 48px; }
   
    div.otherTexts3 p{ font-size: 0.58em; }
   
    .otherTexts3 p.discount3{
        font-size: 1em;
        margin-top: -5%;
    }
    .otherTexts3 p.pSecond3{
        line-height: 0.1;
        font-size: 0.65em;    
    }
    .secondSecondHalf3 p.calltitle3{
        font-size: 0.35em;
        line-height: 0.2;
    }
    .secondSecondHalf3 p.mynumber3{
        font-size: 1.35em;
        margin-top: -15px;
        max-height: 23px;
    } 
    .secondSecondHalf3 p.placeOrder3{ 
        font-size: 0.55em;
        margin-top: -10px; 
    }  
    .footerLink3{
        font-size: 0.3em; 
        margin-top: -10px;
    }
}
