body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: 'Rage Italic';
    src: local('Rage Italic'), url('../../../assets/RAGE_1.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}
.parentContainer12 {
    height: 550px;
    margin: 5px;
    background: url(../assets/RE2.jpg); 
    background-position: center;
    background-size: 100%;
	background-repeat: no-repeat;
    color: #282F49;
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerImageTextDiv12{
    width: 80%;
    height: calc(100% - 390px);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv12{
    width: 200px;
    height: 200px;
}
.middleImage12{
    width: 80%;
    height: 80%;
    border-radius: 50%;
    object-fit: cover;
}
.underTextsDiv12{
    max-width: 100%;
    margin-top: -10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.otherTexts12{
    max-width: 100%;
    max-height: 40%;
    font-size: 1em;
    font-weight: 500;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    padding-bottom: 5px;
}
.fromTexts12{
    max-width: 100px;
    /* max-height: 33px; */
    margin-top: -3%;
    font-size: 0.75em;
    background: #282F49;
    color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 1200px) {
    .parentContainer12{
        height: 450px;
    }
    .centerImageTextDiv12{
        height: calc(100% - 300px);
    }
    .imageDiv12{
        width: 160px;
        height: 160px;
    }
    .fromTexts12{
        margin-top: -10%;
    }
}
@media (max-width: 992px)
{
    .parentContainer12{
        height: 400px;
    }
    .centerImageTextDiv12{
        height: calc(100% - 260px);
    }
    .imageDiv12{
        width: 130px;
        height: 130px;
    }
    .underTextsDiv12{
        margin-top: -8%;
    }
    .otherTexts12{
        max-height: 45%;
        font-size: 0.73em;
    }
    .fromTexts12{
        max-width: 90px;
        max-height: 30px;
        font-size: 0.6em;
        margin-top: -5.7%;
    }   
}
@media (max-width: 770px) {
    .parentContainer12{
        height: 530px;
    }
    .centerImageTextDiv12{
        height: calc(100% - 420px);
    }
    .imageDiv12{
        width: 160px;
        height: 160px;
    }
    .underTextsDiv12{
        margin-top: -2%;
    }
    .otherTexts12{
        max-height: 40%;
        font-size: 0.8em;
    }
    .fromTexts12{
        margin-top: 1%;
    } 
}
@media (max-width: 550px)
{
    .parentContainer12{
        height: 470px;
    }
    .centerImageTextDiv12{
        height: calc(100% - 380px);
    }
    .imageDiv12{
        width: 130px;
        height: 130px;
    }
    .newYearText12{ font-size: 2em; }
    
    .otherTexts12{ font-size: 0.75em; }
    
    .fromTexts12{
        max-width: 70px;
        font-size: 0.5em;
    }   
}
@media (max-width: 450px) {
    .parentContainer12{
        height: 400px;
    }
    .centerImageTextDiv12{
        height: calc(100% - 320px);
    }
    .imageDiv12{
        width: 100px;
        height: 100px;
    }
    
    .newYearText12{ font-size: 1.5em; }
    
    .otherTexts12{ font-size: 0.55em;  }
    
    .fromTexts12{
        margin-top: -3%;
        max-width: 55px;
        max-height: 20px;
        font-size: 0.4em;
    }    
}
@media (max-width: 350px) {
    .parentContainer12{
        height: 300px;
    }
    .centerImageTextDiv12{
        height: calc(100% - 230px);
    }
    .imageDiv12{
        width: 75px;
        height: 75px;
    }
    .newYearText12{ font-size: 1em; }
    
    .otherTexts12{ font-size: 0.4em;  }

    .fromTexts12{
        margin-top: -6%;
        max-width: 50px;
        font-size: 0.3em;
    }
}
