body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
.parentContain5 {
    height: 550px;
    margin: 5px;
	background: url(../assets/medil5.jpg); 
    background-position: center;
    background-size: 100%;
	background-repeat: no-repeat;
    color: #F3C3B6;
    font-size: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10%;
}
.centerImageTextDiv5{
    width: 35%;
    height: calc(100% - 300px);
    margin-top: 180px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv5{
    width: 190px;
    height: 190px;
}
.middleImage5{
    width: 100%;
    height: 170px;
    border-radius: 50%;
}
.middleImage5{
    width: 80%;
    height:75%;
    object-fit: cover;
}
.otherTexts5{
    max-width: 100%;
    max-height: 104px;
    margin-top: -17%;
    font-size: 1em;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis; 
    line-height: 1.1;
}
.fromTexts5{
    max-width: 65%;
    font-size: 0.8em;
    background: #8F0320;
    margin-top: -5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 1200px) {
    .parentContain5{
        height: 450px;
    }
    .centerImageTextDiv5{
        margin-top: 145px;
    }
    .imageDiv5{
        width: 170px;
        height: 150px;
    }
    .otherTexts5{
        max-height: 64%;
        font-size: 0.95em;
    }
    .fromTexts5{
        max-height: 30%;
        font-size: 0.75em;
    }

}
@media (max-width: 992px) {
    .parentContain5{
        height: 400px;
    }
    .centerImageTextDiv5{
        margin-top: 130px;
    }
    .imageDiv5{
        width: 160px;
        height: 130px;
    }
    .otherTexts5{ 
        font-size: 0.8em;  
        margin-top: -20%;
        max-height: 85%;
    }
    .fromTexts5{
        max-width: 60%;
        font-size: 0.6em;
    }
}
@media (max-width: 770px) {
    .parentContain5{
        height: 500px;
    }
    .centerImageTextDiv5{
        margin-top: 160px;
        height: calc(100% - 280px);
    }
    .imageDiv5{ 
        width: 150px;
        height: 150px;
    }
    .otherTexts5{ 
        max-height: 52%;
        margin-top: -15%; 
    }
    .fromTexts5{  font-size: 0.7em; }
}
@media (max-width: 550px) {
    .parentContain5{
        height: 500px;
    }
    .centerImageTextDiv5{ 
        height: calc(100% - 280px);
        margin-top: 165px; 
    }
    .imageDiv5{
        width: 145px;
        height: 145px;
    }
    .otherTexts5{ 
        max-height: 42%;
        font-size: 0.75em;
    }
    .fromTexts5{ font-size: 0.6em; }
}
@media (max-width: 450px) {
    .parentContain5{
        height: 400px;
    }
    .centerImageTextDiv5{ margin-top: 135px; }
   
    .imageDiv5{
        width: 110px;
        height: 110px;
    }
    .middleImage5{
        width: 90px;
        height: 90px;
    }
    .otherTexts5{ 
        margin-top: -10%;
        font-size: 0.6em;
        max-height: 59%;
    }
    .fromTexts5{
        font-size: 0.45em;
        margin-top: -12%;
    }
}
@media (max-width: 350px) {
    .parentContain5{
        height: 300px;
    }
    .centerImageTextDiv5{
        height: calc(100% - 150px);
        margin-top: 100px;
    }
    .imageDiv5{
        width:80px;
        height: 80px;
    }
    .middleImage5{
        width: 60px;
        height: 60px;
    }
    .otherTexts5{
        margin-top: -20%;
        max-height: 32%;
        font-size: 0.5em; 
    }
    .fromTexts5{
        max-width: 66%;
        font-size: 0.35em;
        margin-top: -12%;
    }
  
}
