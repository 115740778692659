.parentContainer2 {
	height: 550px;
	width: 100%;
	background:linear-gradient(0deg, rgba(0, 68, 100, 0.7), rgba(0, 68, 100, 0.7)), url('../../../../../assets/images/templates/heroes/1.jpeg');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	color: #ffffff;
	padding: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; 
}
.happyBirthday2 {
	font-size: 60px;
	font-weight: bold;
	word-break: break-word;
	text-transform: uppercase;
	text-align: center;
	max-width: 80%;
}
.celebrantName2 {
	font-family: 'taken_by_vultures_demoregular';
	font-size: 110px;
	max-width: 80%;
	margin-top: 10px;
}
.inAdvance2 {
	font-size: 25px;
	max-width: 80%;
}

@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@media (max-width: 768px) {
	.parentContainer2 {
		height: 400px;
		padding: 30px;
	}
	.happyBirthday2 {
		font-size: 35px;
		max-width: 80%;
	}
	.celebrantName2 {
		font-size: 80px;
	}
	.inAdvance2 {
		font-size: 15px;
	}
}

@media (max-width: 576px) {
	.parentContainer2 {
		height: 300px;
		padding: 20px;
	}
	.happyBirthday2 {
		font-size: 30px;
		width: 70%;
	}
	.celebrantName2 {
		font-size: 50px;
	}
	.inAdvance2 {
		font-size: 10px;
	}
	.fromName2 {
		font-size: 8px;
		letter-spacing: 5px;
	}
}