 body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.parentContainer02 {
	height: 500px;
	width: 100%;
	background-image: url('../assets/bg2-2.png'), url('../assets/bg2.jpg');
	background-color: #881A23;
	/*background:linear-gradient(0deg, rgba(43, 62, 139, 0.9), rgba(43, 62, 139, 0.9));*/
	background-size: 100%;
	background-repeat: no-repeat;
	/*background-attachment: fixed;*/
	background-position: 0% 120%, cover;
	color: #ffffff;
	padding: 10px;
}
.dateDiv02 {
	/*position: absolute;*/
	/*right: 0;*/
	/*top: 0;*/
	/*margin-right: 50px;*/
	/*margin-top: 30px;*/
	font-size: 20px;
	/*text-align: right;*/
	text-transform: uppercase;
}
.happyBirthday02 {
	/*font-family: 'taken_by_vultures_demoregular';*/
	/*position: absolute;*/
	font-size: 45px;
	text-align: center;
	/*word-break: break-word;*/
	text-transform: capitalize;
	/*margin-left: 70px;*/
}
.birthday02 {
	margin-top: -30px;
	/*margin-left: 20px;*/
}

.month02 {
	font-size: 9px;
	text-transform: lowercase;
}
.celebrantImageDiv02 {
	display: flex;
	justify-content: center;
	margin-top: 15%;
}
.celebrantImageWrapper02 {
	background-color: black;
	height: 140px;
	width: 140px;
	outline: none;
	border-radius: 50%;
	display: flex;
}
.celebrantImage02 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}
.celebrantName02 {
	text-align: center;
	font-size: 20px;
	margin-top: 10px;
	word-break: break-word;
}
.extraNoteDiv02 {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}
.extraNote02 {
	text-align: center;
	font-size: 10px;
	letter-spacing: 1.2px;
	word-spacing: 2.2px;
	max-width: 70%;
	/*color: rgba(255, 255, 255, 0.5);*/
}

.bottomPart02 {
	position: absolute;
	bottom: 0px;
	left: 0;
	margin-bottom: 10px;
	width: 100%;
}

@media (max-width: 768px) {
	.parentContainer02 {
		height: 450px;
		padding: 20px;
	}
	.happyBirthday02 {
		font-size: 40px;
	}
	.dateDiv02 {
		font-size: 13px;
	}
	.celebrantImageWrapper02 {
		height: 150px;
		width: 150px;
	}
	.birthday02 {
		margin-top: -20px;
		margin-left: 20px;
	}
	.celebrantName02 {
		font-size: 17px;
	}
	.extraNote02 {
		font-size: 7px;
	}
}

@media (max-width: 576px) {
	.parentContainer02 {
		height: 320px;
		padding: 10px;
	}
	.month02 {
		font-size: 5px;
	}
	.dateDiv02 {
		position: absolute;
		right: 0;
		top: 0;
		margin-right: 30px;
		margin-top: 20px;
		font-size: 10px;
		text-align: right;
	}
	.celebrantImageDiv02 {
		display: flex;
		justify-content: center;
		margin-top: 15%;
	}
	.celebrantImageWrapper02 {
		background-color: black;
		height: 80px;
		width: 80px;
		border-radius: 50%;
		display: flex;
	}
	.celebrantImage02 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
	}
	.happyBirthday02 {
		font-size: 20px;
		margin-top: 20px;
	}	
	.birthday02 {
		margin-top: -8px;
		margin-left: 10px;
	}
	.celebrantName02 {
		font-size: 10px;
	}
	.extraNoteDiv02 {
		margin-top: 7px;
	}
	.extraNote02 {
		font-size: 5.5px;
	}
} 