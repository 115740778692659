body {
	font-family: 'Arial';
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: 'Rage Italic';
    src: local('Rage Italic'), url('../../../assets/RAGE_1.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}

.parentContainer11 {
    height: 550px;
    margin: 5px;
    background: url(../assets/RE1.jpg); 
    background-position: center;
    background-size: 100%;
	background-repeat: no-repeat;
    color: #FBFEFF;
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerImageTextDiv11{
    width: 60%;
    height: calc(100% - 330px);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageDiv11{
    width: 200px;
    height: 200px;
}
.middleImage11{
    width: 80%;
    height: 80%;
    border-radius: 50%;
    object-fit: cover;
}
.underTextsDiv11{
    max-width: 100%;
    margin-top: -11.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.newYearText11{
    font-family: 'Rage Italic'; 
}
.otherTexts11{
    max-width: 100%;
    max-height: 27%;
    font-size: 0.95em;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
}
.fromTexts11{
    max-width: 100px;
    font-size: 0.8em;
    background: #ffffff;
    color: #282F49;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width:1200px) {
    .parentContainer11{
        height:450px;
    }
    .centerImageTextDiv11{
        height: calc(100% - 260px);
    }
    .imageDiv11{
        width: 140px;
        height: 140px;
    }
}
@media (max-width: 992px)
{
    .parentContainer11{
        height:400px;
    }
    .centerImageTextDiv11{
        height: calc(100% - 230px);
    }
    .imageDiv11{
        width: 130px;
        height: 130px;
    }
    .underTextsDiv11{
        margin-top: -10%;
    }
    .newYearText11{ font-size: 1.9em; }
    
    .otherTexts11{ font-size: 0.82em; }
    .fromTexts11{
        max-width: 80px;
        font-size: 0.6em;
    }   
}
@media (max-width: 770px) {
    .parentContainer11{
        height: 480px;
    }
    .centerImageTextDiv11{
        height: calc(100% - 270px);
    }
    .imageDiv11{
        width: 150px;
        height: 150px;
    }
    .underTextsDiv11{
        margin-top: -5%;
    } 
    .otherTexts11{ font-size: 0.9em; }
}
@media (max-width: 550px)
{
    .parentContainer11{
        height: 500px;
    }
    .centerImageTextDiv11{
        height: calc(100% - 350px);
    }
    .imageDiv11{
        width: 130px;
        height: 130px;
    }
    .otherTexts11{ 
        max-height: 30%;
        font-size: 0.7em; 
    }
    
    .fromTexts11{
        max-width: 70px;
        font-size: 0.5em;
    }   
}
@media (max-width: 450px) {
    .parentContainer11{
        height: 400px;
    }
    .centerImageTextDiv11{
        height: calc(100% - 280px);
    }
    .imageDiv11{
        width: 100px;
        height: 100px;
    }
    .underTextsDiv11{ margin-top: -10%; }
    
    .newYearText11{ font-size: 1.5em; }
    
    .otherTexts11{ 
        max-height: 30.5%;
        font-size: 0.6em;  
    }
    
    .fromTexts11{
        margin-top: -2%;
        max-width: 55px;
        max-height: 19px;
        font-size: 0.45em;
    }    
}
@media (max-width: 350px) {
    .parentContainer11{
        height: 300px;
    }
    .centerImageTextDiv11{
        height: calc(100% - 200px);
    }
    .imageDiv11{
        width: 75px;
        height: 75px;
    }
    .newYearText11{ font-size: 1.2em; }
    
    .otherTexts11{
        font-size: 0.4em; 
    }
    
    .fromTexts11{
        margin-top: -7%;
        max-width: 50px;
        font-size: 0.35em;
    }
}
