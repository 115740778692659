.parentContainer3 {
	height: 550px;
	width: 100%;
	background:linear-gradient(0deg, rgba(42, 46, 47, 0.7), rgba(42, 46, 47, 0.7)), url('../../../../../assets/images/templates/heroes/1.jpeg');
	background-size: 100% !important;
	background-repeat: no-repeat;
	background-position: center;
	color: #ffffff;
	padding: 40px;
}
.dateDiv3 {
	position: absolute;
	right: 0;
	top: 0;
	margin-right: 50px;
	margin-top: 30px;
	font-size: 20px;
	text-align: right;
	text-transform: uppercase;
}
.infoDiv3 {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 30%;
}
.happyText {
	text-transform: uppercase;
}
.happyBirthday3 {
	font-size: 70px;
	font-weight: bold;
	word-break: break-word;
	text-transform: uppercase;
	text-align: right;
	max-width: 100%;
}
.celebrantName3 {
	font-size: 30px;
}
.extraNote3 {
	font-size: 10px;
	position: absolute;
	bottom: 0;
	right: 0;
	margin-bottom: 20px;
	margin-right: 50px;
	letter-spacing: 1.4px;
	word-spacing: 2.2px;
	text-transform: uppercase;
}

@media (max-width: 768px) {
	.parentContainer3 {
		height: 400px;
		padding: 20px;
	}
	.dateDiv3 {
		font-size: 13px;
	}
	.infoDiv3 {
		margin-top: 50%;
	}
	.happyBirthday3 {
		text-align: right;
		font-size: 45px;
		max-width: 100%;
	}
	.celebrantName3 {
		font-size: 20px;
	}
	.extraNote3 {
		font-size: 10px;
	}
}

@media (max-width: 576px) {
	.parentContainer3 {
		height: 300px;
		padding: 15px;
		background-size: 125% 100%;
	}
	.dateDiv3 {
		position: absolute;
		right: 0;
		top: 0;
		margin-right: 30px;
		margin-top: 20px;
		font-size: 10px;
		text-align: right;
	}
	.infoDiv3 {
		margin-top: 40%;
	}
	.happyBirthday3 {
		font-size: 30px;
		max-width: 100%;
	}
	.celebrantName3 {
		font-size: 15px;
	}
	.extraNote3 {
		margin-right: 30px;
		font-size: 6px;
	}
}