.outSideDiv010{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    background:linear-gradient(0deg, rgba(35, 4, 80, 0.9), rgba(35, 4, 80, 0.9)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
    display: flex;
    box-shadow: 0px 0px 4px #000;
}
.leftSideDiv010{
    width: 55%;
}
.mainInsideDiv010{
    margin-top: 15%;
    padding-left: 30px;
}
.circle010 { 
    width: 66px; 
    height: 66px;
    /* background-color: #FBC335; */
    border-radius: 50%;
    padding-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; 
    position: relative; 
} 
.yourLogo010{
    width: 50px;
    height: 48px;
    position: absolute;
    z-index: 99;
}
.logoImage010{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.textDetailsDiv010{
    width: 100%;
    height: 218px;
    margin-top: 90px;
    
}
.textDetailsDiv010 h1{
    font-size: 1.8em;
    font-weight: 650;
    line-height: 1;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 68%;
    padding-top: 5px;
    padding-bottom: 5px; 
}
.textDetailsDiv010 .roundyellow010{
    background-color: #FBC335;
    color: #000;
    font-size: 0.9em;
    font-weight: 200;
    padding: 0 6px;
    border-radius:50%;
}
.textDetailsDiv010 .subordinateText010{
    font-size: 1.09em;
    font-weight: 800;
}
.textDetailsDiv010 span.electionYear010{ 
    color: #FAC139;
    font-size: 1.125em;
}
.roundWhiteCircle010{
    background: #ffffff;
    width: 355px;
    height: 58%;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    left: -82px;
    bottom: -28.3%;
    clip-path: inset(0px 0px 50% 100px);
}
.textOnly010{
    width: 50%;
    margin-top: 17%;
    margin-left: 125px;
    font-weight: 700;
}
.textOnly010 p{
    color: #000;
    font-size: 0.8em;
    line-height: 1.1;
    z-index: 3;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 70px;
}
.textOnly010 span.h6{
    font-weight: 800;
    text-transform: capitalize;
}
.rightSideDiv010{
    width: 45%;
    height: 100%;
    display: flex-column;
}
.coverImgRectDiv010{
    width: 70%;
    height: 50%;
    margin: 65% 0 20% 20%;
    display: flex-column;
}
.singleImage010{
    width: 100%;
    height: 65%;
    object-fit: cover;
    border: 3px solid #ffffff;
    border-bottom: 0px !important;
}
div.yellowBoard010{
    width: 100%;
    max-height: 10.3vh;
    background-color: #FAC139;
    color: #000;
    font-weight: 600;
    padding: 5px;
    text-transform: capitalize;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.voteNowDiv010{
    margin-left: -15px;
    text-align: center;
}

h1.voteNowClass010{
    font-size: 2.4em;
    font-weight: 700;
    line-height: 0.3;
    color: #29025E;
} 
h1.voteNowOne010{
    color: #ffffff !important;
    margin-top: -60px;
}
h1.voteNowTwo010{
    opacity: 0.8;
    text-shadow: -1px 2px #fff, 2px 1px #fff, 1px 2px #fff, 2px -1px #fff;
    margin-top: -5px;
}

@media (max-width: 992px) {
    .outSideDiv010{
        height: 550px;
    }
    .textDetailsDiv010 h1{
        font-size: 1.5em;
    }
    .coverImgRectDiv010{
        width: 80%;
        height: 50%;
        margin: 70% 0 15% 10%;
    }
    div.yellowBoard010{
        max-height: 9vh;
    }
    h1.voteNowClass010{
        font-size: 2.2em;
    }
}
@media (max-width: 770px) {
    .outSideDiv010{ height: 570px; }
    
}

@media (max-width: 550px) {
    .outSideDiv010{ height: 400px; }
    
    .textDetailsDiv010{
        height: 200px;
        margin-top: 50px;
    }
    .textDetailsDiv010 h1{
        font-size: 1em;
    }

    .roundWhiteCircle010{
        width: 300px;
    }
    .textOnly010 p{
        font-size: 0.7em;
        max-height: 60px;
    }
    .textOnly010 span.h6{ font-size: 1.2em; }
   
    .coverImgRectDiv010{
        height: 60%;
        margin-top: 45%;
    }
    h1.voteNowClass010{
        font-size: 2em;
    }
    h1.voteNowOne010{
        margin-top: -45px;
    }
    h1.voteNowTwo010{
        margin-top: 10px;
    }
}
@media (max-width: 450px) {
    .coverImgRectDiv010{
        width: 80%;
        margin-left: 10%;
    }
    .textDetailsDiv010 h1{
        font-size: 0.9em;
    }
    div.yellowBoard{
        font-size: 0.8em
    }
    h1.voteNowClass010{
        font-size: 1.6em;
    }
}
@media (max-width: 350px) {
    .outSideDiv010{
        height: 400px;
    }
    .mainInsideDiv010{
        padding-left: 10px;
    }
    .textDetailsDiv010 h1{
        font-size: 0.85em;
    }
    .textDetailsDiv010 .roundyellow010{
        margin-left: 30%;
    }
    .textDetailsDiv010 .subordinateText010{
        font-size: 1.1em;
    }
    .roundWhiteCircle010{
        width: 255px;
    }
    .textOnly010{
        width: 45%;
        margin-left: 115px;
    }
    .textOnly010 p{
        font-size: 0.6em;
        max-width: 90%;
        max-height: 64px;
    }
    .textOnly010 span.h6{
        font-size: 1em;
    }
    .coverImgRectDiv010{
        width: 80%;
        height: 50%;
        margin: 80% 0 40% 10%;
    }
    h1.voteNowClass010{
        font-size: 1.2em;
    }
}
