.parentContainer{
    width: 100%;
    position: relative;
    overflow: hidden;
}
.outSideDiv5{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 550px;
    margin: 5px;
    background: #ffffff;
    box-shadow: 0px 0px 4px #000;
}

.roundBlueCircle5{
    background: #3687D8;
    width: 66%;
    height: 70%;
    border-radius: 50%;
    z-index: 1;
}
.firstRound5{
    position: absolute;
    width: 65%;
    height: 80%;
    right: -98.5px;
    margin-top: -101px;
    clip-path: inset(100px 25% 0px 0px);
}
.secondRound5{
    position: absolute;
    left: -95px;
    bottom: -192px;
    clip-path: inset(0px 0px 50% 100px);
}

.innerDivCover5{
    width: 100%;
    height: 100%;
    padding-top: 4%;
}
.circle5 { 
    width: 65px; 
    height: 65px;
    text-align: center; 
    position: relative; 
} 
.yourLogo5{
    width: 50px;
    height: 38px;
    position: absolute;
    margin-top: 15px;
    margin-left: 24px;
    z-index: 99;
}
.logoImage5{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.triangle5 { 
    position: absolute; 
    left: 30%;
    top: 0; 
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8;
}
.triangle5:nth-child(2) { transform: rotate(10deg); } 
.triangle5:nth-child(3) { transform: rotate(20deg); } 
.triangle5:nth-child(4) { transform: rotate(30deg); } 
.triangle5:nth-child(5) { transform: rotate(40deg); } 
.triangle5:nth-child(6) { transform: rotate(50deg); } 
.triangle5:nth-child(7) { transform: rotate(60deg); } 
.triangle5:nth-child(8) { transform: rotate(70deg); } 
.triangle5:nth-child(9) { transform: rotate(80deg); } 
.triangle5:nth-child(10) { transform: rotate(90deg); } 
.triangle5:nth-child(11) { transform: rotate(100deg); } 
.triangle5:nth-child(12) { transform: rotate(110deg); }

.circleTwo5 { 
    width: 120px; 
    height: 120px;
    text-align: center; 
    position: relative; 
}

.getUpTo5{
    width: 80px;
    height: 64px;
    position: absolute;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 90%;
    top: 87%;
    padding-top: 1px;
    margin-top: 165px;
    margin-left: 85%;
    line-height: 0.7;
    font-size: 0.9em;
    font-weight: 700;
    color: #000;
    z-index: 99;
}
.getUpTo5 h4{ font-weight: 700; }

.secondTriangle5 { 
    position: absolute;
    left: -10%;
    top: 98%;
    margin-top: 122px;
    margin-left: 210px;
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8; 
}

.secondTriangle5:nth-child(2) { transform: rotate(10deg); } 
.secondTriangle5:nth-child(3) { transform: rotate(20deg); } 
.secondTriangle5:nth-child(4) { transform: rotate(30deg); } 
.secondTriangle5:nth-child(5) { transform: rotate(40deg); } 
.secondTriangle5:nth-child(6) { transform: rotate(50deg); } 
.secondTriangle5:nth-child(7) { transform: rotate(60deg); } 
.secondTriangle5:nth-child(8) { transform: rotate(70deg); } 
.secondTriangle5:nth-child(9) { transform: rotate(80deg); } 
.secondTriangle5:nth-child(10) { transform: rotate(90deg); } 
.secondTriangle5:nth-child(11) { transform: rotate(100deg); } 
.secondTriangle5:nth-child(12) { transform: rotate(110deg); }

.flexTextImg5{
    height: 65%;
    display: flex;
    margin-top: -13%;
    padding: 0 10% 0 4%;
}
.flexTextImg5 .textDiv5{ width: 45%; }

.coverLineDiv5{ position: relative; }

.lineDiv5{
    position: absolute;
    height: 4px;
    width: 100%;
    background: #093950;
}
div.firstlineDiv5{ margin-top: 60px; }

div.secondlineDiv5{ margin-top: 120px; }

div.thirdlineDiv5{ margin-top: 178px; }

.textDiv5 h1{
    color: #000;
    font-size: 3.4em;
    text-transform: uppercase;
    word-break: keep-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;   
    height: 55%;
}
.textDiv5 p{
    max-width: 95%;
    white-space: nowrap;
    font-size: 1em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -7px;
}
.flexTextImg5 .imgFrame5{
    width: 60%;
    height: 80%;
    border: 4px solid #FFFFFF;
    z-index: 2;
}
.mainImg5{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.offerRuns5{
    font-size: 1.2em;
    font-weight: 700;
    text-align: right;
    float: right;
    margin: 0 8% 0 0;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 43%;
    max-height: 53px;
}
@media (max-width: 1200px) and (min-width: 990px){
    .firstRound5{ right: -82px; }

    .textDiv5 p{ font-size: 0.85em; }
}
@media (max-width: 992px) {
    .outSideDiv5{ height: 450px; } 
    
    .roundBlueCircle5{ height: 60%; }

    .firstRound5{ 
        right: -15%;
        height: 70%;
    }
    .secondRound5{ bottom: -135px; }
    
    .secondTriangle5 {
        margin-top: 72px;
        margin-left: 145px; 
    }
    .circleTwo5 { 
        width: 95px; 
        height: 95px;
    }
    .getUpTo5{
        width: 70px;
        height: 50px;
        left: 65%;
        top: 20%;
        padding-top: 2px;
        font-size: 0.75em;
    }
    .getUpTo5 h4 { 
        font-size: 1.6em; 
    }
    .flexTextImg5{
        height: 60%;
        margin-top: -23%;
    }  

    div.firstlineDiv5{ margin-top: 53px; }
    
    div.secondlineDiv5{ margin-top: 98px; }
    
    div.thirdlineDiv5{ margin-top: 145px; }
    
    .textDiv5 h1{ 
        font-size: 2.5em; 
        margin-top: 10px;
    }
    
    .textDiv5 p{
        max-width: 100%;
        font-size: 0.65em;
    }
    .offerRuns5{
        font-size: 0.8em;
        margin: 7% 10% 0 0;
    }
}
@media (max-width: 750px){
    .firstRound5{ right: -15.5%; }
    
    .flexTextImg5{ margin-top: -15%; }
    
    .secondTriangle5 {
        margin-left: 165px; 
    }
    .getUpTo5{
        left: 85%;
        top: 25%;
    }
    .offerRuns5{
        margin: 2% 10% 0 0;
    }
}
@media (max-width: 440px) {
    .parentContainer{
        overflow-x: scroll;
    }
    .outSideDiv5{ height: 400px; } 
    
    .roundBlueCircle5{ height: 55%; }
    
    .firstRound5{
        height: 75%;
    }
    .secondRound5{ bottom: -110px; }
    .yourLogo5{
        margin-top: 15px;
        height: 37px;
    }
    .circleTwo5 { 
        width: 80px; 
        height: 80px;
    }
    .getUpTo5{
        max-width: 90%;
        max-height: 58%;
        font-size: 0.7em;
        margin-top: 150px;
        margin-left: 30px;
    }
    .getUpTo5 h4{
        font-size: 2em;
        line-height: 0.9;
        padding-bottom: 2px;
    }
    .secondTriangle5 {
        margin-top: 78px;
        margin-left: 106px; 
    }
    .flexTextImg5{ margin-top: -16%; }  

    div.firstlineDiv5{ margin-top: 42px; }
    
    div.secondlineDiv5{ margin-top: 80px; }
    
    div.thirdlineDiv5{ margin-top: 115px; }
    
    .textDiv5 h1{ 
        font-size: 2em;
        max-height: 48%; 
    }
    
    .textDiv5 p{ font-size: 0.6em; }
    
    .offerRuns5{ margin: 0 8% 0 0; }
}
@media (max-width: 350px) {
    .outSideDiv5{ height: 330px; } 
    
    .roundBlueCircle5{ height: 55%; }
    
    .firstRound5{ 
        right: -15%;
        height: 75%; 
    }
    .secondRound5{ bottom: -90px; }
    .circleTwo5 {
        width: 85px; 
        height: 85px;
    }
    .getUpTo5{
        max-width: 85%;
        max-height: 48px;
        font-size: 0.7em;
        top: -7px;
        margin-left: -5px;
    }
    .getUpTo5 h4{
        line-height: 1;
        font-size: 1.8em !important;
    }
    .secondTriangle5 {
        margin-top: 40px;
        margin-left: 75px; 
    }
    .flexTextImg5{
        height: 55%;
        margin-top: -25%;
    }

    .lineDiv5{ 
        height: 3px; 
        top: 7px;
    }

    div.firstlineDiv5{ margin-top: 28px; }
    
    div.secondlineDiv5{ margin-top: 55px; }
    
    div.thirdlineDiv5{ margin-top: 82px; }
    
    .textDiv5 h1{ font-size: 1.5em; }
    
    .textDiv5 p{ font-size: 0.45em; }
    
    .offerRuns5{ 
        margin: 1% 8% 0 0;
        font-size: 0.6em;
        max-height: 40px;
    }

}
