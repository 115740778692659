.parentContainer1 {
	height: 500px;
	width: 100%;
	background:linear-gradient(0deg, rgba(227, 151, 5, 0.5), rgba(227, 151, 5, 0.5)),url('../../../../../assets/images/templates/heroes/1.jpeg');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	color: #ffffff;
	padding: 30px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.happyBirthday1 {
	font-size: 48px;
	font-weight: bold;
	word-break: break-word;
	text-transform: uppercase;
	max-width: 70%;
}
.celebrantName1 {
	font-family: 'taken_by_vultures_demoregular';
	font-size: 100px;
	position: absolute;
	bottom: 0;
	right: 0;
	margin-right: 50px;
	margin-bottom: 70px;
}
.bottomContent1 {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}
.inAdvance1 {
	font-size: 20px;
}
.fromName1 {
	letter-spacing: 7px;
}

@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@media (max-width: 768px) {
	.parentContainer1 {
		height: 400px;
		padding: 30px;
	}
	.happyBirthday1 {
		font-size: 35px;
		width: 70%;
	}
	.celebrantName1 {
		font-size: 50px;
		margin-right: 30px;
		margin-bottom: 80px;
	}
	.inAdvance1 {
		font-size: 15px;
	}
	.fromName1 {
		font-size: 12px;
		letter-spacing: 5px;
	}
}

@media (max-width: 576px) {
	.parentContainer1 {
		height: 300px;
		padding: 20px;
	}
	.happyBirthday1 {
		font-size: 30px;
		width: 70%;
	}
	.celebrantName1 {
		font-size: 40px;
		margin-right: 25px;
		margin-bottom: 55px;
	}
	.inAdvance1 {
		font-size: 10px;
	}
	.fromName1 {
		font-size: 8px;
		letter-spacing: 5px;
	}
}