.parentContainer{
    width: 100%;
    position: relative;
    overflow: hidden;
}
.outSideDiv10{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 550px;
    margin: 5px;
    background:linear-gradient(0deg, rgba(45, 22, 136, 0.8), rgba(45, 22, 136, 0.8)), url('../../../../../assets/images/templates/general/skyscrapperOne.jpg');
    background-size: cover;
    background-position: center;
	background-repeat: no-repeat;
	/* background-attachment: fixed; */
    color: #ffffff;
    display: flex;
    box-shadow: 0px 0px 4px #000;
}
.leftSideDiv10{
    width: 60%
}

.mainInsideDiv10{
    margin-top: 30%;
    margin-left: 15px;
}

.circle10 { 
    width: 66px; 
    height: 66px;
    position: relative; 
} 
.yourLogo10{
    width: 45px;
    max-width: 45px;
    height: 46px;
    max-height: 46px;
    position: absolute;
    margin-top: -39px;
    margin-left: 42px;
    z-index: 99;
}
.logoImage10{
    width: 80%;
    height: 80%;
    object-fit: cover;
}
    
.triangle10 { 
    position: absolute; 
    left: 50%;
    top: -85%; 
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8; 
} 
  .triangle10:nth-child(2) { transform: rotate(10deg); } 
  .triangle10:nth-child(3) { transform: rotate(20deg); } 
  .triangle10:nth-child(4) { transform: rotate(30deg); } 
  .triangle10:nth-child(5) { transform: rotate(40deg); } 
  .triangle10:nth-child(6) { transform: rotate(50deg); } 
  .triangle10:nth-child(7) { transform: rotate(60deg); } 
  .triangle10:nth-child(8) { transform: rotate(70deg); } 
  .triangle10:nth-child(9) { transform: rotate(80deg); } 
  .triangle10:nth-child(10) { transform: rotate(90deg); } 
  .triangle10:nth-child(11) { transform: rotate(100deg); } 
  .triangle10:nth-child(12) { transform: rotate(110deg); }

.textDetailsDiv10{
    max-width: 95%;
    height: 230px;
    margin-top: 1%;
    margin-left: 5%;
    text-align: center;
    margin-bottom: 8%;
}
.textDetailsDiv10 h1{
    font-size: 3.2em;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
}
.textDetailsDiv10 h1.styledWord10{
    font-family: 'Script MT Bold','Allegratta Personal Use','Blackadder ITC', Rage,'Brush Script MT';
    font-size: 5.3em;
    font-weight: 900;
}
.textDetailsDiv10 span{
    color: #FAC139;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.firstp10{
    max-width: 80%;
    max-height: 21%;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10%;
}
.textDetailsDiv10 p{
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.2;
}

.roundWhiteCircle10{
    background: #ffffff;
    width: 340px;
    height: 60%;
    border-radius: 50%;
    z-index: 1;
}

.whiteCircleOne10{
    position: absolute;
    margin-top: -10px;
    margin-left: -71px;
    clip-path: inset(0px 0px 50% 55px);
}
.textOnly10{
    max-width: 53%;
    margin-top: 15%;
    margin-left: 32%;
}
.textOnly10 p{
    max-height: 77px;
    color: #41677A;
    font-size: 0.95em;
    font-weight: 600;
    line-height: 1.1;
    z-index: 3;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rightSideDiv10{
    width: 40%;
    height: 100%;
}
.coverImgCircleDiv10{
    width: 90%;
    height: 90%;
    display: flex;
}
.roundImageCircle10{
    object-fit: cover;
    width: 79%;
    height: 100%;
    border: 15px solid #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.whiteCircleTwo10{
    position: absolute;
    margin-top: 136px;
    margin-left: -33%;
    clip-path: inset(0px 53% 65px 0px); 
}

.imagetextDiv10{
    width: 95%;
    height: 10%;
    margin-top: 30%;
    margin-left: 20%;
}

.imagetextDiv10 p{
    font-size: 1em;
    font-weight: 700;
    line-height: 1.3;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 60px;
}
@media (max-width: 992px) {
    .outSideDiv10{
        height: 450px;
    }
    .textDetailsDiv10{
        margin-top: 10px;
    }
    .textDetailsDiv10 h1{
        font-size: 2.2em;
        line-height: 1;
    }
    .textDetailsDiv10 h1.styledWord10{
        font-size: 4.3em;
    }
    .firstp10{
        max-width: 90%;
        margin-left: 6%;
    }
    .textDetailsDiv10 p{
        font-size: 0.7em;
    }
    .roundWhiteCircle10{
        width: 280px;
        height: 47%;
    }
    .whiteCircleOne10{
        margin-top: -70px;
        margin-left: -71px;
        clip-path: inset(0px 0px 40% 55px);
    }
    .textOnly10{
        max-width: 60%;
        margin-top: 19%;
        margin-left: 30%;
    }
    .textOnly10 p{ 
        font-size: 0.8em; 
        max-height: 65px;
    }

    .rightSideDiv10{
        width: 40%;
        height: 100%;
    }
    .coverImgCircleDiv10{
        width: 80%;
        height: 80%;
    }
    .roundImageCircle10{
        width: 80%;
        height: 82%;
    }
    .whiteCircleTwo10{
        margin-top: 138px;
        margin-left: -29%;
    }
    
    .imagetextDiv10{ margin-left: 10%; }
    
    .imagetextDiv10 p{
        max-width: 100%;
        font-size: 0.65em;
    }
}
@media (max-width: 770px) {
    .whiteCircleOne10{
        margin-top: -17%;
    }
}
@media (max-width: 430px) {
    .parentContainer{
        overflow-x: scroll;
    }
    .outSideDiv10{ 
        height: 300px;
    }
    
    .circle10 { 
        width: 55px; 
        height: 55px; 
    } 
    .yourLogo10{
        margin-top: -10px;
        margin-left: 28px;
        max-width: 36px;
        max-height: 32px;
        line-height: 1;
        font-size: 0.75em;
    }    
    .triangle10 {  
        top: -45%; 
        left: 30%; 
    }
    .textDetailsDiv10{ margin-top: 5% ;}
    
    .textDetailsDiv10 h1{ font-size: 1.2em; }
   
    .textDetailsDiv10 h1.styledWord10{ font-size: 2.2em; }

    .firstp10{
        max-width: 75%;
        margin-left: 10%;
    }
    .textDetailsDiv10 p{ 
        font-size: 0.55em;
        max-height: 23px; 
    }
    
    .roundWhiteCircle10{
        width: 180px;
        height: 40%;
    }
    .whiteCircleOne10{ margin-top: -134px; }
    
    .textOnly10{
        max-width: 45%;
        margin-top: 15%;
        margin-left: 40%;
    }
    .textOnly10 p{
        max-height: 30px; 
        font-size: 0.5em; 
    }
    .coverImgCircleDiv10{
        width: 90%;
        height: 90%;
    }
    .imagetextDiv10 p{
        max-height: 35px;
    }
    .roundImageCircle10{
        height: 94%;
        border: 10px solid #ffffff;
    }
    
    .whiteCircleTwo10{
        margin-top: 75px;
        margin-left: -33%;
    }

}
@media (max-width: 350px) {
    .outSideDiv10{ height: 280px; }
   
    .textDetailsDiv10{ margin-top: 5% ;}
    
    .textDetailsDiv10 h1{ font-size: 1.2em; }
   
    .textDetailsDiv10 h1.styledWord10{ font-size: 2.2em; }

    .firstp10{
        max-width: 75%;
        margin-left: 10%;
    }
    .textDetailsDiv10 p{
        max-height: 17px; 
        font-size: 0.45em; 
    }
    
    .roundWhiteCircle10{ width: 160px; }
    
    .whiteCircleOne10{ margin-top: -125px; }
    
    .textOnly10{
        margin-top: 14%;
        margin-left: 40%;
    }
    .textOnly10 p{ 
        font-size: 0.4em; 
        max-height: 23px;
    }

    .imagetextDiv10 p{ 
        max-height: 20px;
        font-size: 0.4em; 
    }

}
  
