.outSideDiv11{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    height: 500px;
    background:linear-gradient(0deg, rgba(112, 11, 99, 0.8), rgba(112, 11, 99, 0.8)), url('../../../../../assets/images/templates/general/skyscrapperOne.jpg');
    background-size: cover;
    background-position: center;
	background-repeat: no-repeat;
    color: #ffffff;
    display: flex;
    box-shadow: 0px 0px 4px #000;
}

.leftSideDiv11{ width: 60% }

.mainInsideDiv11{
    margin-top: 27%;
    margin-left: 15px;
}
.circle11 { 
    width: 66px; 
    height: 66px;
    text-align: center; 
    position: relative; 
} 
.yourLogo11{
    width: 50px;
    max-width: 50px;
    height: 48px;
    max-height: 48px;
    position: absolute;
    margin-top: -36px;
    margin-left: 35px;
    z-index: 99;
}
.logoImage11{
    width: 80%;
    height: 80%;
    object-fit: cover;
}
    
.triangle11 { 
    position: absolute; 
    left: 47%;
    top: -80%; 
    width: 86.6%; 
    height: 75%; 
    background-color: #FBC335; 
    clip-path: polygon(50% 0, 0 100%, 100% 100%); 
    transform-origin: center 66.6%;
    color: #000;
    text-align:center;
    font-size: 2em;
    z-index: 8; 
} 
  .triangle11:nth-child(2) { transform: rotate(10deg); } 
  .triangle11:nth-child(3) { transform: rotate(20deg); } 
  .triangle11:nth-child(4) { transform: rotate(30deg); } 
  .triangle11:nth-child(5) { transform: rotate(40deg); } 
  .triangle11:nth-child(6) { transform: rotate(50deg); } 
  .triangle11:nth-child(7) { transform: rotate(60deg); } 
  .triangle11:nth-child(8) { transform: rotate(70deg); } 
  .triangle11:nth-child(9) { transform: rotate(80deg); } 
  .triangle11:nth-child(10) { transform: rotate(90deg); } 
  .triangle11:nth-child(11) { transform: rotate(100deg); } 
  .triangle11:nth-child(12) { transform: rotate(110deg); }

.textDetailsDiv11{
    max-width: 90%;
    height: 300px;
    margin-top: 15px;
    margin-left: 5%;
    text-align: center;
}
.textDetailsDiv11 h1{
    font-size: 3.3em;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
}
.textDetailsDiv11 h1.styledWord11{
    font-family: 'taken_by_vultures_demoregular';
    font-size: 10em;
    line-height: 0.55;
    font-weight: 100;
    text-transform: capitalize;
}
@font-face {
    font-family: 'taken_by_vultures_demoregular';
    src: url('../../../assets/taken_by_vultures_demo-webfont.woff2') format('woff2'),
         url('../../../assets/taken_by_vultures_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.textDetailsDiv11 span{ color: #F8C435; }

.firstp11{ width: 90%; }

.textDetailsDiv11 p{
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.3;
    max-height: 49px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buyNowDiv11{ margin-top: 70px; }

h1.buyNowClass11{
    font-size: 3em;
    line-height: 0.3;
    color: rgba(141, 35, 127, 0.9);
    text-shadow: -1px 0 #ccc, 0 1px #ccc, 1px 0 #ccc, 0 -1px #ccc;
} 
h1.buyNowOne11{
    color: #ffffff !important;
    margin-top: -44px;

}
h1.buyNowTwo11{
    opacity: 0.3;
    margin-top: -38px;
}
h1.buyNowThree11{
    opacity: 0.4;
    margin-top: 0px;
}

.rightSideDiv11{
    width: 40%;
    height:100%;
}
.coverImgRectDiv11{
    max-width: 90%;
    height: 80%; 
}
.imagetextDiv11{
    width: 90%;
    height: 15%;
    margin-top: 30%;
    margin-left: 20%;
}
.imagetextDivOne11{
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.imagetextDiv11 p{
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1.3;
}
.rectImage11{
    width: 100%;
    height: 60%;
    margin-top: 40px;
    object-fit: cover;
}
.imagetextDivTwo11{
    margin-top: 25px;
}
.imagetextDivTwo11 p{
    line-height: 0.8;
}
@media (max-width: 992px) {
    .underTextDiv11{
        margin-top: 60px;
    }
    .buyNowDiv11{ margin-top: 85px; }

	h1.buyNowClass11{
        font-size: 2.3em;
        line-height: 0.5;
    }
    p, .imagetextDiv11 p{
        font-size: 0.75em;
    }
    .imagetextDiv11{
        height: 14%;
    }
}
@media (max-width: 768px) {
    .textDetailsDiv11 h1{
        font-size: 2.5em;
    }
    h1.styledWord11{
        font-size: 4.5em;
        line-height: 0.2;
    }
    .firstp11{ max-width: 80%; }

    .textDetailsDiv11 p{
        font-size: 0.7em;
        line-height: 1.2;
    }
    .imagetextDiv11{ height: 13%; }
}

@media (max-width: 576px) {
    .outSideDiv11{
        height: 500px;
    }

    .imagetextDiv11{
        margin-top: 25%;
    }
    .rectImage11{
        height: 47%;
        margin-top: 50px;
    }
    .imagetextDivTwo11{
        margin-top: 40px;
    }
    p, .imagetextDiv11 p{
        font-size: 0.7em;
    }
	h1.buyNowClass11{
        font-size: 2.5em;
        line-height: 0.5;
    } 
}
@media (max-width: 430px) {
    .outSideDiv11{
        height: 460px;
    }

    h1.buyNowClass11{
        font-size: 2em;
        line-height: 0.6;
    } 
    .imagetextDiv11{
        height: 14%;
    }

}
@media (max-width: 350px) {
    .outSideDiv11{
        height: 400px;
    }
    .triangle11 { 
        margin-top: 35px;
        margin-left: -20px;
    }
    .yourLogo11{ 
        margin-top: -5px;
        margin-left: 15px;
    }
    .textDetailsDiv11 h1{
        font-size: 1.9em;
    }
    .textDetailsDiv11 h1.styledWord11{
        font-size: 4em;
        line-height: 0.2;
    }
    p, .imagetextDiv11 p,
    .textDetailsDiv11 p {
        font-size: 0.6em;
    }
    
    h1.buyNowClass11{
        font-size: 1.5em;
        line-height: 0.9;
    } 
}
