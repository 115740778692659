.outSideDiv04{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    background-color: #DCDBD6;
    box-shadow: 0px 0px 4px #000;
}
.mainInsideDiv04{
    width: 100%;
    height: 65%;
    color: #fff !important;
    background:linear-gradient(0deg, rgba(97,99,2, 0.9), rgba(97,99,2, 0.9)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5% 5% 0 5%;
    display: flex;
    flex-direction: column;
}
.logoHeaderText04{
    width: 100%;
    height: 20%;
    clear: both;
}
.circleDiv04{ float: left; }

.circle04 { 
    width: 60px; 
    height: 60px;
    /* background-color: #FBC335; */
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; 
} 
.yourLogo04{
    width: 50px;
    height: 45px;
    z-index: 99;
}
.logoImage04{
    width: 85%;
    height: 85%;
    object-fit: cover;
}
.dateTextDiv04{
    width: 35%;
    float: right;
}
.dateTextDiv04 h5{
    text-align: right;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
}
.mainDetailsText04{
    width: 100%;
    height: 80%;
    display: flex;
    padding-top: 87px;
}
.byElectionDiv04{
    width: 55%;
    margin-bottom: -7px;
    display: flex;
    align-self: flex-end;
}
.byElectionDivTwo04{
    width: 55%;
    padding: 0 0 0 5%;
    display: flex;
}
h4.byElectionClass04{
    text-transform: uppercase;
    font-size: 1.8em;
    font-weight: 700;
    color: #DCDBD6;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 63px;
} 
h4.byElectionOne04{ color: #E8E7DF !important; }

h4.byElectionTwo04{
    height: 63px;
    opacity: 0.8;
    text-shadow: -2px 2px #646605, 2px 1px #646605, 1px 2px #646605, 2px -2px #646605;
    margin-top: -2px;
}
.imagesDivFlex04{
    width: 45%;
    /* width: 225px; */
    height: 270px;
}
.imgFlexed04{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.voterDetailsText04{
    color: #000;
    height: 25%;
    width: 50%;
    float: right;
    margin: 3% 5% 0 0;
    text-align: right;
}
.voterDetailsText04 h5{
    font-weight: 600;
    line-height: 0.2;
}
.voterDetailsText04 h1{
    line-height: 0.9;
    font-size: 2.2em;
    font-weight: 800;
    text-shadow: 1px 1px #fff;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 85px;
}
@media (max-width: 1200px) {
    h4.byElectionClass04{
        font-size: 1.5em;
        max-height: 51px;
    } 
    .imagesDivFlex04{
        margin-top: 10%;
        height: 240px;
    }
    .voterDetailsText04{
        width: 60%;
        margin: 7% 5% 0 0;
    }
    .voterDetailsText04 h1{ font-size: 2.1em; }
}
@media (max-width: 992px) {
    .outSideDiv04{ height: 500px; }
    
    .dateTextDiv04 h5{ font-size: 1em; }
    
    .mainDetailsText04{ padding-top: 60px; }
    
    h4.byElectionClass04{ font-size: 1.35em; }
    
    .imagesDivFlex04{ height: 210px; }
    
    .voterDetailsText04 h1{ font-size: 2em; }
}

@media (max-width: 770px) {
    .dateTextDiv04 h5{ font-size: 1.1em; }
    
    .mainDetailsText04{ padding-top: 40px; }
    
    h4.byElectionClass04{
        font-size: 1.5em;
        max-height: 51px;
    } 
    .imagesDivFlex04{
        margin-top: 1%;
        height: 240px;
    }
    .voterDetailsText04{
        width: 55%;
        margin: 1% 5% 0 0;
    }
    .voterDetailsText04 h1{ font-size: 2.1em; }
}

@media (max-width: 550px) {
    .dateTextDiv04 h5{ font-size: 1em; }

    .mainDetailsText04{ padding-top: 60px; }

    h4.byElectionClass04{
        font-size: 1.3em;
        max-height: 46px;
    }
    .voterDetailsText04{
        height: 20%;
        margin-top: 5%;
    }
    .voterDetailsText04 h5{ line-height: 0.3; }
    .voterDetailsText04 h1{
        font-size: 1.7em;
        max-height: 70px;
    }
}
@media (max-width: 450px) {
    .outSideDiv04{ height: 400px; }
    
    .circle04 { 
        width: 55px; 
        height: 55px;
    } 
    .yourLogo04{ font-size: 0.85em; }

    .dateTextDiv04 h5{ font-size: 0.9em; }

    .byElectionDiv04{ padding-top: 100px; }

    h4.byElectionClass04{
        font-size: 1em;
        max-height: 35px;
    }
    .imagesDivFlex04{
        height: 180px;
    }
    .voterDetailsText04{
        margin-top: 5%;
    }
    .voterDetailsText04 h5{
        font-size: 0.9em;
        line-height: 0.1;
    }
    .voterDetailsText04 h1{
        font-size: 1.2em;
        max-height: 49px;
    }
}

@media (max-width: 350px) {
    .outSideDiv04{
        height: 350px;
    }
    .circle04 { 
        width: 48px; 
        height: 48px;
        margin-left: 5px;
    } 
    .yourLogo04{
        width: 40px;
        height: 40px;
    }
    .dateTextDiv04 h5{
        font-size: 0.65em;
    }
    h4.byElectionClass04{
        font-size: 0.75em;
        max-height: 25px;
    }
    .imagesDivFlex04{ height: 150px; }
    
    .voterDetailsText04{ margin-top: 10%; }
    
    .voterDetailsText04 h5{
        font-size: 0.6em;
        line-height: 0.1;
    }
    .voterDetailsText04 h1{
        font-size: 1.1em;
        max-height: 43px;
    }
      
}
