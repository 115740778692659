.outSideDiv02{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
    width: 100%;
    height: 600px;
    margin: 5px;
    text-transform: uppercase;
    background-color: #DCDBD6;
    box-shadow: 0px 0px 4px #000;
}
.topInsideDiv02{
    width: 100%;
    height: 58%;
    color: #000 !important;
    background: linear-gradient(0deg, rgba(239,203,3, 0.9), rgba(239,203,3, 0.9)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5% 5% 0 5%;
    display: flex;
} 
.mainDetailsText02{
    width: 45%;
    display: flex;
    align-items: flex-end;
}
.imagesDivFlex02{
    width: 230px;
    height: 95%;
}
.imgFlexed02{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.electionDebateDiv02{
    text-align: right;
    padding-top: 10px;
    width: 55%;
    align-self: center;
}
h1.electionDebateClass02{
    line-height: 0.9;
    font-size: 2.1em;
    font-weight: 700;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 140px;
}
p.whiteBackDate{
    float: right;
    font-size: 1.2em;
    font-weight: 700;
    padding: 4px 7px;
    background-color: #fff;
    word-wrap: break-word;
    overflow: hidden;
    max-width: 95%;
    max-height: 57px;
}
.bottomInsideDiv02{
    width: 100%;
    height: 42%;
    background: linear-gradient(0deg, rgba(250,250,250, 0.9), rgba(250,250,250, 0.9)), url('../../../../../assets/images/templates/general/skyscrapperTwo.jpg');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.voterDetailsText02{
    display: flex;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 130px;
}
.voterDetailsText02 div.partyDiv{
    width: 45%;
    line-height: 1;
    font-size: 1.8em;
    font-weight: 700;
    text-align: right;
    padding: 0 6px;
    
}
.voterDetailsText02 div.partyDivTwo{ text-align: left; }

.voterDetailsText02 span.versusText{
    background-color: #02116F;
    color: #fff;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    font-size: 1.6em;
    border-radius: 50%;
}

@media (max-width: 992px) {
    .outSideDiv02{ height: 500px; }
    
    .imagesDivFlex02{ width: 240px; }
    
    h1.electionDebateClass02{
        width: 100%;
        font-size: 1.75em;
        max-height: 115px;
    }
    p.whiteBackDate{
        font-size: 1em;
        max-height: 50px;
    }
    .voterDetailsText02 div.partyDiv{ font-size: 1.5em; }
    
    .voterDetailsText02 span.versusText{
        font-size: 1.3em;
        width: 46px;
        height: 46px;
    } 
}

@media (max-width: 770px) {
    .imagesDivFlex02{ width: 230px; }
    
    h1.electionDebateClass02{ font-size: 1.75em; }
}

@media (max-width: 550px) {
    .outSideDiv02{ height: 450px; }
    
    .imagesDivFlex02{ width: 180px; }
    
    h1.electionDebateClass02{
        font-size: 1.6em;
        max-height: 106px;
    }
    .voterDetailsText02 div.partyDiv{
        font-size: 1.2em;
        max-height: 90px;
    }
    .voterDetailsText02 span.versusText{ font-size: 0.9em; }
}
@media (max-width: 450px) {
    .outSideDiv02{ height: 400px; }
    
    .imagesDivFlex02{ width: 150px; }
    
    h1.electionDebateClass02{
        font-size: 1.2em;
        max-height: 96px;
    }
    p.whiteBackDate{ font-size: 0.7em; }
   
    .voterDetailsText02 div.partyDiv{
        font-size: 1em;
        max-height: 90px;
    }
    .voterDetailsText02 span.versusText{
        font-size: 0.7em;
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 350px) {
    .outSideDiv02{ height: 350px; }
    
    .imagesDivFlex02{ width: 105px; }
   
    h1.electionDebateClass02{ font-size: 0.95em; }
   
    p.whiteBackDate{ font-size: 0.55em; }
    
    .voterDetailsText02 div.partyDiv{ font-size: 0.7em; }
    
    .voterDetailsText02 span.versusText{
        width: 35px;
        height: 35px;
        font-size: 0.5em;
    }
}
